<p-dialog #dialog header="{{'FsSystem.Title.Shared' | translate}}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="ui-g -large-gutter -row sv-form-row">
            <div class="__table-action-block">
                <div class="__left-action-block" style="flex: 1 1 400px">
                    <button type="button" (click)="onAddUser()"
                        class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active easing-fast">
                        <span class="__label">{{'FsSystem.BtnAddUser' | translate}}</span>
                        <span class="__icon ci ci-add"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="ui-g -large-gutter -row sv-form-row"> &nbsp;</div>
        <div class="ui-g -large-gutter -row sv-form-row">
            <div class="sv-table-wrapper">
                <p-table [columns]="cols" [value]="dataSource" class="sv-table" [scrollable]="true" [lazy]="true">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <ng-container *ngFor="let col of cols">
                                <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                            </ng-container>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <th *ngIf="col.sort" [pSortableColumn]="col.field" [hidden]="!col.visible">
                                    <div class="flex-el space-between">
                                        <div class="overflow-break-word">{{col.header}}</div>
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </th>
                                <th *ngIf="!col.sort" [hidden]="!col.visible">
                                    <div class="overflow-break-word">{{col.header}}</div>
                                </th>
                            </ng-container>
                            <th style="width: 150px;">{{'AzRoleMappingUsers.actions' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                        <tr style="text-align: center">
                            <td style="width: 50px;">{{rowIndex + 1}}</td>
                            <td *ngFor="let col of columns;let i = index;" [ngSwitch]="col.field"
                                [style.width]="col.width" [hidden]="!col.visible"
                                [class.align-left]="col.align == 'left'" style="word-wrap: break-word;">
                                <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                <span *ngSwitchCase="'userName'">
                                    <span *ngIf="!rowData.isEdit">{{rowData[col.field]}}</span>
                                    <span *ngIf="rowData.isEdit">
                                        <p-dropdown appendTo="body" (onChange)="onChangeUser(rowData)" [filter]="true"
                                            [autoDisplayFirst]='false' [emptyFilterMessage]="'LIST.EMPTY' | translate"
                                            [options]="dsUsers" [(ngModel)]="rowData.userIdShared"
                                            [style]="{'width':'100%', 'min-width':'0'}">
                                        </p-dropdown>
                                    </span>
                                </span>
                                <span *ngSwitchCase="'isEditor'">
                                    <p-checkbox [(ngModel)]="rowData.isEditor" [binary]="true"
                                        [disabled]="!rowData.isEdit">
                                    </p-checkbox>
                                </span>
                            </td>
                            <td style="width: 150px;" class="tn-hidden-print">
                                <div class="tn-table-row-actions ui-text-right">
                                    <button type="button"
                                        class="sv-btn icon-only __record-action-btn sv-bgc--transparent"
                                        *ngIf="rowData.isEdit" (click)="onCancelItem(rowData)"
                                        pTooltip="{{'Hủy bỏ' | translate}}">
                                        <span class="__icon fa fa-undo"></span>
                                    </button>
                                    <button type="button"
                                        class="sv-btn icon-only __record-action-btn sv-bgc--transparent"
                                        *ngIf="rowData.isEdit" (click)="onCancelItem(rowData)"
                                        pTooltip="{{'Lưu dữ liệu' | translate}}">
                                        <span style="color: #190a43;" class="__icon fa fa-floppy-o"></span>
                                    </button>
                                    <button type="button"
                                        class="sv-btn icon-only __record-action-btn sv-bgc--transparent"
                                        *ngIf="!rowData.isEdit" (click)="onEditItem(rowData)"
                                        pTooltip="{{'Sửa chi tiết' | translate}}">
                                        <span class="__icon fa fa-edit" style="color: #2e9fe0;"></span>
                                    </button>
                                    <button type="button"
                                        class="sv-btn icon-only __record-action-btn sv-bgc--transparent"
                                        (click)="onDeleteItem(rowData)" pTooltip="{{'Xóa dữ liệu' | translate}}">
                                        <span class="__icon fa fa-trash" style="color: red;"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">

            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="onSave()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
