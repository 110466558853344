import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseTopicService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/coursetopic`);
    }

    getsByIdCourse(idCourse: number, status: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}/${status}`;
        return this.defaultGet(apiUrl);
    }

    updatePin(id: number, pin: boolean): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdatePin/${id}/${pin}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

}
