import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

@Pipe({
    name: 'fsFileUrl'
})
export class FsFileUrlPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        if (value) {
            if (value.startsWith("http")) {
                return value;
            } else {
                return `${environment.apiDomain.svFsSystemEndpoint}/${value}`;
            }
        } else {
            return 'assets/img/courses/img1.jpg';
        }
    }
}
