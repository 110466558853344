import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageEditBase } from 'sv-shared';
import { RootCategory } from '../../../config/enums';
import { SvSchoolYear } from '../../../models/sv-school-year';
import { CategoriesService } from '../../../services/categories.service';
import { CourseService } from '../../../services/course.service';
import { FsItemPublicService } from '../../../services/fsitem-public';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';
import { SvSubjectService } from '../../../services/sv-subject.service';

@Component({
    selector: 'app-teacher-course-form',
    templateUrl: './teacher-course-form.component.html',
    styleUrls: ['./teacher-course-form.component.scss']
})
export class TeacherCourseFormComponent extends SecondPageEditBase implements OnInit {
    dsSubject = []
    dsCategory = []
    id = 0;
    currYear: SvSchoolYear = new SvSchoolYear();
    dsRegisterType = [
        { label: 'Sinh viên tự đăng ký', value: 2 },
        { label: 'Không cho phép đăng ký', value: 1 }
    ]
    @ViewChild('hiddenfileinput') hiddenfileinput: ElementRef;
    constructor(
        protected _courseService: CourseService,
        protected _categoriesService: CategoriesService,
        protected _injector: Injector,
        protected _svSchoolYearService: SvSchoolYearService,
        protected _svSubjectService: SvSubjectService,
        protected _fsItemPublicService: FsItemPublicService,
        protected _activatedRoute: ActivatedRoute,
        protected _router: Router,

    ) {
        super(_courseService, _injector);
    }

    async ngOnInit() {
        this.formGroup = new FormGroup({
            code: new FormControl('', Validators.required),
            title: new FormControl('', Validators.required),
            idSvSubject: new FormControl('', Validators.required),
            idCategory: new FormControl('', Validators.required),
            registerType: new FormControl('', Validators.required),
            startDate: new FormControl(''),
            imageUrl: new FormControl(''),
            introduction: new FormControl(''),
            status: new FormControl(''),
        });
        await this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
            this.itemDetail = {
                idSvSchoolYear: rs.id,
                UserIdTeacher: this.currentUser.userId,
                registerType: 1,
                idCategory: 0,
                idSvSubject: 0,
                typeId: 1,
                dsCourseTeacher: []
            }
        })

        await this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.id = pid;
            });
        await this.getDmHocPhan();
        await this.getCategory();

        if (this.id > 0) {
            this.getDetail();
        }
    }

    async getDetail() {
        this._courseService.getDetailForEdit(this.id).then(async rs => {
            if (rs.success) {
                if ((rs.data.userIdTeacher - 0) !== (this.currentUser.userId - 0)) {
                    this._notifierService.showWarning('Không được phép sửa thông tin lớp học phần của giảng viên khác');
                    this._router.navigate([`teacher`]);
                }
                this.itemDetail.id = rs.data.id;
                this.itemDetail.code = rs.data.code;
                this.itemDetail.title = rs.data.title;
                this.itemDetail.idCategory = rs.data.idCategory;
                this.itemDetail.idSvSubject = rs.data.idSvSubject;
                this.itemDetail.imageUrl = rs.data.imageUrl;
                this.itemDetail.idSvSchoolYear = rs.data.idSvSchoolYear;
                this.itemDetail.introduction = rs.data.introduction;
                this.itemDetail.status = rs.data.status;
                this.itemDetail.userIdTeacher = rs.data.userIdTeacher;
                this.itemDetail.dsCourseTeacher = rs.data.dsCourseTeacher;
                if (rs.data.startDate) {
                    this.itemDetail.startDate = new Date(rs.data.startDate);
                }

            }
        })
    }


    async getDmHocPhan() {
        this.dsSubject = [];
        var model = {
            idOrganization: 0,
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 9999,
            orderCol: 'title',
            isDesc: false
        }
        await this._svSubjectService.find(model)
            .then(rs => {
                rs.data.map(item =>
                    this.dsSubject.push({ label: `${item.code} - ${item.title}`, value: item.id })
                );
            }, error => {
                this._notifierService.showHttpUnknowError();
            });
    }

    async getCategory() {
        this._categoriesService.getTreeTableByIdParent(RootCategory.Dm_LopHocPhan, true, false).then(rs => {
            if (rs.success) {
                this.dsCategory = rs.data;
            }
        })
    }

    onInsert() {
        this._courseService.AddEditItem(this.itemDetail)
            .then(response => {
                if (response.success) {
                    this.itemDetail.id = response.data;
                    this._notifierService.showInsertDataSuccess();
                    this.onAfterSave();
                    this._router.navigate(['/teacher']);
                }
                else {
                    this._notifierService.showInsertDataFailed();
                }
                this.submitting = false;
            }, () => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._courseService.AddEditItem(this.itemDetail)
            .then(() => {
                this._notifierService.showUpdateDataSuccess();
                this.onAfterSave();
                this.submitting = false;
                this._router.navigate(['/teacher']);
            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    async onFileSelect(event) {
        const selectedFile = event.target.files[0];
        console.log(selectedFile.name);
        const rawFileName = selectedFile.name;
        const formData: FormData = new FormData();
        formData.append(rawFileName, selectedFile);
        this.itemDetail.avatar = "";
        await this._fsItemPublicService.uploadCourseImage(formData).then(rs => {
            if (rs.success) {
                this.itemDetail.imageUrl = rs.data;
            } else {
                this._notifierService.showHttpUnknowError();
            }
        }).catch(err => {
            console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
        });
    }

    goCourseItem() {
        if (this.itemDetail.id > 0) {
            this._router.navigate([`teacher/course/item/${this.itemDetail.id}`]);

        }
    }
}
