import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecondPageIndexBase } from 'sv-shared';
import { RootCategory } from '../../config/enums';
import { CategoriesService } from '../../services/categories.service';
import { NewsService } from '../../services/news.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent extends SecondPageIndexBase implements OnInit {
    idCategory = 0;
    itemCategory = { id: 0, title: 'Tin tức / thông báo / blog' };
    dsOrderBy = [];
    idOrderBy = 1;

    constructor(
        protected _newsService: NewsService,
        protected _injector: Injector,
        private _route: ActivatedRoute,
        private _categoriesService: CategoriesService,
    ) {
        super(_newsService, _injector);
    }

    ngOnInit() {
        this.pageSize = 4;
        this._route.queryParams.subscribe(params => {
            this.idCategory = params['idCategory'];
            this.getCategoryDetail(params['idCategory'] ?? 0);
            this.query = params['query'];
            this.getData();
        });
    }

    getCategoryDetail(id: number) {
        if (id > 0) {
            this._categoriesService.getDetail(id).then(rs => {
                if (rs.success) {
                    this.itemCategory = rs.data;
                }
            })
        }
    }



    getData() {
        this.dataSource = [];
        var searchModel = {
            keyword: this.query,
            idCategory: this.idCategory ?? 0,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: 'Id',
            isDesc: true,
        };
        this._newsService.find(searchModel)
            .then(response => {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.isLoading = false;
            }, error => {
                console.log(error);
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });

        this.resetBulkSelect();
    }

    onGoSearch(data: any) {
        this.pageIndex = 1;
        this.idCategory = data.idCategory;
        this.query = data.query;
        this.getData();
    }

    onGoPage($event) {
        this.pageIndex = $event;
        this.getData();
    }

}

