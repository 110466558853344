import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService, BaseService, ResponseResult } from 'sv-shared';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AzRoleMappingUsersService extends BaseService {
    readonly storage = sessionStorage;
    constructor(http: HttpClient, injector: Injector, _authenticationService: AuthenticationService) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/azrolemappingusers`);
    }

    getsByUserId(userId: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByUserId/${userId}`;
        return this.defaultGet(apiUrl);
    }

    isAdmin(userId: string): number {
        const key = `AUTHORIZATION.ROLE.${userId}`;
        const storageItem = this.storage.getItem(key);

        if (storageItem) {
            const result = parseInt(storageItem);
            return result;
        } else {
            this.getsByUserId(userId).then(rs => {
                if (rs.success) {
                    this.storage.setItem(key, rs.data.length);
                } else {
                    return 0;
                }
            }, err => {
                return 0;
            })
        }
    }
}
