import { BaseModel } from './base';


export class CourseUserLog extends BaseModel {
    id: number;
    idCourse: number;
    idCourseItem: number;
    userId: number;
    finalMark: number;
    timeIn: Date;
    timeOut: Date;
    trangThai: number;
    answerText: string;
    idItemData: number;
    idItemDataDetail: number;
    idCourseItemType: number;
    dsAttactment = [];
    totalTime: number;
    countDown: number;
    numQTrue: number;
    numQFalse: number;
    numQNoAns: number;
    teacherComment: string;
    scormResult: string;
    isLearned: boolean;
    constructor(_id: number, _answerText: string) {
        super();
        this.id = _id;
        this.answerText = _answerText;
    }
}
