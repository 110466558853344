<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Chuyên mục phân loại lớp học phần' | translate}}</li>
            </ul>
            <h2>{{'Chuyên mục phân loại lớp học phần' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let item of dsCategory">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-categories-box">
                        <img src="{{item.imgUrl ?? 'assets/img/categories/img1.jpg'}}" alt="image">
                        <div class="content">
                            <h3>{{item.title}}</h3>
                            <span>{{item.countCourse}}</span>
                        </div>
                        <a routerLink="/courses" [queryParams]="{idCategory: item.id}" class="link-btn"></a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>
