import { Component, OnInit, Injector } from '@angular/core';
import { CourseService } from '../../services/course.service';
import { SecondPageIndexBase } from 'sv-shared';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '../../services/categories.service';
import { SvSchoolYear } from '../../models/sv-school-year';
import { SvSchoolYearService } from '../../services/sv-school-year.service';

@Component({
    selector: 'app-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.scss']
})
export class CoursesComponent extends SecondPageIndexBase implements OnInit {
    idCategory = 0;
    itemCategory = { id: 0, title: 'Danh sách khóa học' };
    dsOrderBy = [];
    idOrderBy = 1;
    dsTypeId = [];
    typeId = 0;
    currYear: SvSchoolYear = new SvSchoolYear();
    constructor(
        protected _courseService: CourseService,
        protected _injector: Injector,
        private _route: ActivatedRoute,
        private _categoriesService: CategoriesService,
        private _svSchoolYearService: SvSchoolYearService,
    ) {
        super(_courseService, _injector);
    }

    async ngOnInit() {
        this.pageSize = 12;
        this.dsOrderBy = [
            { label: this._translateService.instant('Mới nhất'), value: 1 },
            { label: this._translateService.instant('Cũ nhất'), value: 2 },
            { label: this._translateService.instant('Tên A...Z'), value: 3 },
            { label: this._translateService.instant('Tên Z...A'), value: 4 }
        ];
        this.dsTypeId = [
            { label: this._translateService.instant('Tất cả'), value: 0 },
            { label: this._translateService.instant('Lớp học phần'), value: 1 },
            { label: this._translateService.instant('Kỹ năng mềm'), value: 2 }
        ];
        await this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        })
        await this._route.queryParams.subscribe(params => {
            this.idCategory = params['idCategory'];
            this.getCategoryDetail(params['idCategory'] ?? 0);
            this.query = params['query'];
            this.typeId = params['typeId'] ?? 0;
            this.getData();
        });

    }

    getCategoryDetail(id: number) {
        if (id > 0) {
            this._categoriesService.getDetail(id).then(rs => {
                if (rs.success) {
                    this.itemCategory = rs.data;
                }
            })
        }
    }

    async getData() {
        this.isLoading = true;
        var searchModel = {
            keyword: this.query,
            idSvSchoolYear: this.currYear.id,
            idCategory: this.idCategory,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            typeId: this.typeId,
        };
        await this._courseService.find(searchModel)
            .then(response => {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.isLoading = false;
            }, error => {
                console.log(error);
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });

        this.resetBulkSelect();
    }

    onGoSearch(data: any) {
        this.pageIndex = 1;
        this.idCategory = data.idCategory;
        this.query = data.query;
        this.getData();
    }

    onGoPage($event) {
        this.pageIndex = $event;
        this.getData();
    }

    async changeOrder() {
        console.log(this.idOrderBy);
        switch (this.idOrderBy) {
            case 1: {
                this.orderCol = 'Id',
                    this.isDesc = true;
                break;
            }
            case 2: {
                this.orderCol = 'Id',
                    this.isDesc = false;
                break;
            }
            case 3: {
                this.orderCol = 'Title',
                    this.isDesc = false;
                break;
            }
            case 4: {
                this.orderCol = 'Title',
                    this.isDesc = true;
                break;
            }
            default:
                this.orderCol = 'Id',
                    this.isDesc = true;
                break;
        }
        this.getData();
    }

}
