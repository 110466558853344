import { Component, Input, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-learning-space-table-history',
    templateUrl: './learning-space-table-history.component.html',
    styleUrls: ['./learning-space-table-history.component.scss']
})
export class LearningSpaceTableHistoryComponent implements OnInit {

    @Input() dsResult: [];
    constructor() { }

    ngOnInit() {
    }

}
