<p-dialog #dialog [header]="'TestQuestion.ImportWord' | translate" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="col-lg-12 col-md-12">
            <div class="sv-form-control type-text-field">
                <div class="sv-form-item__label">{{'FORMIMPORT.LBLDOWNLOADTEMPLATE' | translate}}
                </div>
                <div class="sv-form-item__input-wrapper -type-text-field">
                    <button type="button" (click)="downloadForm()"
                        class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--green-60 focus-style-1">
                        <span class="sv-btn__label">{{'FORMIMPORT.DOWNLOAD' | translate}}</span>
                        <span class="__icon ci ci-download"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="sv-form-control type-text-field">
                <div class="sv-form-item__label">{{'FORMIMPORT.SelectedFile' | translate}}</div>
                <div class="sv-form-item__input-wrapper -type-text-field">
                    <p-fileUpload #fileControl [multiple]="false" customUpload="true"
                        (uploadHandler)="myUploader($event)" name="files" maxFileSize="10000000" accept=".doc, .docx"
                        chooseLabel="{{'FORMIMPORT.lblSelectFile' | translate}}"
                        uploadLabel="{{'FORMIMPORT.lblUpload' | translate}}"
                        cancelLabel="{{'FORMIMPORT.lblCancel' | translate}}">
                    </p-fileUpload>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="isUploading"></p-progressBar>
        </div>
    </div>
    <p-footer>
    </p-footer>
</p-dialog>
