<div class="sv-page-content-area">
    <div class="sv-page-content-area__header">
        <div class="sv-page-title-area">
            <div class="sv-page-title-area__left-block">
                <h1 class="sv-page-title">
                    {{'FsSystem.Title.SharedWithMe' | translate}}
                    <span class="sv-page-title__helper ci ci-help"></span>
                </h1>
            </div>
            <div class="sv-page-title-area__right-block">

            </div>
        </div>
    </div>
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__main-block">
                <div class="sv-data-view-main-action-area">
                    <div (window:resize)="onLeftTableActionsWidthChange()"
                        class="sv-data-view-main-action-area__left-block" id="left-table-actions"
                        style="flex: 1; min-width: 110px">
                        <button type="button" (click)="goHome()" id="act-refresh"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            pTooltip="{{'Thư mục gốc' | translate }}">
                            <span class="sv-btn__icon ci ci-home"></span>
                        </button>
                        <button type="button" id="act-refresh"
                            class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1"
                            (click)="getDataTable()">
                            <span class="sv-btn__label">{{'BUTTON.RELOAD' | translate}}</span>
                            <span class="sv-btn__icon ci ci-restart"></span>
                        </button>
                        <button (click)="actionHidden.toggle($event)"
                            class="sv-btn -icon-only sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1"
                            type="button">
                            <span class="sv-btn__icon ci ci-overflow-menu-vertical"></span>
                        </button>

                        <p-overlayPanel #actionHidden [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                            appendTo="body" styleClass="sv-overlay-menu style-1">
                            <div *ngFor="let item of leftTableActionsElements" [class.h-hidden]="item.visible"
                                class="sv-overlay-menu__item">
                                <a (click)="item.action.apply(this)">{{item.text}}</a>
                            </div>
                        </p-overlayPanel>

                    </div>
                    <div class="sv-data-view-main-action-area__right-block">
                        <!-- <div class="h-mb-8">{{totalRecord}}</div> -->
                    </div>
                </div>
                <div class="sv-table-wrapper">
                    <div class="file-grid-wrapper">
                        <div class="file-list grid">
                            <div class="file-wrapper file-item" *ngFor="let item of dataSource">
                                <div class="file-item" (contextmenu)="showContectMenu($event, item)"
                                    (click)="onClickFsItem(item)" [class.is-clicked]="item.isClick"
                                    (dblclick)="onDoubleItemClick(item)">
                                    <div class="icon-item"
                                        [innerHTML]="item.title | icon : item.idFsItemType == 1 : item.webPath">
                                    </div>
                                    <div class="item-name">
                                        <b class="name">
                                            {{item.title}}
                                        </b>
                                        <div class="item-info">
                                            <span class="item-length" *ngIf="item.idFsItemType > 1">
                                                {{item.totalSize | fileSize}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p-overlayPanel #pContext [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                        appendTo="body" styleClass="contextmenu">
                        <div class="menu-options">
                            <ul class="menu-option-group">
                                <li class="menu-option" (click)="viewFile()">
                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg></div>
                                    <div class="text-label">
                                        {{'FsSystem.contextMenu.view' | translate}}
                                    </div>
                                </li>
                                <li class="menu-option" (click)="copyUrl()"
                                    [hidden]="selectedFsItem?.idFsItemType <= 1">
                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg></div>
                                    <div class="text-label">
                                        {{'FsSystem.contextMenu.copyLink' | translate}}
                                    </div>
                                </li>
                                <!---->
                            </ul>
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
        </div>
    </div>
</div>

<app-fssystem-file-viewer #pFileViewer></app-fssystem-file-viewer>
