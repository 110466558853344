<p-dialog #dialog [header]="'FORMIMPORT.TITLE' | translate" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">

    <p-tabView class="sv-modal-tab" *ngIf="dataSource.length > 0">
        <p-tabPanel header="{{'FORMIMPORT.TitlePanelData' | translate}}">
            <div class="sv-form-container default-control-label-width">
                <div class="sv-data-view-area">
                    <div class="sv-data-view-area__main-block">
                        <div class="sv-table-wrapper">
                            <p-scrollPanel [style]="{'width': '100%', 'height': '100%'}">
                                <p-table [columns]="cols" [value]="dataSource" class="sv-table"
                                    [style]="{'min-width': '1000px'}" [lazy]="true">
                                    <ng-template pTemplate="colgroup">
                                        <colgroup>
                                            <ng-container *ngFor="let col of cols">
                                                <col [style.width]="col.width ? col.width : 'auto'"
                                                    [hidden]="!col.visible">
                                            </ng-container>
                                        </colgroup>
                                    </ng-template>

                                    <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            <ng-container *ngFor="let col of columns">
                                                <th *ngIf="col.sort" [pSortableColumn]="col.field"
                                                    [hidden]="!col.visible">
                                                    <div class="flex-el space-between">
                                                        <div class="overflow-break-word">{{col.header}}</div>
                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                    </div>
                                                </th>
                                                <th *ngIf="!col.sort" [hidden]="!col.visible">
                                                    <div class="overflow-break-word">{{col.header}}</div>
                                                </th>
                                            </ng-container>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-columns="columns"
                                        let-rowIndex="rowIndex">
                                        <tr [class.sv-row-status]="rowData.trangThai"
                                            [class.sv-row-status-danger]="rowData.trangThai === 3"
                                            [class.sv-row-status-warning]="rowData.trangThai === 2"
                                            [class.sv-row-status-success]="rowData.trangThai === 1">

                                            <td *ngFor="let col of columns" [hidden]="!col.visible"
                                                [ngSwitch]="col.field">
                                                <span *ngSwitchCase="'typeId'">{{'Users_typeId' + rowData[col.field] |
                                                    translate}}</span>
                                                <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-scrollPanel>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'FORMIMPORT.TitlePanelDataErr' | translate}}">
            <div class="sv-form-container default-control-label-width">
                {{dataError.toString()}}
            </div>
        </p-tabPanel>
    </p-tabView>

    <div class="sv-modal-content-panel sv-form-container">
        <div class="col-lg-12 col-md-12" *ngIf="dataSource.length === 0">
            <div class="sv-form-item">
                <div class="sv-form-item__label">{{'FORMIMPORT.LBLDOWNLOADTEMPLATE' | translate}}
                </div>
                <div class="sv-form-item__input-wrapper">
                    <button type="button" (click)="downloadForm()"
                        class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--green-60 focus-style-1">
                        <span class="sv-btn__label">{{'FORMIMPORT.DOWNLOAD' | translate}}</span>
                        <span class="sv-btn__icon ci ci-download"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12" *ngIf="dataSource.length === 0">
            <div class="sv-form-item">
                <div class="sv-form-item__label">{{'FORMIMPORT.SelectedFile' | translate}}</div>
                <div class="sv-form-item__input-wrapper">
                    <p-fileUpload #fileControl (onBeforeUpload)="onBeforeUpload()" [multiple]="false"
                        customUpload="true" (uploadHandler)="myUploader($event)" name="files" maxFileSize="10000000"
                        accept=".xlsx, .xsl" chooseLabel="{{'FORMIMPORT.lblSelectFile' | translate}}"
                        uploadLabel="{{'FORMIMPORT.lblUpload' | translate}}"
                        cancelLabel="{{'FORMIMPORT.lblCancel' | translate}}">
                    </p-fileUpload>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="isUploading"></p-progressBar>
        </div>
    </div>
    <p-footer>
        <div class="ui-g large-gutter row sv-modal-footer-content">
            <div class="ui-g-8 __left-content">
            </div>
            <div class="ui-g-4 __right-content">
                <button type="button" (click)="onImport()" [disabled]="submitting" *ngIf="dataSource.length > 0"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                    <span class="__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
