import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../../src/environments/environment';
import { BaseService } from '../../../services/base.service';
import { ResponseResult } from '../../../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class FsItemTrashUserService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svFsSystemEndpoint}/fsitemtrashuser`);
    }

    getsByUserId(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByUserId`;
        return this.defaultGet(url);
    }

    deleteItem(models: any[]): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteItem`;
        return this._http
            .post<ResponseResult>(url, models)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    restoreItem(models: any[]): Promise<ResponseResult> {
        const url = `${this.serviceUri}/RestoreItem`;
        return this._http
            .post<ResponseResult>(url, models)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
