import { BaseModel } from './base';

export class CourseModel extends BaseModel {
    avatarTeacher: '';
    countCourseComment: number;
    countCourseUser: number;
    displayNameTeacher: string;
    dsUserIdTeacher: [];
    imageUrl: string;
    introduction: string;
    countCourseItem: number;
    startDate: Date;
    price: number;
    typeId: number;
    userIdTeacher: number;
    idSvSchoolYear: number;
    idSvSubject: number;
    countCourseItemTask: number;
}
