import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ComponentBase } from 'sv-shared';
import { CategoriesService } from '../../../services/categories.service';
import { NewsService } from '../../../services/news.service';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent extends ComponentBase implements OnInit {

    itemDetail = { title: '', imageUrl: '', bodyHtml: '', publishedDate: '', viewCount: 0, idCategory: 0 };
    itemCategoty = { id: 0, title: '' };
    dataPrevNext = [];
    constructor(
        private _newsService: NewsService,
        private _activatedRoute: ActivatedRoute,
        private _categoriesService: CategoriesService,
    ) {
        super();
    }

    ngOnInit() {
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this._newsService.getDetail(pid).then(rs => {
                    if (rs.success) {
                        this.itemDetail = rs.data;
                        this._categoriesService.getDetail(rs.data.idCategory).then(rs1 => {
                            if (rs1.success) {
                                this.itemCategoty = rs1.data;
                            }
                        })
                    }
                })
                this.getPrevNextItem(pid);
            });
    }

    getPrevNextItem(id: number) {
        this._newsService.getPrevNextItem(id).then(rs => {
            if (rs.success) {
                this.dataPrevNext = rs.data;
            }
        })
    }


}
