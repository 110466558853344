import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { InteractiveVideoTestQuestionService } from '../../../../../services/interactive-video-test-question.service';
import { TestQuestionType } from '../../../../../config/enums';

@Component({
    selector: 'app-interactive-video-test-question-ma',
    templateUrl: './interactive-video-test-question-ma.component.html',
    styleUrls: ['./interactive-video-test-question-ma.component.scss']
})
export class InteractiveVideoTestQuestionMaComponent extends SecondPageEditBase implements OnInit {

    showEditor = false;
    itemAnswer = { id: 0, idInteractiveVideoTestQuestion: 0, answerText: '', status: 1, matching: '', idInteractiveVideoTest: 0 }
    indexAnswer = -1;
    correctAnswer = -1;
    idQuestionParent = null;
    idInteractiveVideoTest = 0;
    editor_modules = {
        imageResize: true
    };
    constructor(
        protected _testquestionService: InteractiveVideoTestQuestionService,
        protected _injector: Injector
    ) {
        super(_testquestionService, _injector);

        this.formGroup = new FormGroup({
            questionText: new FormControl('', Validators.required),
            orderNo: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    async onShowPopup(item: { id: 0, idInteractiveVideoTest: 0, idQuestionParent: null }) {
        // this.resetForm();
        this.idQuestionParent = item.idQuestionParent;
        this.idInteractiveVideoTest = item.idInteractiveVideoTest;
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (item.id > 0) {
            await this._testquestionService.getDetail(item.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: item.idQuestionParent, idTestQuestionType: TestQuestionType.MA, levelOfDifficult: 1, mark: 1, status: 1, dsAnswers: [] };
            this.submitting = false;
        }
    }

    onBeforeSave(): boolean {
        if (this.itemDetail.dsAnswers.length == 0) {
            this._notifierService.showWarning(this._translateService.instant('TestQuestion_MESSAGE_INPUTERROR'));
            return false;
        }

        return true;
    }

    addAnswer() {
        this.itemDetail.dsAnswers.push({ id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, matching: '', idInteractiveVideoTest: this.idInteractiveVideoTest });
    }


    deleteAnswer(index: number) {
        this._notifierService.showDeleteConfirm().then(rs => {
            this.itemDetail.dsAnswers.splice(index, 1);
        });
    }

    resetForm() {
        this.validationSummary.resetErrorMessages();
        this.formGroup.reset();
        this.itemDetail = { id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: this.idQuestionParent, idTestQuestionType: TestQuestionType.MA, levelOfDifficult: 1, mark: 1, status: 1, dsAnswers: [] };
        this.submitting = false;
    }

    delete() {
        this.submitting = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._testquestionService.deleteById(this.itemDetail.id)
                .then(response => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                    this.submitting = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.submitting = false;
                });
        });
    }

    changeEditorView() {
        this.showEditor = !this.showEditor;
    }
}
