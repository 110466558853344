import { IconPipe } from './pipes/icon.pipe';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { FileIconPipe } from './pipes/file-icon.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MenubarModule } from 'primeng/menubar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { AuthorizeDirective } from './directives/authorize.directive';
import { StringFormatPipe } from './pipes/string-format.pipe';
import { UserFormatPipe } from './pipes/user-format.pipe';
import { BooleanFormatPipe } from './pipes/boolean-format.pipe';
import { ApprovalPipe } from './pipes/approval.pipe';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SummaryPipe } from './pipes/summary.pipe';
import { TrangThaiPipe } from './pipes/trangThai.pipe';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeModule } from 'primeng/tree';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SpinnerModule } from 'primeng/spinner';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChipsModule } from 'primeng/chips';
import { SliderModule } from 'primeng/slider';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FsFileUrlPipe } from './pipes/fs-fileUrl.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AvatarPipe } from './pipes/avatar.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FssystemFileViewerComponent } from './components/fssystem/fssystem-file-viewer/fssystem-file-viewer.component';
import { FssystemGlobalComponent } from './components/fssystem/fssystem-global/fssystem-global.component';
import { FssystemGlobalFolderFormComponent } from './components/fssystem/fssystem-global/fssystem-global-folder-form/fssystem-global-folder-form.component';
import { FssystemGlobalTrashComponent } from './components/fssystem/fssystem-global/fssystem-global-trash/fssystem-global-trash.component';
import { FssystemGlobalUploadComponent } from './components/fssystem/fssystem-global/fssystem-global-upload/fssystem-global-upload.component';
import { FssystemPrivateComponent } from './components/fssystem/fssystem-private/fssystem-private.component';
import { FssystemPrivateFolderFormComponent } from './components/fssystem/fssystem-private/fssystem-private-folder-form/fssystem-private-folder-form.component';
import { FssystemPrivateItemSharedComponent } from './components/fssystem/fssystem-private/fssystem-private-item-shared/fssystem-private-item-shared.component';
import { FssystemPrivateUploadComponent } from './components/fssystem/fssystem-private/fssystem-private-upload/fssystem-private-upload.component';
import { FssystemPrivateSharedWithMeComponent } from './components/fssystem/fssystem-private/fssystem-private-shared-with-me/fssystem-private-shared-with-me.component';
import { FssystemModalComponent } from './components/fssystem/fssystem-modal/fssystem-modal.component';
import { FssystemModalSharedWithMeComponent } from './components/fssystem/fssystem-modal/fssystem-modal-shared-with-me/fssystem-modal-shared-with-me.component';
import { FssystemModalPrivateComponent } from './components/fssystem/fssystem-modal/fssystem-modal-private/fssystem-modal-private.component';
import { FssystemOrgComponent } from './components/fssystem/fssystem-org/fssystem-org.component';
import { FssystemOrgFolderFormComponent } from './components/fssystem/fssystem-org/fssystem-org-folder-form/fssystem-org-folder-form.component';
import { FssystemOrgTrashComponent } from './components/fssystem/fssystem-org/fssystem-org-trash/fssystem-org-trash.component';
import { FssystemOrgUploadComponent } from './components/fssystem/fssystem-org/fssystem-org-upload/fssystem-org-upload.component';
import { FssystemPrivateTrashComponent } from './components/fssystem/fssystem-private/fssystem-private-trash/fssystem-private-trash.component';


export function coreDeclaration() {
    return [
        FileIconPipe,
        FileSizePipe,
        StringFormatPipe,
        UserFormatPipe,
        BooleanFormatPipe,
        AuthorizeDirective,
        ApprovalPipe,
        ValidationSummaryComponent,
        AutofocusDirective,
        SummaryPipe,
        TrangThaiPipe,
        HighlightSearchPipe,
        SafePipe,
        TimeAgoPipe,
        AvatarPipe,
        IconPipe,
        FsFileUrlPipe,
        FileNamePipe,

        FssystemFileViewerComponent,
        FssystemGlobalComponent,
        FssystemGlobalFolderFormComponent,
        FssystemGlobalTrashComponent,
        FssystemGlobalUploadComponent,
        FssystemPrivateComponent,
        FssystemPrivateFolderFormComponent,
        FssystemPrivateItemSharedComponent,
        FssystemPrivateUploadComponent,
        FssystemPrivateTrashComponent,
        FssystemPrivateSharedWithMeComponent,
        FssystemModalComponent,
        FssystemModalSharedWithMeComponent,
        FssystemModalPrivateComponent,
        FssystemOrgComponent,
        FssystemOrgFolderFormComponent,
        FssystemOrgTrashComponent,
        FssystemOrgUploadComponent,
    ];
};

export function coreModuleImport() {
    return [
        CommonModule,
        DialogModule,
        ButtonModule,
        BreadcrumbModule,
        DropdownModule,
        MultiSelectModule,
        TabViewModule,
        DataViewModule,
        TooltipModule,
        ToastModule,
        MenubarModule,
        ContextMenuModule,
        TableModule,
        ConfirmDialogModule,
        FileUploadModule,
        FormsModule,
        RadioButtonModule,
        CalendarModule,
        CheckboxModule,
        InputTextModule,
        ReactiveFormsModule,
        TranslateModule,
        TreeModule,
        ScrollPanelModule,
        AutoCompleteModule,
        PaginatorModule,
        BlockUIModule,
        MenuModule,
        InputTextareaModule,
        SpinnerModule,
        EditorModule,
        InputSwitchModule,
        TableModule,
        DialogModule,
        ChipsModule,
        SliderModule,
        CardModule,
        OverlayPanelModule,
        InputMaskModule,
        TriStateCheckboxModule,
        OverlayPanelModule,
        NgxFileDropModule
    ];
}

@NgModule({
    imports: coreModuleImport(),
    providers: [

    ],
    declarations: coreDeclaration(),
    exports: [...coreDeclaration(), ...coreModuleImport()]
})
export class SvComponentModule {
}
