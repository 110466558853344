<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Đăng nhập' | translate}}</li>
            </ul>
            <h2>{{'Đăng nhập mLearning' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row justify-content-center" *ngIf="showLogin">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>{{'Đăng nhập' | translate}}</h2>
                    <form #formElement [formGroup]="formGroup">
                        <div class="form-group">
                            <label>{{'Tài khoản' | translate}}</label>
                            <input type="text" class="form-control" [(ngModel)]="model.username" name="username"
                                autocomplete="username" formControlName="username">
                        </div>
                        <div class="form-group">
                            <label>{{'Mật khẩu' | translate}}</label>
                            <input type="password" class="form-control" formControlName="password"
                                [(ngModel)]="model.password" autocomplete="current-password"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">{{'Nhớ mật khẩu'}}</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a (click)="showLogin = !showLogin" class="lost-your-password">{{'Quên mật khẩu?'}}</a>
                            </div>
                        </div>
                        <button type="submit" (click)="doLogin()">{{'Đăng nhập' | translate}}</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!showLogin">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>{{'Quên mật khẩu' | translate}}</h2>
                    <form>
                        <div class="form-group">
                            <label>{{'Tài khoản' | translate}}</label>
                            <input type="text" class="form-control" [(ngModel)]="model.username" name="username"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="form-group">
                            <label>{{'Email' | translate}}</label>
                            <input type="text" class="form-control" [(ngModel)]="model.email" name="email"
                                (keyup.enter)="doResetPassword()">
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a (click)="showLogin = !showLogin" class="lost-your-password">{{'Trở về?'}}</a>
                            </div>
                        </div>
                        <button type="submit" (click)="doResetPassword()">{{'Lấy lại mật khẩu' | translate}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
