import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseTopicPostService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/coursetopicpost`);
    }

    getsByIdTopic(idTopic: number, status: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdTopic/${idTopic}/${status}`;
        return this.defaultGet(apiUrl);
    }

    updateCount(idPost: number, likeCount: number, dislikeCount: number, loveCount: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateCount/${idPost}/${likeCount}/${dislikeCount}/${loveCount}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsLatestPost(idCourse: number, count: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsLatestPost/${idCourse}/${count}`;
        return this.defaultGet(apiUrl);
    }

}
