import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../../../classes/base/second-page-index-base';
import { FsItemTrashUserService } from '../../services/fsitemTrashUser.service';

@Component({
    selector: 'app-fssystem-private-trash',
    templateUrl: './fssystem-private-trash.component.html',
    styleUrls: ['./fssystem-private-trash.component.scss']
})
export class FssystemPrivateTrashComponent extends SecondPageIndexBase implements OnInit {

    constructor(
        protected _injector: Injector,
        protected _fsItemTrashUserService: FsItemTrashUserService,
    ) {
        super(_fsItemTrashUserService, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'title', header: this._translateService.instant('FORM.TITLE'), visible: true, width: '65%', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'totalSize', header: this._translateService.instant('FsSystem.TotalSize'), visible: true, width: 'auto', },

        ];
        this.getData();
    }

    getData() {
        this.resetBulkSelect();
        this._fsItemTrashUserService.getsByUserId().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    goHome() {
        this.getData();
    }

    restoreItem() {
        this._notifierService.showConfirm('Bạn có chắc chắn muốn khôi phục dữ liệu').then(rs => {
            this._fsItemTrashUserService.restoreItem(this.selectedItems).then(rs => {
                if (rs.success) {
                    this._notifierService.showUpdateDataSuccess();
                    this.getData();
                }
            })
        });
    }

    deleteItem() {
        this._notifierService.showDeleteConfirm().then(rs => {
            this._fsItemTrashUserService.deleteItem(this.selectedItems).then(rs => {
                if (rs.success) {
                    this._notifierService.showDeleteDataSuccess();
                    this.getData();
                }
            })
        });
    }

}
