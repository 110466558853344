import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { AuthenticationService, SecondPageIndexBase, SvUser } from 'sv-shared';
import { CourseModel } from '../../../models/course';
import { CourseItemModel } from '../../../models/courseitem';
import { SvSchoolYear } from '../../../models/sv-school-year';
import { CourseItemLiveService } from '../../../services/course-item-live.service';
import { CourseItemService } from '../../../services/course-item.service';
import { CourseUserService } from '../../../services/course-user.service';
import { CourseService } from '../../../services/course.service';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';
import { CourseItemDetailComponent } from './course-item-detail/course-item-detail.component';
import { CourseItemFormComponent } from './course-item-form/course-item-form.component';
import { CourseItemLiveComponent } from './course-item-live/course-item-live.component';
import { CourseItemUserComponent } from './course-item-user/course-item-user.component';
import { CourseItemViewComponent } from './course-item-view/course-item-view.component';

@Component({
    selector: 'app-course-item',
    templateUrl: './course-item.component.html',
    styleUrls: ['./course-item.component.scss']
})
export class CourseItemComponent extends SecondPageIndexBase implements OnInit {
    idCourse = 0;
    itemCourse: CourseModel = new CourseModel();
    tabIndex = 1;
    @ViewChild('pEdit') pEdit: CourseItemFormComponent;
    @ViewChild('pView') pView: CourseItemViewComponent;
    @ViewChild('pEditLive') pEditLive: CourseItemLiveComponent;
    @ViewChild('pImportUser') pImportUser: CourseItemUserComponent;
    // @ViewChild('pDetail') pDetail: CourseItemDetailComponent;
    dsTask = [];
    statistic = { countIdType1: 0, countIdType10: 0, countIdType20: 0 };
    dsCourseUser = [];
    currentUser: SvUser = new SvUser();
    isEditOrderNo = false;
    dsLiveItem = [];
    currYear: SvSchoolYear = new SvSchoolYear();
    isTeacher = false;
    constructor(
        protected _courseService: CourseService,
        protected _courseItemService: CourseItemService,
        protected _injector: Injector,
        protected _activatedRoute: ActivatedRoute,
        protected _courseUserService: CourseUserService,
        protected _router: Router,
        protected _authenticationService: AuthenticationService,
        protected _courseItemLiveService: CourseItemLiveService,
        protected _svSchoolYearService: SvSchoolYearService,

    ) {
        super(_courseItemService, _injector);
        this.currentUser = this._authenticationService.getCurrentUser();
    }

    async ngOnInit() {
        await this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        });
        await this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourse = pid;
                this.checkIsEditCourse(pid);
                this.getDetail();
                this.getData();
                this.getsCourseUser();
                this.getsItemLive();
            });
    }

    checkIsEditCourse(idCourse: number) {
        this._courseService.checkIsTeacherOfCourse(idCourse).then(rs => {
            if (rs.success) {
                this.isTeacher = rs.data;
                if (rs.data != true) {
                    this._notifierService.showError('Bạn không phải giáo viên của lớp học phần này');
                    this._router.navigate(['/']);
                }
            }
        })
    }

    getDetail() {
        this._courseService.getDetailForEdit(this.idCourse).then(rs => {
            if (rs.success) {
                this.itemCourse = rs.data;
            }
        })
    }

    getData() {
        this._courseItemService.searchTree(this.idCourse).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
        this._courseItemService.getStatisticByIdCourse(this.idCourse).then(rs => {
            if (rs.success) {
                this.statistic = rs.data;
            }
        })
        this._courseItemService.getsTaskByIdCourse(this.idCourse).then(rs => {
            if (rs.success) {
                this.dsTask = rs.data;
            }
        })
    }

    goEditInfo() {
        this._router.navigate([`teacher/course/form/${this.idCourse}`]);
    }
    goTab(index: number) {
        this.tabIndex = index;
    }

    add() {
        var model = new CourseItemModel()
        model.id = 0;
        model.idCourseItemType = 1;
        model.idCourse = this.idCourse;
        model.status = 2;
        this.pEdit.showPopup(model);
    }

    editItem(item: any) {
        this.pEdit.showPopup(item);
    }
    addChildItem(item: any) {
        var model = new CourseItemModel()
        model.id = 0;
        model.idCourseItemParent = item.id;
        model.idCourseItemType = 11;
        model.idCourse = this.idCourse;
        model.status = 1;
        this.pEdit.showPopup(model);
    }
    deleteItem(item: any) {
        this._notifierService.showDeleteConfirm().then(rs => {
            this._courseItemService.deleteItem(item.idCourse, item.id)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    goView(item: any) {
        this.pView.showPopup(item);
    }

    goDetail(item: any) {
        //this.pDetail.showPopup(item);
    }

    editTest(item: any) {
        this._router.navigate([`teacher/course/test/${item.idCourse}/${item.id}`]);
    }

    viewLog(item: any) {
        // console.log(item);
        this._router.navigate([`teacher/course/item/log/${item.idCourse}/${item.id}`]);
    }

    updateTrangThai(item: any, status: number) {
        this._notifierService.showConfirm("Bạn có muốn cập nhật trạng thái dữ liệu").then(rs => {
            this._courseItemService.UpdateTrangThai(item.idCourse, item.id, status)
                .then(response => {
                    this.getData();
                    this._notifierService.showUpdateDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    getsCourseUser() {
        this._courseUserService.getsByIdCourse(this.idCourse).then(rs => {
            if (rs.success) {
                this.dsCourseUser = rs.data;
            }
        })
    }

    showEditOrderNo() {
        this.isEditOrderNo = true;
    }
    onUpdateOrderNo() {
        this._courseUserService.updateOrderNo(this.idCourse, this.dsCourseUser).then(rs => {
            if (rs.success) {
                this.dsCourseUser = rs.data;
                this.isEditOrderNo = false;
                this._notifierService.showUpdateDataSuccess();
            }
        })
    }

    getsItemLive() {
        this._courseItemLiveService.getsByIdCourse(this.idCourse).then(rs => {
            if (rs.success) {
                this.dsLiveItem = rs.data.dsUpComming;
                this.dsLiveItem = this.dsLiveItem.concat(rs.data.dsFinished);
            }
        })
    }

    addLiveItem() {
        this.pEditLive.showPopup({ id: 0, idCourse: this.idCourse, idSvSubject: this.itemCourse.idSvSubject });
    }

    editItemLive(item) {
        this.pEditLive.showPopup({ id: item.id, idCourse: this.idCourse, idSvSubject: this.itemCourse.idSvSubject });
    }

    deleteItemLive(item) {
        this._notifierService.showDeleteConfirm().then(() => {
            this._courseItemLiveService.deleteItem(item.idCourse, item.id)
                .then(() => {
                    this.getsItemLive();
                    this._notifierService.showDeleteDataSuccess();
                }, () => {
                    this._notifierService.showDeleteDataError();
                });
        });
    }

    onPopupClosed(data) {
        this.selectedItems = [];
        if (data) {
            if (this.tabIndex == 1) {
                this.getData();
            }
            if (this.tabIndex == 4) {
                this.getsItemLive();
            }
            if (this.tabIndex == 3) {
                this.getsCourseUser();
            }
        }
    }
    showAddUser() {
        this.pImportUser.showPopup(this.itemCourse);
    }

    onChangeCourseUserStatus(item) {
        if (item.isActive == true) {
            item.status = 1;
        } else {
            item.status = 2;
        }
    }
    viewLearningHistory(courseItem: any) {
        this._router.navigate([`teacher/course/item/learning-log/${courseItem.idCourse}/${courseItem.id}`]);
    }

}
