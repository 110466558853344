<p-dialog #dialog header="{{ title }}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [style]="{'width': '1300px'}" [styleClass]="'sv-modal force-full-width'"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize"
    minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="title">{{'Tên' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input type="text" class="sv-form-item__input sv-text-field" [(ngModel)]="itemDetail.title"
                                formControlName="title">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="startTime">{{'Thời gian bắt đầu' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <p-calendar class="sv-form-item__input  sv-datepicker" formControlName="startTime"
                                dateFormat="dd/mm/yy" appendTo="body" [minDate]="minDateValue"
                                [(ngModel)]="itemDetail.startTime" showTime="true" hourFormat="24">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="duration">{{'Kéo dài trong (phút)' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input type="text" class="sv-form-item__input sv-text-field"
                                [(ngModel)]="itemDetail.duration" formControlName="duration">
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui-g large-gutter row sv-form-row" *ngIf="itemDetail.id == 0">
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-control type-text-field">
                        <div class="sv-form-item__label" for="recurrenceType">{{'Lặp lại ? ' | translate}}</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-checkbox class="sv-checkbox primary-theme" name="group" binary="true"
                                [minDate]="minDateValue" formControlName="recurrenceType"
                                [(ngModel)]="itemDetail.recurrenceType">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-control type-text-field required">
                        <div class="sv-form-item__label" for="startTime">{{'Lặp đến ngày' | translate}}</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-calendar class="sv-form-item__input  sv-datepicker" formControlName="endTime"
                                dateFormat="dd/mm/yy" appendTo="body" [(ngModel)]="itemDetail.endTime">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="itemDetail.recurrenceType">
                    <div class="sv-form-control type-text-field">
                        <div class="sv-form-item__label" for="recurrenceBy">{{'Lặp lại theo' | translate}}</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input  sv-dropdown" formControlName="recurrenceBy"
                                [options]="dsRecurrenceBy" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.recurrenceBy" [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="itemDetail.recurrenceBy === 2">
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="1" label="Thứ 2" [(ngModel)]="recurrenceValue"
                            formControlName="group12" inputId="2">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="2" label="Thứ 3" [(ngModel)]="recurrenceValue"
                            formControlName="group13" inputId="3">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="3" label="Thứ 4" [(ngModel)]="recurrenceValue"
                            formControlName="group14" inputId="4">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="4" label="Thứ 5" [(ngModel)]="recurrenceValue"
                            formControlName="group15" inputId="5">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="5" label="Thứ 6" [(ngModel)]="recurrenceValue"
                            formControlName="group16" inputId="6">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-1">
                        <p-checkbox name="group1" value="6" label="Thứ 7" [(ngModel)]="recurrenceValue"
                            formControlName="group17" inputId="7">
                        </p-checkbox>
                    </div>
                    <div class="ui-g-3 ui-lg-2">
                        <p-checkbox name="group1" value="0" label="Chủ nhật" [(ngModel)]="recurrenceValue"
                            formControlName="group18" inputId="8">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>

        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1 ">
                    <span class="__icon ci ci-delete sv-color--red-60"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.DELETE' | translate}}</span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="__icon ci ci-save"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
