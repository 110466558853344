<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Soạn đề thi' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3 pt-3">
                <div class="col-md-12">
                    <div class="float-end">
                        <a class="default-btn me-3" (click)="actionAddHidden.toggle($event)">
                            <i class="bx bx-plus"></i>
                            {{'Thêm mới câu hỏi' | translate}}
                        </a>
                        <a class="default-btn me-3" (click)="onImport()">
                            <i class="bx bx-file"></i>
                            {{'Thêm từ file Word' | translate}}
                        </a>
                        <a class="default-btn me-3" (click)="deleteAll()">
                            <i class="bx bx-trash"></i>
                            {{'Xóa tất cả câu hỏi' | translate}}
                        </a>
                        <a class="second-btn " (click)="goBack()">
                            <i class="bx bx-arrow-back"></i>
                            {{'Trở về' | translate}}
                        </a>
                    </div>
                    <p-overlayPanel #actionAddHidden [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                        appendTo="body" styleClass="sv-overlay-panel style-1">
                        <div class="__item-wrapper">
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.SC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SC) Câu hỏi một lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MC) Câu hỏi nhiều lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MW)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MW) Câu hỏi điền từ vào chỗ trống</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.SQ)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SQ) Câu hỏi sắp xếp thứ tự</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.UP)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(FU) Câu hỏi upload file</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MA)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MA) Câu hỏi ghép đôi phù hợp</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.ES)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(ES) Câu hỏi tự luận</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.SB)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SB) Câu hỏi có câu hỏi phụ</span>
                                </button>
                            </div>
                        </div>
                    </p-overlayPanel>

                    <p-overlayPanel #actionAddHiddenSB [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                        appendTo="body" styleClass="sv-overlay-panel style-1">
                        <div class="__item-wrapper">
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.SC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SC) Câu hỏi một lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.MC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MC) Câu hỏi nhiều lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.MW)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MW) Câu hỏi điền từ vào chỗ trống</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.MA)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MA) Câu hỏi ghép đôi phù hợp</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.SQ)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SQ) Câu hỏi sắp xếp thứ tự</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.UP)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(FU) Câu hỏi upload file</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addSubQuestion(testQuestionType.ES)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(ES) Câu hỏi tự luận</span>
                                </button>
                            </div>
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card mb-2" *ngFor="let item of dataSource; index as i">
                        <div class="card-header bg-success text-white" [ngClass]="{'bg-danger' : item.status == 2}">
                            <div class="row">
                                <div class="col">
                                    <h3 class="panel-title">
                                        Câu {{i + 1}}: ({{item.codeTestQuestionType}}) - ({{item.mark}} điểm)
                                    </h3>
                                </div>
                                <div class="col-auto">
                                    <div class="button-tool">
                                        <a *ngIf="item.idTestQuestionType == 5" (click)="doOpenPanel($event, item)"
                                            pTooltip="Thêm câu hỏi con"><i class="bx bx-plus-circle"
                                                aria-hidden="true"></i></a>
                                        <a (click)="editItem(item)" pTooltip="Sửa"><i class="bx bx-edit"
                                                aria-hidden="true"></i></a>
                                        <a (click)="deleteItem(item)" pTooltip="Xóa"><i class="bx bx-trash-alt"
                                                aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="fw-bold" [innerHTML]="item.questionText"></p>
                                    <div class="row" *ngIf="item.questionFile">
                                        <audio controls>
                                            <source src="{{item.questionFile}}" type="audio/mpeg">
                                        </audio>
                                    </div>
                                </div>
                            </div>
                            <!-- SC -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 1">
                                <div class="row" *ngFor="let ans of item.dsAnswers">
                                    <div class="col-md-1 pd-tb0">
                                        <p-radioButton name="{{item.id}}" value="{{ans.id}}" disabled
                                            [(ngModel)]="+item.correctAnswer">
                                        </p-radioButton>
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- MC -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 2">
                                <div class="row" *ngFor="let ans of item.dsAnswers">
                                    <div class="col-md-1 pd-tb0">
                                        <p-checkbox disabled name="{{ans.id}}" binary="true"
                                            [(ngModel)]="ans.isCorrect"></p-checkbox>
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- SQ -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 6">
                                <div class="row" *ngFor="let ans of item.dsAnswers; index as i">
                                    <div class="col-md-1 pd-tb0">
                                        {{i + 1}}.
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- MA -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 7">
                                <div class="row pd-tb0" *ngFor="let ans of item.dsAnswers; index as i">
                                    <div class="col-md-2 pd-tb0">
                                        {{i + 1}}.
                                    </div>
                                    <div class="col-md-5 pd-tb0" [innerHTML]="ans.answerText"></div>
                                    <div class="col-md-5 pd-tb0" [innerHTML]="ans.matching"></div>
                                </div>
                            </div>

                            <div class="row mb-2" *ngIf="item.idTestQuestionType == 5">
                                <div class="panel main-section">
                                    <div class="course-item text-dark"
                                        *ngFor="let subItem of item.dsSubQuestions; index as j">
                                        <div class="subtitle1">
                                            <div class="row">
                                                <div class="col">
                                                    <h4>Câu {{i + 1}}.{{j+1}}: ({{subItem.maTestQuestionType}})
                                                        -
                                                        ({{subItem.mark}} điểm)</h4>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="button-tool">
                                                        <a (click)="editSubquestion(subItem)" pTooltip="Sửa"><i
                                                                class="fa fa-pencil-square-o"
                                                                aria-hidden="true"></i></a>
                                                        <a (click)="deleteItem(subItem)" pTooltip="Xóa"><i
                                                                class="fa fa-trash-o" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row mb-2">
                                                <div class="col-md-12">
                                                    <p class="fw-bold" [innerHTML]="subItem.questionText">
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- SC -->
                                            <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 1">
                                                <div class="row pd-tb0 " *ngFor="let ans of subItem.dsAnswers">
                                                    <div class="col-md-1 pd-tb0">
                                                        <p-radioButton name="{{subItem.id}}" value="{{ans.id}}" disabled
                                                            [(ngModel)]="+subItem.correctAnswer">
                                                        </p-radioButton>
                                                    </div>
                                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MC -->
                                            <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 2">
                                                <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers">
                                                    <div class="col-md-1 pd-tb0">
                                                        <p-checkbox disabled name="{{ans.id}}" binary="true"
                                                            [(ngModel)]="ans.isCorrect"></p-checkbox>
                                                    </div>
                                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 6">
                                                <div class="row pd-tb0"
                                                    *ngFor="let ans of subItem.dsAnswers; index as i">
                                                    <div class="col-md-1 pd-tb0">
                                                        {{i + 1}}.
                                                    </div>
                                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 7">
                                                <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers">
                                                    <div class="col-md-6 pd-tb0" [innerHTML]="ans.answerText">
                                                    </div>
                                                    <div class="col-md-6 pd-tb0" [innerHTML]="ans.matching">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-course-test-question-sc #pEditSc (closePopup)="onPopupClosed($event)">
</app-course-test-question-sc>
<app-course-test-question-mc #pEditMc (closePopup)="onPopupClosed($event)">
</app-course-test-question-mc>
<app-course-test-question-mw #pEditMW (closePopup)="onPopupClosed($event)">
</app-course-test-question-mw>
<app-course-test-question-es #pEditES (closePopup)="onPopupClosed($event)">
</app-course-test-question-es>
<app-course-test-question-sq #pEditSQ (closePopup)="onPopupClosed($event)">
</app-course-test-question-sq>
<app-course-test-question-ma #pEditMA (closePopup)="onPopupClosed($event)">
</app-course-test-question-ma>
<app-course-test-question-up #pEditUp (closePopup)="onPopupClosed($event)">
</app-course-test-question-up>
<app-course-test-question-sb #pEditSB (closePopup)="onPopupClosed($event)">
</app-course-test-question-sb>

<app-course-test-question-import #pImport (closePopup)="onPopupClosed($event)">
</app-course-test-question-import>
