import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mlearning-features',
  templateUrl: './mlearning-features.component.html',
  styleUrls: ['./mlearning-features.component.scss']
})
export class MlearningFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
