<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li><a routerLink="/teacher">{{'Giảng dạy & đánh giá' | translate}}</a></li>
                <li>{{'Cập nhật thông tin lớp học phần' | translate}}</li>
            </ul>
            <h2>{{'Cập nhật thông tin lớp học phần' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="checkout-area ptb-100">
    <div class="container">
        <!-- <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Returning customer? <a routerLink="/profile-authentication">Click here to login</a></span>
        </div> -->
        <form #formElement [formGroup]="formGroup">
            <div class="row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>{{'Mã' | translate}} <span class="required">*</span></label>
                        <input type="text" class="form-control" placeholder="{{'Nhập mã lớp học phần *' | translate}}"
                            formControlName="code" [(ngModel)]="itemDetail.code">
                    </div>
                    <div class="form-group">
                        <label>{{'Học phần' | translate}} <span class="required">*</span></label>
                        <p-dropdown class="sv-form-item__input sv-dropdown" [options]="dsSubject"
                            [autoDisplayFirst]="false" formControlName="idSvSubject" dropdownIcon="bx bx-chevron-down"
                            appendTo="body" [(ngModel)]="itemDetail.idSvSubject" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                    <div class="form-group">
                        <label>{{'Hình thức đăng ký' | translate}} <span class="required">*</span></label>
                        <p-dropdown class="sv-form-item__input sv-dropdown" [options]="dsRegisterType"
                            formControlName="registerType" [autoDisplayFirst]="false" dropdownIcon="bx bx-chevron-down"
                            appendTo="body" [(ngModel)]="itemDetail.registerType" [filter]="true"
                            filterBy="label,value">
                        </p-dropdown>
                    </div>
                    <div class="form-group">
                        <label>{{'Ảnh đại diện' | translate}} <span class="required">*</span></label>
                        <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event)"
                            formControlName="imageUrl" #hiddenfileinput>
                        <div class="col-md-12" style="padding-bottom: 10px;">
                            <img class="img-responsive" src="{{itemDetail.imageUrl | fsFileUrl}}" alt="...">
                        </div>
                        <a class="btn btn-light" id="upload_link" (click)="hiddenfileinput.click()"
                            *ngIf="itemDetail.idSvSchoolYear == currYear.id">
                            <i class="bx bx-image" aria-hidden="true"></i> Chọn ảnh đại diện
                        </a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>{{'Tiêu đề' | translate}} <span class="required">*</span></label>
                        <input type="text" class="form-control"
                            placeholder="{{'Nhập tiêu đề lớp học phần *' | translate}}" [(ngModel)]="itemDetail.title"
                            formControlName="title">
                    </div>
                    <div class="form-group">
                        <label>{{'Chuyên mục phân loại' | translate}} <span class="required">*</span></label>
                        <p-dropdown class="sv-form-item__input sv-dropdown" [options]="dsCategory"
                            formControlName="idCategory" [autoDisplayFirst]="false" dropdownIcon="bx bx-chevron-down"
                            appendTo="body" [(ngModel)]="itemDetail.idCategory" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                    <div class="form-group">
                        <label>{{'Thời gian bắt đầu học' | translate}} <span class="required">*</span></label>
                        <p-calendar class="sv-form-item__input  sv-datepicker" dateFormat="dd/mm/yy" appendTo="body"
                            formControlName="startDate" [(ngModel)]="itemDetail.startDate">
                        </p-calendar>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <label>{{'Giới thiệu' | translate}}</label>
                        <ck-editor name="editor1" style="width: 100%" [(ngModel)]="itemDetail.introduction"
                            formControlName="introduction">
                        </ck-editor>
                    </div>
                    <div class="form-group">
                        <label>{{'Trạng thái' | translate}} <span class="required">*</span></label>
                        <p-dropdown class="sv-form-item__input sv-dropdown" [options]="arrStatus"
                            formControlName="status" [autoDisplayFirst]="false" dropdownIcon="bx bx-chevron-down"
                            appendTo="body" [(ngModel)]="itemDetail.status" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row row ptb-100">
                <div class="col-lg-12 col-md-12" *ngIf="itemDetail.idSvSchoolYear == currYear.id">
                    <div class="col-lg-6 col-md-6 form-group float-start">
                        <a href="/teacher" class="second-btn"><i class="bx bx-undo" aria-hidden="true"></i>
                            {{'Trở về' | translate}}</a>
                    </div>
                    <div class="col-lg-6 col-md-6 form-group float-end">
                        <a (click)="saveAndClose()" class="default-btn float-end"><i class="bx bx-save"
                                aria-hidden="true"></i>
                            {{'Lưu thông tin' | translate}}</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
