<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li><a routerLink="/courses">{{'Khóa học' | translate}}</a></li>
                <li>{{itemDetail.title}}</li>
            </ul>
            <h2>{{itemDetail.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area ptb-100">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <!-- <div class="courses-title">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy.</p>
                    </div> -->
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>{{'Học phần' | translate}}</span>
                                <a>{{itemSubject.title}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>{{'Số học viên' | translate}}</span>
                                <a>{{itemDetail.countCourseUser}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>{{'Thời gian bắt đầu' | translate}}</span>
                                <a>{{itemDetail.startDate | date : 'dd/MM/yyyy'}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-price" *ngIf="currentUser != null">
                        <!-- <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(3 reviews)</span>
                        </div> -->
                        <!-- <div class="price">$150</div> -->
                        <ng-container *ngIf="currentUser.typeId == 1 && isTeacher">
                            <a (click)="goLearning()" class="default-btn">
                                <i class="flaticon-user"></i>
                                {{'Vào lớp' | translate}}<span></span>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="currentUser.typeId != 1">
                            <ng-container *ngIf="isRegister">
                                <a (click)="goLearning()" class="default-btn">
                                    <i class="flaticon-user"></i>
                                    {{'Vào học' | translate}}<span></span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!isRegister">
                                <a (click)="doRegister()" class="default-btn" *ngIf="itemDetail.registerType != 1">
                                    <i class="flaticon-user"></i>
                                    {{'Đăng ký' | translate}}<span></span>
                                </a>
                                <a class="default-btn" *ngIf="itemDetail.registerType == 1">
                                    <i class="flaticon-user"></i>
                                    {{'Lớp học phần không cho đăng ký' | translate}}<span></span>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-image-style-two text-center">
                    <img src="{{itemDetail.imageUrl | fsFileUrl}}" alt="image">
                </div>
                <div class="courses-details-desc-style-two">
                    <h3>{{'Giới thiệu' | translate}}</h3>
                    <p innerHTML="{{itemDetail.introduction}}"></p>
                    <h3>{{'Nội dung' | translate}}</h3>
                    <div class="courses-curriculum">
                        <ng-template ngFor let-item [ngForOf]="dsCourseItem" let-i="index">
                            <h3>{{item.title}}</h3>
                            <ul>
                                <li *ngFor="let itemChild of item.children">
                                    <a class="popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">{{itemChild.title}}</span>
                                        <div class="courses-meta">
                                            <span class="duration">{{itemChild.totalTime}}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                    <!-- <div class="courses-reviews">
                        <h3>Course Rating</h3>
                        <div class="rating">
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star"></span>
                        </div>
                        <div class="rating-count">
                            <span>4.1 average based on 4 reviews.</span>
                        </div>
                        <div class="row">
                            <div class="side">
                                <div>5 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-5"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>02</div>
                            </div>
                            <div class="side">
                                <div>4 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-4"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>03</div>
                            </div>
                            <div class="side">
                                <div>3 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-3"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>04</div>
                            </div>
                            <div class="side">
                                <div>2 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-2"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>05</div>
                            </div>
                            <div class="side">
                                <div>1 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-1"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>00</div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-sidebar-information">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i>{{'Học phần' | translate}}</span>
                                {{itemSubject.code}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> {{'Giảng viên' | translate}}</span>
                                {{itemDetail.displayNameTeacher}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i>{{'Học liệu' | translate}}</span>
                                {{itemDetail.countCourseItem}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-quiz"></i>{{'Nhiệm vụ' | translate}}</span>
                                {{itemDetail.countCourseItemTask}}
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="btn-box">
                        <a routerLink="/single-courses-2" class="default-btn"><i class="flaticon-shopping-cart"></i>Add
                            to Cart<span></span></a>
                    </div> -->
                    <!-- <div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70" *ngIf="dataSource.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>{{'Khóa học liên quan'}}</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let item of dataSource">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/course-detail/{{item.id}}" class="d-block image">
                            <img src="{{item.imageUrl | fsFileUrl}}" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="{{item.avatarTeacher | fsFileUrl}}" class="rounded-circle" alt="image">
                            <span>{{item.displayNameTeacher}}</span>
                        </div>
                        <h3><a routerLink="/course-detail/{{item.id}}">{{item.title}}</a></h3>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> {{item.countCourseItem}} {{'Nội dung' |
                                translate}}</li>
                            <li><i class='flaticon-people'></i> {{item.countCourseUser}} {{'Học viên' |
                                translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
