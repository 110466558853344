import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../services/banner.service';

@Component({
    selector: 'app-mlearning-banner',
    templateUrl: './mlearning-banner.component.html',
    styleUrls: ['./mlearning-banner.component.scss']
})
export class MlearningBannerComponent implements OnInit {

    dataSource = [];

    constructor(
        private _bannerService: BannerService,
    ) { }

    ngOnInit() {

    }
    getData() {
        this._bannerService.getsAll().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

}
