<p-dialog #dialog header="{{'FsSystem.Title_FolderAdd' | translate}}" class="sv-modal" [(visible)]="isShow"
    [modal]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="ui-g -large-gutter -row sv-form-row">
            </div>

            <div class="ui-g -large-gutter -row sv-form-row">
                <div class="ui-g-12 ui-lg-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="title">{{'FORM.TITLE' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input class="sv-form-item__input sv-text-field" type="text" [(ngModel)]="itemDetail.title"
                                (keyup.enter)="saveAndClose()" formControlName="title">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1 ">
                    <span class="sv-btn__label">{{'BUTTON.DELETE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-delete sv-color--red-60"></span>
                </button>
            </div>
            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
