import { Component, OnInit } from '@angular/core';
import { RootCategory } from '../../../config/enums';
import { SvSchoolYear } from '../../../models/sv-school-year';
import { CategoriesService } from '../../../services/categories.service';
import { CourseService } from '../../../services/course.service';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';

@Component({
    selector: 'app-course-categories',
    templateUrl: './course-categories.component.html',
    styleUrls: ['./course-categories.component.scss']
})
export class CourseCategoriesComponent implements OnInit {
    dsCategory = [];
    currYear: SvSchoolYear = new SvSchoolYear();
    constructor(
        private _courseService: CourseService,
        private _svSchoolYearService: SvSchoolYearService,
    ) { }

    ngOnInit() {
        this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
            this._courseService.getsCategoriesBySvSchoolYear(rs.id, RootCategory.Dm_LopHocPhan).then(rs => {
                if (rs.success) {
                    this.dsCategory = rs.data;
                }
            })
        })


    }

}
