import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseUserService extends BaseService {
    constructor(http: HttpClient, injector: Injector, private _exportService: ExportService) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseuser`);
    }
    getsByIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    GetsLogByIdCourseItem(idCourse: number, idCourseItem: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsLogByIdCourseItem/${idCourse}/${idCourseItem}`;
        return this.defaultGet(apiUrl);
    }

    UpdateMark(idCourse: number, model: any[]): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateMark/${idCourse}`;
        return this._http.post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }


    checkRegistered(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/CheckRegistered/${idCourse}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    registerCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/RegisterCourse/${idCourse}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    studentUpdateNote(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/StudentUpdateNote`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    updateOrderNo(idCourse: number, model: any[]): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateOrderNo/${idCourse}`;
        return this._http.post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsNotRegesterByIdCourse(idCourse: number, idDmNamHoc: number,): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsNotRegesterByIdCourse/${idCourse}/${idDmNamHoc}`;
        return this.defaultGet(apiUrl);
    }

    addsCourseUser(idCourse: number, dsCourseUser: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/AddsCourseUser/${idCourse}`;
        return this._http
            .post<ResponseResult>(apiUrl, dsCourseUser)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    importUsers(idCourse: number, item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/ImportUsers/${idCourse}`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getTemplateImportUsers() {
        const url = `${this.serviceUri}/GetTemplateImportUsers`;
        return this._http
            .post(`${url}`, null, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .subscribe(res => {
                this._exportService.saveAsExcelFile(res, 'users_import');
            });
    }
}
