<p-dialog #dialog header="{{ 'Cập nhật nội dung câu hỏi sắp xếp thứ tự' | translate }}" class="sv-modal"
    [(visible)]="isShow" [modal]="true" [styleClass]="'sv-modal force-full-width'"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">

    <div class="sv-modal-content-panel sv-form-container">

        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="ui-g large-gutter row sv-form-row">
            </div>

            <div class="ui-g large-gutter row sv-form-row">
                <div class="col-lg-12 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="questionText">{{'TestQuestion.QuestionText' | translate}}
                        </div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <ck-editor name="editor1" style="width: 100%" [(ngModel)]="itemDetail.questionText"
                                formControlName="questionText">
                            </ck-editor>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="mark">{{'TestQuestion.Marks' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <p-dropdown class="sv-form-item__input sv-dropdown" formControlName="mark"
                                [options]="dsNumber" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                                appendTo="body" [(ngModel)]="itemDetail.mark" [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="orderNo">{{'Thứ tự' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input type="text" class="sv-form-item__input sv-text-field"
                                [(ngModel)]="itemDetail.orderNo" formControlName="orderNo">
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="ui-g large-gutter row sv-form-row">
            <div class="col-lg-12 col-md-12">
                <div class="sv-form-control type-text-field">
                    <div class="sv-form-item__label">{{'TestQuestion.Answer' | translate}}
                    </div>
                    <div class="sv-form-item__input-wrapper mb-2">
                        <button type="button" (click)="addAnswer()"
                            class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active easing-fast">
                            <span class="sv-btn__label">{{'BUTTON.ADD' | translate}}</span>
                            <span class="__icon ci ci-add"></span>
                        </button>
                    </div>
                    <div class="sv-form-item__input-wrapper -type-text-field">
                        <p-table [value]="itemDetail.dsAnswers" class="sv-table" [style]="{'min-width': '1000px'}"
                            [lazy]="true">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th style="width: 80px;">

                                    </th>
                                    <th>
                                        <div class="overflow-break-word">{{'TestAnswer.AnswerText' | translate}} <button
                                                type="button" (click)="changeEditorView()"
                                                class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1">
                                                <span class="__icon ci ci-arrow-down" *ngIf="!showEditor"
                                                    pTooltip="Hiển thị Editor soạn thảo"></span>
                                                <span class="__icon ci ci-arrow-right" *ngIf="showEditor"
                                                    pTooltip="Hiển thị Textbox soạn thảo"></span>
                                            </button>
                                        </div>
                                    </th>
                                    <th style="width: 80px;">
                                        <div class="overflow-break-word">{{'BUTTON.EDIT' | translate}}</div>
                                    </th>
                                    <th style="width: 80px;">
                                        <div class="overflow-break-word">{{'BUTTON.DELETE' | translate}}</div>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                <tr>
                                    <td>
                                        {{rowIndex + 1}}
                                    </td>
                                    <td>
                                        <div class="overflow-break-word">
                                            <ck-editor name="editor1" style="width: 100%" *ngIf="showEditor"
                                                [(ngModel)]="rowData.answerText">
                                            </ck-editor>
                                            <input class="sv-form-item__input sv-text-field" type="text"
                                                *ngIf="!showEditor" [(ngModel)]="rowData.answerText">
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" (click)="editAnswer(rowIndex)"
                                            class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1">
                                            <span class="__icon ci ci-edit"></span>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" (click)="deleteAnswer(rowIndex)"
                                            class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--red-60 focus-style-1">
                                            <span class="sv-btn__icon ci ci-delete"></span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1 sv-icon-color--red-60">
                    <span class="sv-btn__label">{{ 'BUTTON.DELETE' | translate }}</span>
                    <span class="sv-btn__icon ci ci-delete"></span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" *ngIf="itemDetail.id == 0" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'BUTTON.SAVE_CLOSE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
                <button type="button" (click)="save()" *ngIf="itemDetail.id == 0" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'BUTTON.SAVE_CONTINUE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
                <button type="button" (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
