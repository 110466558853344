import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from "ngx-accordion";
import { FormsModule } from '@angular/forms';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './pages/error/error.component';
import { MlearningUniComponent } from './pages/mlearning-uni/mlearning-uni.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader, SendAccessTokenInterceptor, SvComponentModule, SvCorePublicService, UserFormatPipe, UserService } from 'sv-shared';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonModule, DatePipe } from '@angular/common';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { FooterComponent } from './common/footer/footer.component';
import { ProfileAuthenticationComponent } from './pages/profile-authentication/profile-authentication.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { MlearningBannerComponent } from './pages/mlearning-uni/mlearning-banner/mlearning-banner.component';
import { MlearningFeaturesComponent } from './pages/mlearning-uni/mlearning-features/mlearning-features.component';
import { MlearningAboutComponent } from './pages/mlearning-uni/mlearning-about/mlearning-about.component';
import { MlearningTestimonialsComponent } from './pages/mlearning-uni/mlearning-testimonials/mlearning-testimonials.component';
import { MlearningInstantCoursesComponent } from './pages/mlearning-uni/mlearning-instant-courses/mlearning-instant-courses.component';
import { MlearningBlogComponent } from './pages/mlearning-uni/mlearning-blog/mlearning-blog.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { TeacherIndexComponent } from './pages/teacher/teacher-index/teacher-index.component';
import { TeacherCourseFormComponent } from './pages/teacher/teacher-course-form/teacher-course-form.component';
import { CourseItemComponent } from './pages/teacher/course-item/course-item.component';
import { CourseItemFormComponent } from './pages/teacher/course-item/course-item-form/course-item-form.component';
import { CourseItemDetailComponent } from './pages/teacher/course-item/course-item-detail/course-item-detail.component';
import { CourseItemLiveComponent } from './pages/teacher/course-item/course-item-live/course-item-live.component';
import { CourseItemUserComponent } from './pages/teacher/course-item/course-item-user/course-item-user.component';
import { CourseItemViewComponent } from './pages/teacher/course-item/course-item-view/course-item-view.component';
import { StorageComponent } from './pages/storage/storage.component';
import { CourseItemUserLogComponent } from './pages/teacher/course-item/course-item-user-log/course-item-user-log.component';
import { CourseTestQuestionComponent } from './pages/teacher/course-test-question/course-test-question.component';
import { CourseTestQuestionScComponent } from './pages/teacher/course-test-question/course-test-question-sc/course-test-question-sc.component';
import { CourseTestQuestionMcComponent } from './pages/teacher/course-test-question/course-test-question-mc/course-test-question-mc.component';
import { CourseTestQuestionMwComponent } from './pages/teacher/course-test-question/course-test-question-mw/course-test-question-mw.component';
import { CourseTestQuestionSqComponent } from './pages/teacher/course-test-question/course-test-question-sq/course-test-question-sq.component';
import { CourseTestQuestionEsComponent } from './pages/teacher/course-test-question/course-test-question-es/course-test-question-es.component';
import { CourseTestQuestionMaComponent } from './pages/teacher/course-test-question/course-test-question-ma/course-test-question-ma.component';
import { CourseTestQuestionUpComponent } from './pages/teacher/course-test-question/course-test-question-up/course-test-question-up.component';
import { CourseTestQuestionSbComponent } from './pages/teacher/course-test-question/course-test-question-sb/course-test-question-sb.component';
import { CourseTestQuestionImportComponent } from './pages/teacher/course-test-question/course-test-question-import/course-test-question-import.component';
import { CourseItemUserLearningLogComponent } from './pages/teacher/course-item/course-item-user-learning-log/course-item-user-learning-log.component';
import { PagingComponent } from './common/paging/paging.component';
import { CourseDetailComponent } from './pages/courses/course-detail/course-detail.component';
import { InteractiveVideoComponent } from './pages/teacher/interactive-video/interactive-video.component';
import { InteractiveVideoFormComponent } from './pages/teacher/interactive-video/interactive-video-form/interactive-video-form.component';
import { InteractiveVideoFormTestComponent } from './pages/teacher/interactive-video/interactive-video-form-test/interactive-video-form-test.component';
import { InteractiveVideoTestQuestionComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question.component';
import { InteractiveVideoTestQuestionScComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-sc/interactive-video-test-question-sc.component';
import { InteractiveVideoTestQuestionMcComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-mc/interactive-video-test-question-mc.component';
import { InteractiveVideoTestQuestionMwComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-mw/interactive-video-test-question-mw.component';
import { InteractiveVideoTestQuestionSqComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-sq/interactive-video-test-question-sq.component';
import { InteractiveVideoTestQuestionMaComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-ma/interactive-video-test-question-ma.component';
import { InteractiveVideoTestQuestionImportComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question-import/interactive-video-test-question-import.component';
import { InteractiveVideoPlayerComponent } from './pages/player/interactive-video-player/interactive-video-player.component';
import { VideoPlayerComponent } from './pages/player/video-player/video-player.component';
import { InteractiveVideoViewComponent } from './pages/teacher/interactive-video/interactive-video-view/interactive-video-view.component';

import { DragDropModule } from 'primeng/dragdrop';
import { StudentIndexComponent } from './pages/student/student-index/student-index.component';
import { StudentTaskUnfinishedComponent } from './pages/student/student-task-unfinished/student-task-unfinished.component';
import { LearningSpaceComponent } from './pages/learning-space/learning-space.component';
import { IconCourseItemTypePipe } from './pipes/course-item-type.pipe';
import { CountdownModule } from 'ngx-countdown';
import { EditorModule } from 'primeng/editor';
import { CourseItemUserExamLogComponent } from './pages/teacher/course-item/course-item-user-exam-log/course-item-user-exam-log.component';
import { LearningSpaceForumComponent } from './pages/learning-space/learning-space-forum/learning-space-forum.component';
import { LearningSpaceForumAddTopicComponent } from './pages/learning-space/learning-space-forum/learning-space-forum-add-topic/learning-space-forum-add-topic.component';
import { LearningSpaceForumLatestPostComponent } from './pages/learning-space/learning-space-forum/learning-space-forum-latest-post/learning-space-forum-latest-post.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { NewWidgetAreaComponent } from './pages/news/new-widget-area/new-widget-area.component';
import { CourseCategoriesComponent } from './pages/courses/course-categories/course-categories.component';
import { StudentCourseResultComponent } from './pages/student/student-course-result/student-course-result.component';
import { DocumentComponent } from './pages/document/document.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SvLocalizationModule } from './localization/svlocalization.module';
import '@angular/common/locales/global/vi'
import { LearningSpaceUsersComponent } from './pages/learning-space/learning-space-users/learning-space-users.component';
import { LearningSpaceLiveclassComponent } from './pages/learning-space/learning-space-liveclass/learning-space-liveclass.component';
import { LearningSpaceTableHistoryComponent } from './pages/learning-space/learning-space-table-history/learning-space-table-history.component';
import { AdminRedirectComponent } from './pages/admin-redirect/admin-redirect.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http,
        [
            { prefix: './assets/i18n/', suffix: '.json' },
            { prefix: './assets/i18n/shared/', suffix: '.json' }
        ]
    );
}

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        LearningLayoutComponent,
        NavbarComponent,
        FooterComponent,
        ErrorComponent,
        MlearningUniComponent,
        ProfileAuthenticationComponent,
        MlearningBannerComponent,
        MlearningFeaturesComponent,
        MlearningAboutComponent,
        MlearningTestimonialsComponent,
        MlearningInstantCoursesComponent,
        MlearningBlogComponent,
        CoursesComponent,
        ProfileComponent,
        CoursesComponent,
        TeacherIndexComponent,
        TeacherCourseFormComponent,
        CourseItemComponent,
        CourseItemFormComponent,
        CourseItemDetailComponent,
        CourseItemLiveComponent,
        CourseItemUserComponent,
        CourseItemViewComponent,
        StorageComponent,
        CourseItemUserLogComponent,
        CourseTestQuestionComponent,
        CourseTestQuestionScComponent,
        CourseTestQuestionMcComponent,
        CourseTestQuestionMwComponent,
        CourseTestQuestionSqComponent,
        CourseTestQuestionEsComponent,
        CourseTestQuestionMaComponent,
        CourseTestQuestionUpComponent,
        CourseTestQuestionSbComponent,
        CourseTestQuestionImportComponent,
        CourseItemUserLearningLogComponent,
        PagingComponent,
        CourseDetailComponent,
        CourseCategoriesComponent,

        InteractiveVideoComponent,
        InteractiveVideoFormComponent,
        InteractiveVideoFormTestComponent,
        InteractiveVideoTestQuestionComponent,
        InteractiveVideoTestQuestionScComponent,
        InteractiveVideoTestQuestionMcComponent,
        InteractiveVideoTestQuestionMwComponent,
        InteractiveVideoTestQuestionSqComponent,
        InteractiveVideoTestQuestionMaComponent,
        InteractiveVideoTestQuestionImportComponent,
        InteractiveVideoViewComponent,
        InteractiveVideoPlayerComponent,
        VideoPlayerComponent,
        CourseItemUserExamLogComponent,
        StudentIndexComponent,
        StudentTaskUnfinishedComponent,
        StudentCourseResultComponent,

        LearningSpaceComponent,
        IconCourseItemTypePipe,
        LearningSpaceForumComponent,
        LearningSpaceForumAddTopicComponent,
        LearningSpaceForumLatestPostComponent,
        LearningSpaceUsersComponent,
        LearningSpaceLiveclassComponent,
        LearningSpaceTableHistoryComponent,

        NewsComponent,
        NewWidgetAreaComponent,
        NewsDetailComponent,
        DocumentComponent,
        AdminRedirectComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        HttpClientModule,
        SvComponentModule,
        CKEditorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, SvCorePublicService]
            }
        }),
        SvLocalizationModule.forRoot({ locale_id: 'vi' }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiDomain.gateway],
                sendAccessToken: false
            }
        }),
        DragDropModule,
        CountdownModule,
        EditorModule,
        NgHttpLoaderModule.forRoot(),
        NgScormPlayerModule.forChild({ debug: true }),
        //NgxGoogleAnalyticsModule.forRoot(environment.appMetadata.main.ga),
        NgxGoogleAnalyticsRouterModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SendAccessTokenInterceptor,
            multi: true
        },
        MessageService, ConfirmationService, DatePipe, UserFormatPipe, UserService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
