<p-dialog #dialog header="{{ 'Tạo chủ đề mới' | translate }}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '1300px'}" closeIcon="bx bx-x" maximizeIcon="bx bx-expand" minimizeIcon="bx bx-collapse"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">
                <div class="ui-g-12 ui-lg-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="title">{{'FORM.TITLE' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input class="sv-form-item__input sv-text-field" type="text" [(ngModel)]="itemDetail.title"
                                formControlName="title">
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="bodyHtml">Nội dung</div>
                        <div class="sv-form-item__input-wrapper">
                            <div class="col-md-12">
                                <p-editor [(ngModel)]="itemDetail.bodyHtml"
                                    [style]="{'height':'200px', 'width': '100%'}" formControlName="bodyHtml"></p-editor>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">

            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="__icon ci ci-save"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
