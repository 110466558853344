import { Pipe, PipeTransform } from '@angular/core';
import { isNgTemplate } from '@angular/compiler';

@Pipe({
    name: 'iconCurseItemType'
})
export class IconCourseItemTypePipe implements PipeTransform {

    transform(value: number, args?: any): any {
        if (value >= 10 && value < 20) {
            return 'ci ci-video-chat';
        }
        if (value == 21) {
            return 'ci ci-checkbox-checked'
        }
        if (value == 22) {
            return 'ci ci-document'
        }
    }

}
