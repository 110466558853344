<div class="features-area pt-100 pb-70 bg-fff8f8">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Học mọi lúc - mọi nơi</span>
            <h2>Các bài học tương tác giúp sinh viên học tập trực tuyến hiệu quả</h2>
            <p>Interactive Video - công nghệ xây dựng bài giảng tiên tiến nhất</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Học bất cứ khi nào</h3>
                    <p>Học viên có thể học bất cứ nơi đâu, bất cứ khi nào trên nhiều thiết bị như điện thoại, máy tính
                        bảng, laptop.. có kết nối Internet </p>
                    <div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Lớp học trực tuyến</h3>
                    <p>mLearning có tất cả những gì bạn cần cho việc tổ chức học trực tuyến, họp trực tuyến, hội thảo
                        trực tuyến. </p>
                    <div class="back-icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Đại học trực tuyến</h3>
                    <p>mLearning đáp ứng tất cả các loại hình đào tạo: học online kết hợp với giảng đường, học online
                        100%, ... </p>
                    <div class="back-icon">
                        <i class="flaticon-world"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
