<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">THỬ NGHIỆM</span>
            <h2>Phản hồi từ học viên của chúng tôi</h2>
        </div>
        <div class="testimonials-slides-two">
            <owl-carousel-o [options]="testimonialsSlidesTwo">
                <ng-template carouselSlide *ngFor="let item of dataSource">
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <p [innerHTML]="item.bodyHtml"></p>
                                <h3>{{item.displayName}}</h3>
                                <span>{{item.roleName}}</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="{{item.avatarUrl}}" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
