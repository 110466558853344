import { Component, Injector, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageIndexBase } from 'sv-shared';
import { CourseUserService } from '../../../services/course-user.service';

@Component({
    selector: 'app-learning-space-users',
    templateUrl: './learning-space-users.component.html',
    styleUrls: ['./learning-space-users.component.scss']
})
export class LearningSpaceUsersComponent extends SecondPageIndexBase implements OnInit {

    idCourse = 0;
    isScroll = false;
    constructor(
        protected _courseuserService: CourseUserService,
        protected _injector: Injector,
    ) {
        super(_courseuserService, _injector);
    }

    ngOnInit() {
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourse = pid;
                this.getData();
            });
    }


    getData() {
        if (this.isScroll == false) {
            this.dataSource = [];
        }

        var searchModel = {
            keyword: this.query,
            status: this.status,
            idCourse: this.idCourse,
            idOrganization: 0,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            offset: (this.pageIndex - 1) * this.pageSize,
            orderCol: this.orderCol ? this.orderCol : 'JoinTime',
            isDesc: this.isDesc,
        };
        this._courseuserService.getsByIdCourse(this.idCourse)
            .then(rs => {
                if (this.isScroll == false) {
                    this.dataSource = rs.data;
                } else {
                    this.dataSource.push(...rs.data);
                }
                this.totalRecord = rs.totalRecord;
                this.isLoading = false;
                this.isScroll = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });

        this.resetBulkSelect();
    }

    onScroll() {
        this.isScroll = true;
        this.pageIndex += 1;
        this.getData();
    }
}


