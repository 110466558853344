import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseItemService } from '../../../../services/course-item.service';
import { CourseTopicPostService } from '../../../../services/course-topic-post.service';

@Component({
    selector: 'app-learning-space-forum-latest-post',
    templateUrl: './learning-space-forum-latest-post.component.html',
    styleUrls: ['./learning-space-forum-latest-post.component.scss']
})
export class LearningSpaceForumLatestPostComponent implements OnInit {

    @Input() idCourse: number;
    dataSource = [];
    dsRecentItem = [];
    @Output() goItem = new EventEmitter<any>();

    constructor(
        private _courseTopicPostService: CourseTopicPostService,
        protected _courseItemService: CourseItemService,
    ) { }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this._courseTopicPostService.getsLatestPost(this.idCourse, 5).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
        this._courseItemService.getRecentItem(this.idCourse).then(rs => {
            if (rs.success) {
                this.dsRecentItem = rs.data;
            }
        })
    }

    viewCourseItem(courseItem: any) {
        this.goItem.next({ type: 1, value: courseItem });
    }

}
