import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'avatar'
})
export class AvatarPipe implements PipeTransform {

    transform(value: string, args?: any): any {

        return `assets/img/avatars/${value?.charAt(0).toUpperCase()}.svg`
    }

}
