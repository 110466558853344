import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { catchError, shareReplay, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SvCorePublicService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/SvCorePublic`);
    }

    GetsByTrangThai(trangThai: number) {
        const queryString = `${this.serviceUri}/GetsByTrangThai/${trangThai}`;
        return this.defaultGet(queryString);
    }

    getsResourceByLanguage(lang: string) {
        const queryString = `${this.serviceUri}/GetsResourceByLanguage/${lang}`;
        return this.defaultGet(queryString);
    }

    resetPassword(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/resetPassword`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getsResourceByLanguageInStorage(lang: string): Promise<ResponseResult> {
        var data = JSON.parse(localStorage.getItem(`language.${lang}`));
        return data;
    }
}
