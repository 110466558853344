import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../../../src/environments/environment';
const signalR = require("@microsoft/signalr");
@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private _hubConnection: HubConnection;

    connections = [];
    hubs = {};
    delayStarters = [];
    connected = false;

    maxRetryTimes = 10;
    viewCodeSubs: {
        viewCode: string,
        callback: (data: any) => void
    }[];

    constructor(
        private _authenService: AuthenticationService
    ) {
        // this.init();
    }

    async initCommonHub() {
        this.hubs['CommonHub'] = this.createConnection('CommonHub');
        await this.startConnection(this.hubs['CommonHub']);
        this.autoReconnect(this.hubs['CommonHub']);
        while (this.delayStarters.length > 0) {
            this.delayStarters.pop()();
        }
        this.connected = true;

    }

    async initNotificationHub() {
        this.hubs['NotificationHub'] = this.createConnection('NotificationHub');
        await this.startConnection(this.hubs['NotificationHub']);
        this.autoReconnect(this.hubs['NotificationHub']);
        while (this.delayStarters.length > 0) {
            this.delayStarters.pop()();
        }
        this.connected = true;

    }

    start(topic: string, viewCode: string, callback: () => void) {
        if (!topic) {
            topic = 'CommonHub';
        }
        if (!this.connected) {
            this.delayStarters.push(() => {
                this.subscribeViewCode(this.hubs[topic], viewCode, callback);
            });
        } else {
            this.subscribeViewCode(this.hubs[topic], viewCode, callback);
        }

    }

    stop() {
        this._hubConnection.stop();
    }

    subscribeViewCode(hubConnection: HubConnection, viewCode: string, callback: (data: any) => void) {

        if (!this.viewCodeSubs) {
            this.viewCodeSubs = [{ viewCode, callback }];
            this.subscribe(hubConnection, viewCode, callback);
        }

        if (this.viewCodeSubs.findIndex(x => x.viewCode === viewCode) === -1) {
            this.viewCodeSubs.push({ viewCode, callback });
            this.subscribe(hubConnection, viewCode, callback);
        }
    }

    unSubscribeViewCode(topic: string, viewCode: string) {
        if (!topic) {
            topic = 'CommonHub';
        }
        const hubConnection = this.hubs[topic];
        if (this.viewCodeSubs) {
            this.viewCodeSubs = this.viewCodeSubs.filter(x => x.viewCode !== viewCode);
            this.unSubscribe(hubConnection, viewCode);
        }
    }

    private createConnection(topic: string) {
        return new signalR.HubConnectionBuilder()
            .withUrl(`${environment.apiDomain.notificationHub}/${topic}`,
                { accessTokenFactory: () => this._authenService.getAccessToken() })
            .build();
    }

    private autoReconnect(hubConnection: HubConnection) {
        hubConnection.onclose(async () => {
            await this.startConnection(hubConnection);
        });
    }

    private wait = ms => new Promise(r => setTimeout(r, ms));

    private startConnection(hubConnection: HubConnection, retryCount = 1): Promise<any> {
        return new Promise(async (resolve, reject) => {
            Object.defineProperty(WebSocket, 'OPEN', { value: 1, });

            hubConnection
                .start().then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    if (this.maxRetryTimes - retryCount > 0) {

                        return this.wait(retryCount * retryCount * 1000)
                            .then(this.startConnection.bind(this, hubConnection, ++retryCount))
                            .then(resolve)
                            .catch(reject);
                    }
                    return reject(err);
                });
        });
    }

    private subscribe(hubConnection: HubConnection, viewCode: string, callback: (data: any) => void) {
        if (viewCode) {
            //hubConnection.invoke('JoinRoom', viewCode).catch(err => console.error(err.toString()));
            switch (viewCode) {
                case 'CommonHub':
                    hubConnection.on('BroadcastMessageTypeId', (messageTypeId: number) => {
                        callback({ messageTypeId: messageTypeId });
                    });
                    break;
                case 'NotificationHub':
                    hubConnection.on('BroadcastMessage', (msg: string) => {
                        callback({ userId: null, msg: msg });
                    });

                    hubConnection.on('SendMessageToUserId', (userId: string, msg: string) => {
                        callback({ userId, msg: msg });
                    });
                    break;
                default:
                    hubConnection.on(viewCode, (data: any) => {
                        callback(data);
                    });
                    break;
            }
        }
    }

    private unSubscribe(hubConnection: HubConnection, viewCode: string) {
        if (viewCode) {
            hubConnection.invoke('leaveRoom', viewCode).catch(err => console.error(err.toString()));
            hubConnection.off(viewCode);
        }
    }
}
