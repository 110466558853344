import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { InteractiveVideoTestService } from '../../../../services/interactive-video-test.service';
import { InteractiveVideoService } from '../../../../services/interactive-video.service';

@Component({
    selector: 'app-interactive-video-form-test',
    templateUrl: './interactive-video-form-test.component.html',
    styleUrls: ['./interactive-video-form-test.component.scss']
})
export class InteractiveVideoFormTestComponent extends SecondPageEditBase implements OnInit {

    @ViewChild('video') video: ElementRef;
    videoInfo = { videoUrl: '' }
    constructor(
        protected _interactiveVideoService: InteractiveVideoService,
        protected _interactivevideotestService: InteractiveVideoTestService,
        protected _injector: Injector,
    ) {
        super(_interactivevideotestService, _injector);

        this.formGroup = new FormGroup({
            title: new FormControl('', Validators.required),
            startTime: new FormControl('', Validators.required),
            // testTime: new FormControl('', Validators.required),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    async onShowPopup(data: { idInteractiveVideo: number, id: number }) {
        // this.resetForm();
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        this.getVideoInfo(data.idInteractiveVideo);
        if (data.id > 0) {
            this._interactivevideotestService.getDetail(data.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idInteractiveVideo: data.idInteractiveVideo };
            this.submitting = false;
        }
    }

    getVideoInfo(id: number) {
        this._interactiveVideoService.getDetail(id).then(rs => {
            if (rs.success) {
                this.videoInfo = rs.data;
            }
        })
    }
    getStartTime() {
        this.itemDetail.startTime = parseInt(this.video.nativeElement.currentTime);
    }
}

