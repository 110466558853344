import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase, UserService } from 'sv-shared';
import { environment } from '../../../environments/environment';
import { FsItemPublicService } from '../../services/fsitem-public';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends SecondPageEditBase implements OnInit {
    isEdit = false;
    errorText = '';
    @ViewChild('hiddenfileinput') hiddenfileinput: ElementRef;
    constructor(
        protected _userService: UserService,
        protected _injector: Injector,
        private _fsItemPublicService: FsItemPublicService
    ) {
        super(_userService, _injector);
    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            userName: new FormControl('', Validators.required),
            displayName: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            phoneNumber: new FormControl(''),
            introduction: new FormControl('')
        });

        this._userService.getMyInfo().then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        })
    }
    goEdit() {
        this.isEdit = true;

    }
    doUpdateInfo() {
        this.submitting = true;
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            this.scrollToTop();
            this.submitting = false;
            return;
        }
        this._userService.updateMyInfo(this.itemDetail).then(rs => {
            this.submitting = false;
            if (rs.success) {
                this._notifierService.showUpdateDataSuccess();
            } else {
                this.errorText = '';
                this._notifierService.showUpdateDataFailed();
            }
        })

    }

    doChangePass() {
        this.errorText = '';
        console.log(this.itemDetail);
        if (this.itemDetail.oldPassword == '' || this.itemDetail.oldPassword == null) {
            this.errorText = 'Chưa nhập mật khẩu cũ';
            this._notifierService.showWarning(this.errorText);
            return;
        }
        if (this.itemDetail.newPassword == '' || this.itemDetail.newPassword == null || this.itemDetail.confirmPassword == '' || this.itemDetail.confirmPassword == null) {
            this.errorText = 'Chưa nhập mật khẩu mới';
            this._notifierService.showWarning(this.errorText);
            return;
        }
        if (this.itemDetail.newPassword != this.itemDetail.confirmPassword) {
            this.errorText = 'Mật khẩu mới không khớp';
            this._notifierService.showWarning(this.errorText);
            return;
        }

        this._userService.changeMyPassword(this.itemDetail).then(rs => {
            if (rs.success) {
                this._notifierService.showUpdateDataSuccess();
                this.itemDetail.oldPassword = '';
                this.itemDetail.newPassword = '';
                this.itemDetail.confirmPassword = '';
            } else {
                this._notifierService.showUpdateDataFailed();
            }
        }, error => {
            console.log(error.error.error);
            if (error.error.error === 'PASSWORD_NOT_MATCHING') {
                this._notifierService.showWarning('Mật khẩu cũ không đúng');

            } else {
                this._notifierService.showUpdateDataFailed();
            }
            this.submitting = false;
        });
    }

    myUploader(event) {
        const files = event.files;
        if (files.length > 0) {

        }
    }

    async onFileSelect(event) {
        const selectedFile = event.target.files[0];
        console.log(selectedFile.name);
        const rawFileName = selectedFile.name;
        const formData: FormData = new FormData();
        formData.append(rawFileName, selectedFile);
        this.itemDetail.avatar = "";
        await this._fsItemPublicService.uploadAvatar(formData).then(rs => {
            if (rs.success) {
                this.itemDetail.avatar = rs.data;
                this._notifierService.showUpdateDataSuccess();
                this._userService.updateMyInfo(this.itemDetail).then(rs => {
                    if (rs.success) {
                        this._notifierService.showUpdateDataSuccess();
                    }
                })
            } else {
                this._notifierService.showHttpUnknowError();
            }
        }).catch(err => {
            console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
        });
    }
    getAvatarUrl(storageUrl: string) {
        var imgUrl = storageUrl !== null ? `${environment.apiDomain.svFsSystemEndpoint}/${storageUrl}` : 'assets/img/advisor/img10.jpg';
        return imgUrl;
    }
}
