<div class="sv-page-content-area__body">
    <div class="sv-data-view-area">
        <div class="sv-data-view-area__main-block">
            <div class="ui-g-12 ui-lg-12">
                <div class="ui-g-6 ui-lg-6">
                    <div class="posts">
                        <div class="posts__body">
                            <ng-container *ngFor="let item of dsRecentItem; index as i">
                                <div class="posts__item" [class.posts__item--bg-gradient]="i % 3 == 0"
                                    [class.bg-f2f4f6]="i % 3 == 2" [class.bg-a7cdbd]="i % 3 == 2">
                                    <div class="posts__section-left">
                                        <div class="posts__topic">
                                            <div class="posts__content">
                                                <a (click)="viewCourseItem(item)">{{item.title}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="posts__section-right">
                                        <div class="posts__users js-dropdown">
                                            <div pTooltip="{{item.modifiedByName}}">
                                                <a class="avatar"><i
                                                        class="{{item.idCourseItemType | iconCurseItemType}}"></i></a>
                                            </div>
                                        </div>
                                        <div class="posts__activity">{{item.modified |
                                            date:'dd/MM/yyyy HH:mm'}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="ui-g-6 ui-lg-6">
                    <div class="posts">
                        <div class="posts__head">
                            <div class="posts__topic">{{'Chủ đề' | translate}}</div>
                            <div class="posts__users">{{'Người tạo' | translate}}</div>
                            <div class="posts__activity">{{'Hoạt động' | translate}}</div>
                        </div>
                        <div class="posts__body">
                            <ng-container *ngFor="let item of dataSource; index as i">
                                <div class="posts__item" [class.posts__item--bg-gradient]="i % 3 == 0"
                                    [class.bg-f2f4f6]="i % 3 == 2" [class.bg-a7cdbd]="i % 3 == 2">
                                    <div class="posts__section-left">
                                        <div class="posts__topic">
                                            <div class="posts__content">
                                                <a>
                                                    <h3>{{item.title}}</h3>
                                                </a>
                                                <p [innerHTML]="item.bodyHtml"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="posts__section-right">
                                        <div class="posts__users js-dropdown">
                                            <div pTooltip="{{item.modifiedByName}}">
                                                <a class="avatar"><img src="{{item.modifiedByName | avatar}}"
                                                        alt="avatar"></a>
                                            </div>
                                        </div>
                                        <div class="posts__activity">{{item.modified | timeAgo}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
