<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li><a routerLink="/">{{'Giảng dạy & đánh giá' | translate}}</a> </li>
                <li>{{itemCourse.title}}</li>
            </ul>
            <h2>{{itemCourse.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3 pt-3">
                <div class="col-md-12">
                    <a class="second-btn float-end"
                        *ngIf="currentUser.userId == itemCourse.userIdTeacher && tabIndex == 1" (click)="goEditInfo()">
                        <i class="bx bx-pencil"></i>
                        {{'Thông tin cơ bản' | translate}}
                    </a>
                    <a class="default-btn float-end me-3"
                        *ngIf="tabIndex==1 && itemCourse.idSvSchoolYear == currYear.id" (click)="add()">
                        <i class="bx bx-plus"></i>
                        {{'Thêm mới nội dung' | translate}}
                    </a>

                    <a class="second-btn float-end"
                        *ngIf="currentUser.userId == itemCourse.userIdTeacher && itemCourse.idSvSchoolYear == currYear.id && tabIndex == 3"
                        (click)="showAddUser()">
                        <i class="bx bx-plus"></i>
                        {{'Thêm sinh viên' | translate}}
                    </a>
                    <a class="default-btn float-end me-3"
                        *ngIf="tabIndex==3 && itemCourse.idSvSchoolYear == currYear.id && !isEditOrderNo"
                        (click)="showEditOrderNo()">
                        <i class="bx bx-pencil"></i>
                        {{'Cập nhật danh sách lớp' | translate}}
                    </a>
                    <a class="default-btn float-end me-3"
                        *ngIf="tabIndex==3 && itemCourse.idSvSchoolYear == currYear.id && isEditOrderNo"
                        (click)="onUpdateOrderNo()">
                        <i class="bx bx-save"></i>
                        {{'Lưu danh sách lớp' | translate}}
                    </a>

                    <a class="default-btn float-end"
                        *ngIf="currentUser.userId == itemCourse.userIdTeacher && itemCourse.idSvSchoolYear == currYear.id && tabIndex == 4"
                        (click)="addLiveItem()">
                        <i class="bx bx-plus"></i>
                        {{'Thêm mới buổi học trực tuyến' | translate}}
                    </a>
                </div>
            </div>

            <ul class="nav-tabset">
                <li class="nav-tab" (click)="goTab(1)" [class.active]="tabIndex == 1">
                    <span>{{'Nội dung học' | translate}}</span>
                </li>
                <li class="nav-tab" (click)="goTab(2)" [class.active]="tabIndex == 2">
                    <span>{{'Nhiệm vụ học tập' | translate}}</span>
                </li>
                <li class="nav-tab" (click)="goTab(3)" [class.active]="tabIndex == 3">
                    <span>{{'Danh sách lớp' | translate}}</span>
                </li>
                <li class="nav-tab" (click)="goTab(4)" [class.active]="tabIndex == 4">
                    <span>{{'Buổi học trực tuyến' | translate}}</span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" *ngIf="tabIndex == 1">
                    <div class="col-lg-12">
                        <div class="alert alert-success" role="alert">
                            Nội dung hiện tại: <span class="label label-primary">
                                <b>{{statistic.countIdType1}}</b> nhóm</span> |
                            <span class="label label-info"><b>{{statistic.countIdType10}}</b> học
                                liệu</span> |
                            <span class="label label-danger"><b>{{statistic.countIdType20}}</b>
                                nhiệm vụ</span>
                        </div>
                        <div class="card" *ngFor="let item of dataSource">
                            <div class="card-header bg-success text-white" [ngClass]="{'bg-danger' : item.status == 2}">
                                <div class="row">
                                    <div class="col">
                                        <h3 class="panel-title">{{item.title}}
                                        </h3>
                                    </div>
                                    <div class="col-auto">
                                        <div class="button-tool">
                                            <a (click)="addChildItem(item)" pTooltip="Thêm nội dung"
                                                *ngIf="itemCourse.idSvSchoolYear == currYear.id"><i
                                                    class="bx bx-plus-circle"></i></a>
                                            <a (click)="goDetail(item)" pTooltip="Xem"
                                                *ngIf="itemCourse.idSvSchoolYear != currYear.id">
                                                <i class="bx bx-search"></i>
                                            </a>
                                            <a (click)="editItem(item)" pTooltip="Sửa"
                                                *ngIf="itemCourse.idSvSchoolYear == currYear.id"><i
                                                    class="bx bx-edit"></i></a>
                                            <a (click)="updateTrangThai(item,1)"
                                                *ngIf="item.status != 1 && itemCourse.idSvSchoolYear == currYear.id"
                                                pTooltip="Xuất bản cho học sinh"><i class="bx bx-chevrons-up"></i></a>
                                            <a (click)="updateTrangThai(item,2)"
                                                *ngIf="item.status != 2 && itemCourse.idSvSchoolYear == currYear.id"
                                                pTooltip="Gỡ xuống"><i class="bx bx-chevrons-down"></i></a>
                                            <a (click)="deleteItem(item)" pTooltip="Xóa"
                                                *ngIf="itemCourse.idSvSchoolYear == currYear.id"><i
                                                    class="bx bx-trash-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="course-item text-dark" *ngFor="let childItem of item.children">
                                <div class="subtitle1" [ngClass]="{'subtitle2' : childItem.status == 2}">
                                    <div class="row">
                                        <div class="col">
                                            <h4>{{childItem.title}}</h4>
                                        </div>
                                        <div class="col-auto">
                                            <div class="button-tool text-dark">
                                                <a (click)="viewLearningHistory(childItem)" pTooltip="Lịch sử học tập"
                                                    *ngIf="childItem.idCourseItemType > 10 && childItem.idCourseItemType < 20">
                                                    <i class="bx bx-history"></i>
                                                </a>
                                                <a (click)="goView(childItem)" pTooltip="Xem trước">
                                                    <i class="bx bx-search"></i>
                                                </a>
                                                <a *ngIf="childItem.status == 2 && childItem.idCourseItemType == 21 && itemCourse.idSvSchoolYear == currYear.id"
                                                    (click)="editTest(childItem)" pTooltip="Soạn đề thi">
                                                    <i class="bx bx-cog"></i>
                                                </a>
                                                <a (click)="goDetail(childItem)" pTooltip="Xem"
                                                    *ngIf="itemCourse.idSvSchoolYear != currYear.id">
                                                    <i class="bx bx-search"></i>
                                                </a>
                                                <a (click)="editItem(childItem)" pTooltip="Sửa"
                                                    *ngIf="itemCourse.idSvSchoolYear == currYear.id">
                                                    <i class="bx bx-edit"></i></a>
                                                <a (click)="updateTrangThai(childItem,1)"
                                                    *ngIf="childItem.status != 1 && itemCourse.idSvSchoolYear == currYear.id"
                                                    pTooltip="Xuất bản cho học sinh"><i
                                                        class="bx bx-chevrons-up"></i></a>
                                                <a (click)="updateTrangThai(childItem,2)"
                                                    *ngIf="childItem.status != 2 && itemCourse.idSvSchoolYear == currYear.id"
                                                    pTooltip="Gỡ xuống"><i class="bx bx-chevrons-down"></i></a>
                                                <a (click)="deleteItem(childItem)" pTooltip="Xóa"
                                                    *ngIf="itemCourse.idSvSchoolYear == currYear.id">
                                                    <i class="bx bx-trash-alt"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 mb-20" *ngIf="childItem.startTime">
                                            <span>
                                                <i class="bx bx-time"></i> Thời gian học từ:
                                                {{childItem.startTime | date:'dd/MM/yyyy HH:mm'}}
                                            </span>
                                            <span *ngIf="childItem.endTime">
                                                - đến: {{childItem.endTime | date:'dd/MM/yyyy HH:mm'}}
                                            </span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 mb-20" *ngIf="childItem.idCourseItemType == 21">
                                            <span>Tổng số câu hỏi: {{childItem.countQuestion}} /
                                                Điểm: {{childItem.totalMark}} / Thời gian làm bài:
                                                {{childItem.totalTime}} (phút)</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 mb-20"
                                            *ngIf="childItem.idCourseItemType != 1 && childItem.dsAttactment && childItem.dsAttactment.length > 0">
                                            <h6>Tài liệu đính kèm</h6>
                                            <ul>
                                                <li *ngFor="let itemAt of childItem.dsAttactment;  index as i">
                                                    <a href="{{itemAt | fsFileUrl}}" target="_blank">
                                                        {{i + 1}}.&nbsp; {{itemAt | fileName}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="pane" *ngIf="tabIndex == 2">
                    <div class="col-lg-12">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width: 80px">STT</th>
                                    <th rowspan="2">Tiêu đề</th>
                                    <th rowspan="2">Loại</th>
                                    <th colspan="2">Thời gian</th>
                                    <th colspan="2" rowspan="2">Thao tác</th>
                                </tr>
                                <tr>
                                    <th>Bắt đầu</th>
                                    <th>Kết thúc</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dsTask; index as i"
                                    [style.background]="item.approvalMark !== true ? 'mistyrose' : ''">
                                    <td>{{i + 1}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.idCourseItemType == 21? 'Thi trực tuyến': 'Bài tập'}}
                                    </td>
                                    <td>{{item.startTime | date:'dd/MM/yyyy HH:mm' }}</td>
                                    <td>{{item.endTime | date:'dd/MM/yyyy HH:mm' }}</td>
                                    <td align="center"><a (click)="viewLog(item)"
                                            *ngIf="itemCourse.idSvSchoolYear == currYear.id" pTooltip="Chấm điểm">
                                            <i class="bx bx-cog" style="font-size: 20px;"></i>
                                        </a>
                                    </td>
                                    <td align="center">

                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pane" *ngIf="tabIndex == 3">
                    <div class="col-lg-12">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="width: 15%">STT</th>
                                    <th>Tài khoản</th>
                                    <th>Họ & tên</th>
                                    <th>{{'Ngày sinh' | translate}}</th>
                                    <th>{{'Lớp' | translate}}</th>
                                    <th>{{'Trạng thái' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dsCourseUser; index as i"
                                    [style.text-decoration:line-through]="!item.isActive">
                                    <td>
                                        <span *ngIf="!isEditOrderNo">
                                            {{item.orderNo}}
                                        </span>
                                        <span *ngIf="isEditOrderNo">
                                            <input type="number" class="sv-form-item__input sv-text-field"
                                                [(ngModel)]="item.orderNo">
                                        </span>
                                    </td>
                                    <td>{{item.userName}}</td>
                                    <td>{{item.displayName}}</td>
                                    <td>{{item.dateOfBirth | date:'dd/MM/yyyy'}}</td>
                                    <td>{{item.titleOrganization}}</td>
                                    <td>
                                        <p-checkbox [(ngModel)]="item.isActive" [binary]="true"
                                            (onChange)="onChangeCourseUserStatus(item)" [disabled]="!isEditOrderNo">
                                        </p-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pane" *ngIf="tabIndex == 4">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2" style="width: 80px">STT</th>
                                <th rowspan="2">Tiêu đề</th>
                                <th colspan="2">Thời gian</th>
                                <th colspan="2">Thao tác</th>
                            </tr>
                            <tr>
                                <th style="width: 10%;">Bắt đầu</th>
                                <th style="width: 10%;">T/G học</th>
                                <th style="width: 5%;">Sửa</th>
                                <th style="width: 5%;">Xóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dsLiveItem; index as i"
                                [style.text-decoration-line]="item.statusLearning == 1 ? 'line-through': ''">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td>{{item.title}}</td>
                                <td>{{item.startTime | date:'dd/MM/yyyy HH:mm' }}</td>
                                <td>{{item.duration}}</td>
                                <td align="center">
                                    <a (click)="editItemLive(item)" pTooltip="Sửa"
                                        *ngIf="item.statusLearning == 3 && itemCourse.idSvSchoolYear == currYear.id"><i
                                            class="bx bx-pencil"></i></a>
                                </td>
                                <td align="center">
                                    <a (click)="deleteItemLive(item)" pTooltip="Xóa"
                                        *ngIf="itemCourse.idSvSchoolYear == currYear.id"><i style="color: #d9534f;"
                                            class="bx bx-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-course-item-form #pEdit (closePopup)="onPopupClosed($event)"></app-course-item-form>
<app-course-item-view #pView></app-course-item-view>
<app-course-item-user #pImportUser (closePopup)="onPopupClosed($event)"></app-course-item-user>
<app-course-item-live #pEditLive (closePopup)="onPopupClosed($event)"></app-course-item-live>
