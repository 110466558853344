import { StudentIndexComponent } from './pages/student/student-index/student-index.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { CourseDetailComponent } from './pages/courses/course-detail/course-detail.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { ErrorComponent } from './pages/error/error.component';
import { MlearningUniComponent } from './pages/mlearning-uni/mlearning-uni.component';
import { ProfileAuthenticationComponent } from './pages/profile-authentication/profile-authentication.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { StorageComponent } from './pages/storage/storage.component';
import { CourseItemUserLearningLogComponent } from './pages/teacher/course-item/course-item-user-learning-log/course-item-user-learning-log.component';
import { CourseItemUserLogComponent } from './pages/teacher/course-item/course-item-user-log/course-item-user-log.component';
import { CourseItemComponent } from './pages/teacher/course-item/course-item.component';
import { CourseTestQuestionComponent } from './pages/teacher/course-test-question/course-test-question.component';
import { InteractiveVideoTestQuestionComponent } from './pages/teacher/interactive-video/interactive-video-test-question/interactive-video-test-question.component';
import { InteractiveVideoComponent } from './pages/teacher/interactive-video/interactive-video.component';
import { TeacherCourseFormComponent } from './pages/teacher/teacher-course-form/teacher-course-form.component';

import { TeacherIndexComponent } from './pages/teacher/teacher-index/teacher-index.component';
import { StudentTaskUnfinishedComponent } from './pages/student/student-task-unfinished/student-task-unfinished.component';
import { LearningSpaceComponent } from './pages/learning-space/learning-space.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { CourseCategoriesComponent } from './pages/courses/course-categories/course-categories.component';
import { StudentCourseResultComponent } from './pages/student/student-course-result/student-course-result.component';
import { DocumentComponent } from './pages/document/document.component';
import { AdminRedirectComponent } from './pages/admin-redirect/admin-redirect.component';

const routes: Routes = [

    // Here add new pages component
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            { path: '', component: MlearningUniComponent, data: { title: 'Home' } },
            { path: 'signin', component: ProfileAuthenticationComponent, data: { title: 'Signin' } },
            { path: 'profile', component: ProfileComponent, data: { title: 'Profile' } },
            { path: 'courses', component: CoursesComponent, data: { title: 'Courses' } },
            { path: 'course-categories', component: CourseCategoriesComponent, data: { title: 'Course Categories' } },
            { path: 'course-detail/:id', component: CourseDetailComponent, data: { title: 'Course Detail' } },
            { path: 'news', component: NewsComponent, data: { title: 'News' } },
            { path: 'news-detail/:id', component: NewsDetailComponent, data: { title: 'News Detail' } },
            { path: 'document', component: DocumentComponent, data: { title: 'Document' } },
            {
                path: 'teacher',
                children: [
                    { path: '', component: TeacherIndexComponent, data: { title: 'Teacher' } },
                    {
                        path: 'course',
                        children: [
                            { path: 'form', component: TeacherCourseFormComponent },
                            { path: 'form/:id', component: TeacherCourseFormComponent },
                            {
                                path: 'item',
                                data: { title: 'Course Item' },
                                children: [
                                    { path: ':id', component: CourseItemComponent },
                                    {
                                        path: 'log/:idCourse/:idCourseItem',
                                        component: CourseItemUserLogComponent,
                                    },
                                    {
                                        path: 'learning-log/:idCourse/:idCourseItem',
                                        component: CourseItemUserLearningLogComponent
                                    }
                                ]
                            },
                            {
                                path: 'test/:idCourse/:idCourseItem',
                                component: CourseTestQuestionComponent,
                            },
                        ]
                    },
                    { path: 'mydrive', component: StorageComponent, data: { title: 'My Drive' } },
                    {
                        path: 'interactive-video',
                        data: { title: 'Interactive Video' },
                        children: [
                            { path: '', component: InteractiveVideoComponent },
                            { path: 'exam/:idInteractiveVideo/:idInteractiveVideoTest', component: InteractiveVideoTestQuestionComponent },
                        ]
                    },

                ]
            },
            {
                path: 'student',
                children: [
                    { path: '', component: StudentIndexComponent, data: { title: 'Student' } },
                    { path: 'mytask', component: StudentTaskUnfinishedComponent, data: { title: 'My Task' } },
                    { path: 'course-result/:id', component: StudentCourseResultComponent, data: { title: 'Course Result' } }
                ]
            },
            {
                path: 'admin',
                component: AdminRedirectComponent
            }
        ]
    },
    {
        path: 'learning-space',
        component: LearningLayoutComponent,
        data: { title: 'Learning Space' },
        children: [
            { path: ':id', component: LearningSpaceComponent }
        ]
    },
    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
