import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase } from '../../../../../lib/classes/base/second-page-edit-base';
import { environment } from '../../../../../../../../src/environments/environment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FsFileUserService } from '../../services/fsFileUser.service';

@Component({
    selector: 'app-fssystem-private-upload',
    templateUrl: './fssystem-private-upload.component.html',
    styleUrls: ['./fssystem-private-upload.component.scss']
})
export class FssystemPrivateUploadComponent extends SecondPageEditBase implements OnInit {

    progressValue = 0;
    apiUploadUrl = '';
    accept = environment.appMetadata.main.fileAccept;
    maxFileSize = 1000000000;
    physicalPath = '';
    uploadedFiles: any[] = [];
    formData = new FormData();
    cols = [];
    constructor(
        protected _fsFileUserService: FsFileUserService,
        protected _injector: Injector,
        private http: HttpClient,
    ) {
        super(_fsFileUserService, _injector);
    }

    ngOnInit() {
        this.apiUploadUrl = this._fsFileUserService.getUploadUrl();
        this.cols = [
            { field: 'name', header: this._translateService.instant('FsSystem.File.Title'), visible: true, width: '80%', },
            { field: 'size', header: this._translateService.instant('FsSystem.TotalSize'), visible: true, width: 'auto', },
        ];
    }

    onShowPopup(data: any) {
        this.physicalPath = data.physicalPath;
        this.progressValue = 0;
        this.uploadedFiles = [];
        this.formData = new FormData();
    }

    dropped(files: NgxFileDropEntry[]) {
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    console.log(droppedFile.relativePath, file);
                    this.uploadedFiles.push({ name: droppedFile.relativePath, size: file.size });
                    this.formData.append(droppedFile.relativePath, file)
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    async uploadFolder() {
        this.formData.append('physicalPath', this.physicalPath);

        const uploadReq = new HttpRequest('POST', this.apiUploadUrl, this.formData, {
            reportProgress: true,
        });
        this.submitting = true;
        await this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                this.progressValue = Math.round(100 * event.loaded / event.total);
            }
            else if (event.type === HttpEventType.Response) {

                if (event.body['success']) {
                    this._notifierService.showSuccess(this._translateService.instant('FsSystem.message.uploadOk'));
                    this.closePopupMethod(true);
                } else {
                    this._notifierService.showWarning(event.body['error']);
                }
            }
            this.submitting = false;
        });
    }

}

