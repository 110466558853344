<p-dialog #dialog header="{{ title }}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [style]="{'min-width': '1200px'}" [styleClass]="'sv-modal force-full-width'"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" row closeIcon="ci ci-close" maximizeIcon="ci ci-maximize"
    minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div id="fileViewContent" style="display:none;">
        </div>
        <div class="row" *ngIf="itemDetail.idCourseItemType > 10 && itemDetail.idCourseItemType < 20">
            <div class="col-lg-12 col-md-12">
                <div class="sv-form-item__label">
                    <h4>{{'Nội dung' | translate}}</h4>
                </div>
                <p [innerHTML]="itemDetail.contents"></p>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="itemDetail.idCourseItemType == 11">
                <div class="video embed-responsive" style="width: 800px; height: 450px; ">
                    <video #video width="100%" height="100%" src="" controls></video>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" style="width: 100%; min-height: 800px;"
                *ngIf="itemDetail.idCourseItemType == 12">
                <div id="file-viewer"></div>
            </div>

            <div class="ui-g-12 ui-lg-12" style="padding-bottom:25px;" *ngIf="itemDetail.idCourseItemType == 13">
                <app-interactive-video-player #player></app-interactive-video-player>
            </div>

            <div class="col-lg-12 col-md-12" style="padding-bottom:25px;" *ngIf="itemDetail.idCourseItemType >= 14">
                <iframe style="min-width: 1020px; min-height: 580px;" frameBorder="0"
                    [src]="itemDetail.fileViewUrl | safe: 'resourceUrl'"></iframe>
            </div>

        </div>
        <div class="row" [hidden]="itemDetail.idCourseItemType != 22">
            <div class="video embed-responsive">
                <a href="{{itemDetail.fileViewUrl}}" target="_blank">
                    {{itemDetail.fileViewUrl | fileName}}</a>
            </div>
        </div>
        <div class="row" *ngIf="itemDetail.idCourseItemType !=1 && itemDetail.dsAttactment">
            <div class="sv-form-item">
                <div class="sv-form-item__label">
                    <h4>{{'Tài liệu tham khảo' | translate}}</h4>
                </div>

                <div class="col-md-12" *ngFor="let itemAt of itemDetail.dsAttactment;  index as i">
                    <a href="{{itemAt}}" target="_blank">
                        {{i + 1}}.&nbsp; {{itemAt | fileName}}</a>
                </div>

            </div>
        </div>

        <div class="row" *ngIf="itemDetail.idCourseItemType == 21">
            <div class="col-md-12">
                <div class="col-md-12 mb-2" *ngFor="let item of dataSource; index as i">
                    <div class="row">
                        <div class="col">
                            <h4>Câu {{i + 1}}: ({{item.codeTestQuestionType}}) -
                                ({{item.mark}} điểm)
                            </h4>
                        </div>
                        <div class="col-auto">
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="fw-bold" [innerHTML]="item.questionText"></p>
                                <div class="row" *ngIf="item.questionFile">
                                    <audio controls>
                                        <source src="{{item.questionFile}}" type="audio/mpeg">
                                    </audio>
                                </div>
                            </div>
                        </div>
                        <!-- SC -->
                        <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 1">
                            <div class="row" *ngFor="let ans of item.dsAnswers">
                                <div class="col-md-1 pd-tb0">
                                    <p-radioButton name="{{item.id}}" value="{{ans.id}}" disabled
                                        [(ngModel)]="+item.correctAnswer">
                                    </p-radioButton>
                                </div>
                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                            </div>
                        </div>
                        <!-- MC -->
                        <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 2">
                            <div class="row" *ngFor="let ans of item.dsAnswers">
                                <div class="col-md-1 pd-tb0">
                                    <p-checkbox disabled name="{{ans.id}}" binary="true" [(ngModel)]="ans.isCorrect">
                                    </p-checkbox>
                                </div>
                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                            </div>
                        </div>
                        <!-- SQ -->
                        <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 6">
                            <div class="row" *ngFor="let ans of item.dsAnswers; index as i">
                                <div class="col-md-1 pd-tb0">
                                    {{i + 1}}.
                                </div>
                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                            </div>
                        </div>
                        <!-- MA -->
                        <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 7">
                            <div class="row pd-tb0" *ngFor="let ans of item.dsAnswers; index as i">
                                <div class="col-md-2 pd-tb0">
                                    {{i + 1}}.
                                </div>
                                <div class="col-md-5 pd-tb0" [innerHTML]="ans.answerText"></div>
                                <div class="col-md-5 pd-tb0" [innerHTML]="ans.matching"></div>
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="item.idTestQuestionType == 5">
                            <div class="panel main-section">
                                <div class="course-item text-dark"
                                    *ngFor="let subItem of item.dsSubQuestions; index as j">

                                    <div class="row">
                                        <div class="col">
                                            <h5>Câu {{i + 1}}.{{j+1}}: ({{subItem.maTestQuestionType}})
                                                -
                                                ({{subItem.mark}} điểm)</h5>
                                        </div>
                                        <div class="col-auto">
                                            <div class="button-tool">
                                                <a (click)="editSubquestion(subItem)" pTooltip="Sửa"><i
                                                        class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                                                <a (click)="deleteItem(subItem)" pTooltip="Xóa"><i class="fa fa-trash-o"
                                                        aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="row mb-2">
                                            <div class="col-md-12">
                                                <p class="fw-bold" [innerHTML]="subItem.questionText">
                                                </p>
                                            </div>
                                        </div>
                                        <!-- SC -->
                                        <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 1">
                                            <div class="row pd-tb0 " *ngFor="let ans of subItem.dsAnswers">
                                                <div class="col-md-1 pd-tb0">
                                                    <p-radioButton name="{{subItem.id}}" value="{{ans.id}}" disabled
                                                        [(ngModel)]="+subItem.correctAnswer">
                                                    </p-radioButton>
                                                </div>
                                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- MC -->
                                        <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 2">
                                            <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers">
                                                <div class="col-md-1 pd-tb0">
                                                    <p-checkbox disabled name="{{ans.id}}" binary="true"
                                                        [(ngModel)]="ans.isCorrect"></p-checkbox>
                                                </div>
                                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 6">
                                            <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers; index as i">
                                                <div class="col-md-1 pd-tb0">
                                                    {{i + 1}}.
                                                </div>
                                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2 ms-2" *ngIf="subItem.idTestQuestionType == 7">
                                            <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers">
                                                <div class="col-md-6 pd-tb0" [innerHTML]="ans.answerText">
                                                </div>
                                                <div class="col-md-6 pd-tb0" [innerHTML]="ans.matching">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>