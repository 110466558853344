<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{itemDetail.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3 pt-3">
                <div class="col-md-12">
                    <div class="float-end">
                        <a class="default-btn me-3" *ngIf="itemDetail.approvalMark != true" (click)="goUpdate()">
                            <i class="bx bx-save"></i>
                            {{'Cập nhật điểm' | translate}}
                        </a>
                        <a class="default-btn me-3" *ngIf="itemDetail.approvalMark != true" (click)="goApproval()">
                            <i class="bx bx-chevrons-up"></i>
                            {{'Công bố điểm' | translate}}
                        </a>
                        <a class="default-btn me-3" *ngIf="itemDetail.approvalMark == true" (click)="goUnApproval()">
                            <i class="bx bx-chevrons-down"></i>
                            {{'Hủy công bố điểm' | translate}}
                        </a>
                        <a class="second-btn " (click)="goBack()">
                            <i class="bx bx-arrow-back"></i>
                            {{'Trở về' | translate}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="tabs-container">
                <div class="pane">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 5%" rowspan="2">STT</th>
                                <th style="width: 5%" rowspan="2">Lớp học</th>
                                <th style="width: 10%" rowspan="2">Tài khoản</th>
                                <th style="width: 20%" rowspan="2">Họ & tên</th>
                                <th style="width: 60%" colspan="3">Bài nộp</th>
                            </tr>
                            <tr>
                                <th style="width: 40%">Thời gian nộp</th>
                                <th style="width: 10%">Điểm</th>
                                <!-- <th style="width: 10%">Chọn</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of dataSource; index as i">
                                <tr>
                                    <td>{{i + 1}}</td>
                                    <td>{{item.tenDmLopHoc}}</td>
                                    <td>{{item.userName}}</td>
                                    <td>{{item.displayName}}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr *ngFor="let logItem of item.dsCourseUserLog; index as j">
                                    <td colspan="4"></td>
                                    <td [style.color]="logItem.timeIn > itemDetail.endTime ? 'red': ''">
                                        <div class="col-md-12">
                                            <span class="row" style="font-weight: bold;">
                                                {{j + 1}}.
                                                {{logItem.timeIn | date:'dd/MM/yyyy HH:mm' }}</span>
                                        </div>
                                        <div class="col-md-12" *ngIf="logItem.idCourseItemType == 22">
                                            <p [innerHTML]="logItem.answerText"></p>
                                            <div class="col-md-12">
                                                <ul>
                                                    <li *ngFor="let attItem of logItem.dsAttactment">
                                                        <a href="{{attItem.fileViewUrl}}" target="_blank">
                                                            <i class="fa fa-file-archive-o"></i>
                                                            &nbsp;
                                                            {{attItem.title}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="logItem.idCourseItemType == 21">
                                            <ul>
                                                <li>
                                                    <a (click)="viewExamDetail(logItem)">
                                                        <i class="fa fa-file-archive-o"></i> Xem chi
                                                        tiết bài kiểm tra</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-12">
                                            <h5>Nhận xét của giáo viên</h5>
                                            <p-editor [(ngModel)]="logItem.teacherComment" [style]="{'height':'200px'}"
                                                [disabled]="itemDetail.approvalMark == true">
                                                <ng-template pTemplate="header">
                                                </ng-template>
                                            </p-editor>

                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" [disabled]="itemDetail.approvalMark == true"
                                            [(ngModel)]="logItem.finalMark">
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-course-item-user-exam-log #pDetail (closePopup)="onPopupClosed($event)">
</app-course-item-user-exam-log>
