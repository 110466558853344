<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Bài giảng tương tác' | translate}}</li>
            </ul>
            <h2>{{'Bài giảng tương tác' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-70 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="odemy-grid-sorting row align-items-center">
                <div class="col-lg-9 col-md-9 result-count">
                    <div class="select-box">
                        <label>{{'Tìm kiếm' | translate}}</label> &nbsp;
                        <p-dropdown [options]="dsSvSubject" [(ngModel)]="idSvSubject" filter="true">
                        </p-dropdown> &nbsp;
                        <p-dropdown [options]="arrStatus" [(ngModel)]="status" filter="true">
                        </p-dropdown>
                        &nbsp;
                        <a class="default-btn float-end" (click)="onSearch()">
                            <i class="bx bx-search"></i>
                            {{'Tìm kiếm' | translate}}
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 ordering">
                    <a class="default-btn float-end" (click)="onAdd()">
                        <i class="bx bx-plus-circle"></i>
                        {{'Tạo mới' | translate}}
                    </a>
                </div>
            </div>
            <div class="tabs-container row">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 80px;">{{'STT' | translate}}</th>
                                <th style="width: 80%;">{{'Tiêu đề / trắc nghiệm' | translate}}</th>
                                <th colspan="4">{{'Thao tác' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of dataSource; index as i">
                                <tr>
                                    <td>{{i+1}}</td>
                                    <td>
                                        {{item.title}} ({{item.videoTime * 1000 | date:'mm:ss'}})
                                    </td>
                                    <td>
                                        <a (click)="onView(item.id)" pTooltip="Xem thử"><i class="bx bx-search"></i></a>
                                    </td>
                                    <td>
                                        <a (click)="onAddTest(item)" pTooltip="Thêm bài trắc nghiệm"><i
                                                class="bx bx-list-plus"></i></a>
                                    </td>
                                    <td>
                                        <a (click)="onEdit(item.id)" pTooltip="Sửa"><i class="bx bx-edit"></i></a>
                                    </td>
                                    <td>
                                        <a (click)="onDelete(item)" pTooltip="Xóa"><i class="bx bx-trash-alt"
                                                style="color: #d9534f;"></i></a>
                                    </td>
                                </tr>
                                <tr *ngFor="let test of item.dsInteractiveVideoTest; index as j">
                                    <td>
                                        {{i + 1}}. {{j+1}}
                                    </td>
                                    <td>
                                        ({{test.startTime * 1000 | date:'mm:ss'}})
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        <a (click)="goQuestionManager(test)" pTooltip="Soạn bài trắc nghiệm"><i
                                                class="bx bx-outline"></i></a>
                                    </td>
                                    <td>
                                        <a (click)="onEditTest(test)" pTooltip="Sửa thông tin bài trắc nghiệm"><i
                                                class="bx bx-edit"></i></a>
                                    </td>
                                    <td>
                                        <a (click)="onDeleteTest(test)" pTooltip="Xóa bài trắc nghiệm"><i
                                                class="bx bx-trash-alt" style="color: #d9534f;"></i></a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-interactive-video-form #pEdit (closePopup)="onPopupClosed($event)"></app-interactive-video-form>
<app-interactive-video-form-test #pEditTest (closePopup)="onPopupClosed($event)"></app-interactive-video-form-test>
<app-interactive-video-view #pView></app-interactive-video-view>