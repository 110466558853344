import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseItemService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitem`);
    }
    searchTree(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/SearchTree/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    getsItemTypeIsGroup(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsItemTypeIsGroup/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    deleteItem(idCourse: number, id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteItem/${idCourse}/${id}`;
        return this._http.delete<ResponseResult>(url).toPromise();
    }

    AddEditItem(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/AddEditItem`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getStatisticByIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetStatisticByIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    getsTaskByIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsTaskByIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    UpdateTrangThai(idCourse: number, id: number, trangThai: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateTrangThai/${idCourse}/${id}/${trangThai}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    searchTreeForStudent(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/SearchTreeForStudent/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    searchTreeWithUserLog(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/SearchTreeWithUserLog/${idCourse}`;
        return this.defaultGet(apiUrl);
    }
    getRecentItem(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetRecentItem/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

}
