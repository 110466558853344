import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RootCategory } from '../../../config/enums';
import { CategoriesService } from '../../../services/categories.service';
import { NewsService } from '../../../services/news.service';

@Component({
    selector: 'app-new-widget-area',
    templateUrl: './new-widget-area.component.html',
    styleUrls: ['./new-widget-area.component.scss']
})
export class NewWidgetAreaComponent implements OnInit {
    dsCategory = [];
    dataTop = [];
    query = "";
    idCategory = 0;
    constructor(
        protected _newsService: NewsService,
        private _router: Router,
        private _categoriesService: CategoriesService,
        private _route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            this.idCategory = params['idCategory'];
        });
        this.getCategories();
        this.getTopNews();
    }

    getCategories() {
        if (this.dsCategory.length == 0) {
            this._categoriesService.getTreeTableByIdParent(RootCategory.Dm_TinTuc, true, false).then(rs => {
                if (rs.success) {
                    this.dsCategory = rs.data;
                }
            });
        }
    }

    getTopNews() {
        this._newsService.getsTop(5, 2).then(rs => {
            if (rs.success) {
                this.dataTop = rs.data;
            }
        })
    }

    onSearch() {
        if (this.idCategory == 0) {
            this._router.navigate(['/news'], { queryParams: { query: this.query } })
        } else {
            this._router.navigate(['/news'], { queryParams: { idCategory: this.idCategory, query: this.query } })
        }

    }

    goCategory(idCategory: number) {
        this.idCategory = idCategory;
        this._router.navigate(['/news'], { queryParams: { idCategory: idCategory } })
    }
}
