<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Blog</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>
            <h2>Blog Details</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{itemDetail.imageUrl}}" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Chuyên mục</span>
                                    <a routerLink="/news"
                                        [queryParams]="{idCategory: itemCategoty.id}">{{itemCategoty.title}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Lượt xem</span>
                                    <a>{{itemDetail.viewCount}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Ngày phát hành</span>
                                    <a>{{itemDetail.publishedDate | date : 'dd/MM/yyyy'}}</a>
                                </li>
                            </ul>
                        </div>
                        <h3>{{itemDetail.title}}</h3>
                        <p [innerHTML]="itemDetail.bodyHtml"></p>
                    </div>

                    <div class="odemy-post-navigation" *ngIf="dataPrevNext.length == 2">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a routerLink="/news-detail/{{dataPrevNext[0].id}}">
                                    <span class="image-prev">
                                        <img src="{{dataPrevNext[0].imageUrl}}" alt="image">
                                        <span class="post-nav-title">Tin trước</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">{{dataPrevNext[0].title}}</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">{{dataPrevNext[0].publishedDate | date :
                                                'dd/MM/yyyy'}}</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a routerLink="/news-detail/{{dataPrevNext[1].id}}">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title">{{dataPrevNext[1].title}}</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">{{dataPrevNext[1].publishedDate | date :
                                                'dd/MM/yyyy'}}</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img src="{{dataPrevNext[1].imageUrl}}" alt="image">
                                        <span class="post-nav-title">Tin tiếp theo</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <app-new-widget-area></app-new-widget-area>
            </div>
        </div>
    </div>
</div>
