import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FssystemModalComponent, SecondPageEditBase } from 'sv-shared';
import { CourseItemModel } from '../../../../models/courseitem';
import { CourseItemAttachmentService } from '../../../../services/course-item-attachment.service';
import { CourseItemTypeService } from '../../../../services/course-item-type.service';
import { CourseItemService } from '../../../../services/course-item.service';
import { InteractiveVideoService } from '../../../../services/interactive-video.service';

@Component({
    selector: 'app-course-item-form',
    templateUrl: './course-item-form.component.html',
    styleUrls: ['./course-item-form.component.scss']
})
export class CourseItemFormComponent extends SecondPageEditBase implements OnInit {
    title = 'Thêm mới nội dung lớp môn học';
    arrCourseItemType = [];
    arrGroup = [];
    itemDetail: CourseItemModel = new CourseItemModel();
    dsAttactment = [];
    @ViewChild('pFileEpl') pFileEpl: FssystemModalComponent;
    editor_modules = {
        imageResize: true
    };
    dsInteractiveVideo = [];
    fileModalTypeId = 1;
    constructor(
        private _courseItemService: CourseItemService,
        private _courseItemTypeService: CourseItemTypeService,
        private _courseItemAttachmentService: CourseItemAttachmentService,
        protected _injector: Injector,
        private _interactiveVideoService: InteractiveVideoService,
    ) {
        super(_courseItemService, _injector);

        this.formGroup = new FormGroup({
            idCourseItemType: new FormControl('', Validators.required),
            idCourseItemParent: new FormControl(''),
            title: new FormControl('', Validators.required),
            fileViewUrl: new FormControl(''),
            orderNo: new FormControl(''),
            startTime: new FormControl(''),
            endTime: new FormControl(''),
            contents: new FormControl(''),
            haveExtraTime: new FormControl(''),
            status: new FormControl(''),
            totalTime: new FormControl(''),
            extraTime: new FormControl(''),
            idItemData: new FormControl(''),
        });
    }

    ngOnInit() {
        if (this.arrCourseItemType.length == 0) {
            this.getsCourseItemType();
        }
    }

    getsInteractiveVideo(idSvSubject: number) {
        var model = {
            idSvSubject: idSvSubject,
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 99999,
            orderCol: 'Id',
            isDesc: true,
            getListTest: false,
        }
        this.dsInteractiveVideo = [];
        this._interactiveVideoService.getsMyVideo(model)
            .then(rs => {
                rs.data.map(item =>
                    this.dsInteractiveVideo.push({ label: `${item.title}`, value: item.id })
                );
            }, error => {
                this._notifierService.showHttpUnknowError();
            });
    }

    async onShowPopup(data: any) {
        await this.resetForm();
        await this.getsGroupItem(data.idCourse);
        if (this.dsInteractiveVideo.length == 0) {
            this.getsInteractiveVideo(data.idSvSubject);
        }
        // console.log(data);
        if (data.id > 0) {
            this._courseItemService.getDetail(data.id).then(rs => {
                if (rs.success) {
                    this.title = `Sửa nội dung: ${data.title}`
                    this.itemDetail = rs.data;
                    if (this.itemDetail.startTime) {
                        this.itemDetail.startTime = new Date(this.itemDetail.startTime);
                    }
                    if (this.itemDetail.endTime) {
                        this.itemDetail.endTime = new Date(this.itemDetail.endTime);
                    }
                    if (this.itemDetail.extraTime) {
                        this.itemDetail.extraTime = new Date(this.itemDetail.extraTime);
                    }
                    this.dsAttactment = data.dsAttactment;
                }
            })


        } else {
            this.itemDetail = data;
            this.title = 'Thêm mới nội dung lớp học phần';
        }
    }

    async getsCourseItemType() {
        this.arrCourseItemType = [];
        await this._courseItemTypeService.getAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrCourseItemType, rs.data, 'title');
            }
        })
    }

    async getsGroupItem(idCourse: number) {
        this.arrGroup = [];
        await this._courseItemService
            .getsItemTypeIsGroup(idCourse)
            .then(rs => {
                if (rs.success) {
                    this.convertDataToOptions(this.arrGroup, rs.data, 'title');
                }
            });
    }

    onBeforeSave(): boolean | void {
        if (this.itemDetail.idCourseItemType == 1) {
            this.itemDetail.idCourseItemParent = 0;
        }
        if (this.itemDetail.idCourseItemType == 21 && this.itemDetail.id == 0) {
            this.itemDetail.status = 2;
        }
        return true;
    }

    onInsert() {
        this._courseItemService.AddEditItem(this.itemDetail)
            .then(response => {
                if (response.success) {
                    this.itemDetail.id = response.data;
                    this.updateAttactment(response.data);
                    if (this.continueAdding) {
                        this.resetForm();
                        this.itemDetail.id = 0;
                    } else {
                        this.closePopupMethod(true);
                    }
                    this._notifierService.showInsertDataSuccess();
                    this.onAfterSave();
                }
                else {
                    this._notifierService.showInsertDataFailed();
                }
                this.submitting = false;
            }, () => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._courseItemService.AddEditItem(this.itemDetail)
            .then(() => {
                this.closePopupMethod(true);
                this._notifierService.showUpdateDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
        this.updateAttactment(this.itemDetail.id);
    }

    updateAttactment(idCourseItem: number) {
        if (idCourseItem > 0 && this.dsAttactment.length > 0) {
            this._courseItemAttachmentService.updateForIdCourseItem(this.itemDetail.idCourse, idCourseItem, this.dsAttactment).then(rs => {
                if (rs.success) {

                }
            })
        }
    }
    getAttactment(idCourseItem: number) {
        if (idCourseItem > 0) {
            this._courseItemAttachmentService.getsByIdCourseItem(idCourseItem).then(rs => {
                if (rs.success) {
                    this.dsAttactment = rs.data;
                }
            })
        }
    }

    showFileExplorer(type: number) {
        this.fileModalTypeId = type;
        this.pFileEpl.onShowPopup(null);
    }

    onFileExplorerClosed(data) {
        if (this.fileModalTypeId == 1) {
            this.itemDetail.fileViewUrl = data;
        } else {
            this.dsAttactment.push(data);
        }

    }

    deleteAttactment(item: string) {
        console.log(item);
        console.log(this.dsAttactment);
        var index = this.dsAttactment.indexOf(item);
        console.log(index);
        if (index >= 0) {
            this.dsAttactment.splice(index, 1);
        }
    }

    deleteItem(item: any) {
        this.submitting = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._courseItemService.deleteItem(item.idCourse, item.id)
                .then(response => {
                    this._notifierService.showDeleteDataSuccess();
                    this.submitting = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.submitting = false;
                });
        });
    }
}
