<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Thông tin cá nhân' | translate}}</li>
            </ul>
            <h2>{{'Thông tin cá nhân' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <ngx-tabset>
                <ngx-tab tabTitle="{{'Thông tin cá nhân' | translate}}">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="image">
                                <img src="{{getAvatarUrl(itemDetail.avatar)}}" alt="image">
                            </div>
                            <div class="row pt-2">
                                <a class="default-btn" id="upload_link" (click)="hiddenfileinput.click()">
                                    <i class="fa fa-file-image-o"></i>
                                    {{'Đổi hình đại diện' | translate}}
                                </a>
                                <input style="display: none" type="file" accept="image/*"
                                    (change)="onFileSelect($event)" #hiddenfileinput>
                            </div>

                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="content">
                                <h3>{{itemDetail.displayName}}</h3>
                                <!-- <span class="sub-title">Agile Project Expert</span> -->
                                <p [innerHTML]="itemDetail.introduction"></p>
                                <!-- <ul class="info">
                                    <li><span>Phone Number:</span> <a href="tel:+44254588689">(+44) -2545 - 88689</a></li>
                                    <li><span>Email:</span> <a href="mailto:hello@sarahtaylor.com">hello@sarahtaylor.com</a>
                                    </li>
                                </ul>
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'Đổi thông tin cá nhân' | translate}}">
                    <div class="container">
                        <form #formElement [formGroup]="formGroup">
                            <div class="ui-g row">
                                <validation-summary #validationSummary [formElement]="formElement"
                                    [formGroup]="formGroup">
                                </validation-summary>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>{{'Tài khoản' | translate}}</label>
                                        <input type="text" class="form-control" [(ngModel)]="itemDetail.userName"
                                            formControlName="userName" disabled>
                                    </div>
                                    <div class="form-group">
                                        <label>{{'Email' | translate}}</label>
                                        <input type="text" class="form-control" [(ngModel)]="itemDetail.email"
                                            formControlName="email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>{{'Tên hiển thị' | translate}}</label>
                                        <input type="text" class="form-control" [(ngModel)]="itemDetail.displayName"
                                            formControlName="displayName">
                                    </div>
                                    <div class="form-group">
                                        <label>{{'Số điện thoại' | translate}}</label>
                                        <input type="text" class="form-control" [(ngModel)]="itemDetail.phoneNumber"
                                            formControlName="phoneNumber">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <label>{{'Giới thiệu' | translate}}</label>
                                    <ck-editor name="editor1" style="width: 100%" [(ngModel)]="itemDetail.introduction"
                                        formControlName="introduction">
                                    </ck-editor>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-12">
                                    <a class="default-btn float-end" (click)="doUpdateInfo()">
                                        <i class="fa fa-file-image-o"></i>
                                        {{'Lưu thông tin' | translate}}
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'Đổi mật khẩu'}}">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>{{'Mật khẩu hiện tại' | translate}}</label>
                                    <input type="password" class="form-control" [(ngModel)]="itemDetail.oldPassword">
                                </div>
                                <div class="form-group">
                                    <label>{{'Mật khẩu mới' | translate}}</label>
                                    <input type="password" class="form-control" [(ngModel)]="itemDetail.newPassword">
                                </div>
                                <div class="form-group">
                                    <label>{{'Nhập lại mật khẩu mới' | translate}}</label>
                                    <input type="password" class="form-control"
                                        [(ngModel)]="itemDetail.confirmPassword">
                                </div>
                                <div class="row pt-2">
                                    <div class="col-md-12">
                                        <a class="default-btn float-end" (click)="doChangePass()">
                                            <i class="fa fa-file-image-o"></i>
                                            {{'Đổi mật khẩu' | translate}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>
