import { BaseModel } from './base';

export class TestQuestion extends BaseModel {
    id: number;
    ma: string;
    idCategory: number;
    idQuestionParent: number;
    idTestQuestionType: number;
    questionText: string;
    mark: number;
    answerText: string;
    idTestExamUser: number;
    idTestExamUserLog: number;
    dsSubQuestions = [];
    dsAnswers = [];

}