import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { SecondPageEditBase, SvCorePublicService, SvUser, UserService } from 'sv-shared';

@Component({
    selector: 'app-profile-authentication',
    templateUrl: './profile-authentication.component.html',
    styleUrls: ['./profile-authentication.component.scss']
})
export class ProfileAuthenticationComponent extends SecondPageEditBase implements OnInit {
    model: ModelDangNhap = new ModelDangNhap();
    showLogin = true;
    returnUrl: string;
    currUser: SvUser;
    constructor(
        protected _userService: UserService,
        protected _injector: Injector,
        private _router: ActivatedRoute,
        private _oauthService: OAuthService,
        protected _svCorePublicService: SvCorePublicService,
    ) {
        super(_userService, _injector);
    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });

        this.returnUrl = this._router.queryParams['returnUrl'] || '/';
        this._oauthService.requireHttps = false;
        if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
            top.location.href = this.returnUrl;

        }
    }

    doLogin() {
        this.submitting = true;

        if (this.formGroup.invalid) {
            this._notifierService.showError(this._translateService.instant('Bạn nhập thiếu thông tin'));
            return;
        }
        if (this.model.username !== undefined && this.model.password !== undefined) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._oauthService.setupAutomaticSilentRefresh();
                        this._notifierService.showSuccess(this._translateService.instant('Đăng nhập thành công'));
                        var svUser = this._authenticationService.getCurrentUser();
                        if (svUser.typeId == 1) {
                            this.returnUrl = '/teacher';
                            setTimeout(() => {
                                this.submitting = false;
                                top.location.href = this.returnUrl;
                            }, 500);
                        } else {
                            this.returnUrl = '/student';
                            setTimeout(() => {
                                this.submitting = false;
                                top.location.href = this.returnUrl;
                            }, 500);
                        }
                    },
                        error => {

                            this._notifierService.showWarning(error.error.error_description)
                            setTimeout(() => {
                                this.submitting = false;

                            }, 500);
                        }
                    );
                }
            });

        } else {
            this._notifierService.showError('Bạn nhập thiếu thông tin');
        }
    }
    doResetPassword() {
        this.submitting = true;
        if (this.model.email !== undefined) {
            this._svCorePublicService.resetPassword(this.model).then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess('Mật khẩu mới đã được gửi vào email của bạn');
                    this.model = new ModelDangNhap();
                    // top.location.href = "/";
                } else {
                    this._notifierService.showWarning('Thông tin không hợp lệ');
                }
                this.submitting = false;
            }, error => {
                this._notifierService.showWarning(error.error.error);
                this.submitting = false;
            });
        }
        else {
            this._notifierService.showError('Bạn nhập thiếu thông tin');
        }
    }
}


class ModelDangNhap {
    username: string;
    password: string;
    remember: boolean;
    email: string;
}
