import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseItemTypeService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitemtype`);
    }

    getAll(trangThai: number = 1): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll/${trangThai}`;
        return this.defaultGet(apiUrl);
    }
}
