import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FssystemModalComponent, SecondPageEditBase, UserService } from 'sv-shared';
import { InteractiveVideoService } from '../../../../services/interactive-video.service';
import { SvSubjectService } from '../../../../services/sv-subject.service';

@Component({
    selector: 'app-interactive-video-form',
    templateUrl: './interactive-video-form.component.html',
    styleUrls: ['./interactive-video-form.component.scss']
})
export class InteractiveVideoFormComponent extends SecondPageEditBase implements OnInit {
    dsSubject = [];
    @ViewChild('pFileEpl') pFileEpl: FssystemModalComponent;
    @ViewChild('video') video: ElementRef;
    constructor(
        protected _interactivevideoService: InteractiveVideoService,
        protected _injector: Injector,
        protected _userService: UserService,
        protected _svSubjectService: SvSubjectService,
    ) {
        super(_interactivevideoService, _injector);

        this.formGroup = new FormGroup({
            title: new FormControl('', Validators.required),
            idSvSubject: new FormControl('', Validators.required),
            videoUrl: new FormControl('', Validators.required),
            videoTime: new FormControl('', Validators.required),
            status: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    getSubjects() {
        if (this.dsSubject.length == 0) {
            var model = {
                idOrganization: 0,
                keyword: '',
                status: 1,
                pageIndex: 1,
                pageSize: 99999,
                orderCol: 'Id',
                isDesc: true
            }
            this._svSubjectService.find(model)
                .then(rs => {
                    rs.data.map(item =>
                        this.dsSubject.push({ label: `${item.code} - ${item.title}`, value: item.id })
                    );
                }, error => {
                    this._notifierService.showHttpUnknowError();
                });
        }
    }

    async onShowPopup(id) {
        // this.resetForm();
        await this.getSubjects();
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (id > 0) {
            this._interactivevideoService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    onInsert() {
        this._interactivevideoService.addEditItem(this.itemDetail)
            .then(response => {
                this.itemDetail.id = response.data;
                if (this.continueAdding) {
                    this.resetForm();
                    this.itemDetail.id = 0;
                } else {
                    this.closePopupMethod(true);
                }
                this._notifierService.showInsertDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, () => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._interactivevideoService.addEditItem(this.itemDetail)
            .then(() => {
                this.closePopupMethod(true);
                this._notifierService.showUpdateDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    showFileExplorer() {
        this.pFileEpl.onShowPopup(null);
    }

    onFileExplorerClosed(data) {
        this.itemDetail.videoUrl = data;
        this.onChangeVideoUrl();
    }

    onChangeVideoUrl() {
        this.itemDetail.videoTime = parseInt(this.video.nativeElement.duration);
    }
}


