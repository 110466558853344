import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, UserService } from 'sv-shared';
import { CourseUserLog_TestDetailService } from '../../../../services/course-user-log-test-detail.service';

@Component({
    selector: 'app-course-item-user-exam-log',
    templateUrl: './course-item-user-exam-log.component.html',
    styleUrls: ['./course-item-user-exam-log.component.scss']
})
export class CourseItemUserExamLogComponent extends SecondPageEditBase implements OnInit {
    title = 'Chi tiết bài làm của học sinh';
    dataTest = [];
    totalQuestion = 0;
    cols = [];
    totalMark = 0;

    constructor(
        protected _injector: Injector,
        private _userService: UserService,
        private _courseUserLog_TestDetailService: CourseUserLog_TestDetailService,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'questionText', header: this._translateService.instant('Câu hỏi'), visible: true, width: 'auto', },
            { field: 'userMark', header: this._translateService.instant('Điểm'), visible: true, width: '100px', },

        ];
    }

    onShowPopup(data) {
        this.itemDetail = data;
        this._userService.getBasicUserInfo(data.userId).then(rs => {
            this.title += `: ${rs.displayName}`;
        });
        this._courseUserLog_TestDetailService.getsForTeacher(data.idCourse, data.id, data.idCourseItem).then(rs => {
            if (rs.success) {
                this.dataTest = rs.data;
                this.totalMark = rs.totalRecord;
            }
        })
    }

    saveAndClose() {
        this._courseUserLog_TestDetailService.updateTotalMark(this.itemDetail.idCourse, this.itemDetail.id, this.dataTest).then(rs => {
            if (rs.success) {
                this._notifierService.showUpdateDataSuccess();
                this.closePopupMethod(true);
            }
        })
    }

}

