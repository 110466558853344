import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { map, takeUntil } from 'rxjs/operators';
import { AuthenticationService, ComponentBase, NotifierService, SvUser, UserService } from 'sv-shared';
import { CourseItemModel } from '../../models/courseitem';
import { CourseItemService } from '../../services/course-item.service';
import { CourseTeacherService } from '../../services/course-teacher.service';
import { CourseUserService } from '../../services/course-user.service';
import { CourseService } from '../../services/course.service';
import { CountdownComponent } from 'ngx-countdown';
import * as $ from 'jquery'
import { environment } from '../../../environments/environment';
import { CourseUserLogService } from '../../services/course-user-log.service';
import { NgScormPlayerService, ScormResult } from 'ng-scorm-player';
import { CourseItemAttachmentService } from '../../services/course-item-attachment.service';
import { CourseUserLog } from '../../models/courseuserlog';
import { CourseUserLog_AttactmentModel } from '../../models/courselog-attactment';
import { CourseUserLog_TestDetailService } from '../../services/course-user-log-test-detail.service';
import { TestQuestion } from '../../models/testquestion';
import { TestQuestionType, TestQuestionTypeNew } from '../../config/enums';
declare const videojs: any;
declare var DocsAPI: any;

@Component({
    selector: 'app-learning-space',
    templateUrl: './learning-space.component.html',
    styleUrls: ['./learning-space.component.scss']
})
export class LearningSpaceComponent extends ComponentBase implements OnInit {
    isCollapse = false;
    isShow = false;

    idCourse = 0;
    currentUser: SvUser = new SvUser();
    courseDetail = { id: 0, code: '', title: '' };
    isLoading = false;
    tabId = 6;
    isTeacher = false;
    courseUserInfo = { id: 0, idCourse: 0, status: 2 };
    courseItems: TreeNode[] = [
        {
            'label': 'Nội dung khóa học',
            'data': '-1',
            'expandedIcon': 'fa-folder-open',
            'collapsedIcon': 'fa-folder',
            'children': [],
            'type': '1'
        }];
    courseItemDetail: CourseItemModel = new CourseItemModel();
    hlsPlayer: any;
    isUpdateLog = false;
    timerId: any = null;
    scormResult = new ScormResult();
    dsCourseItemAttactment = [];
    showCanDoTask = false;

    courseUserLogItem: CourseUserLog = new CourseUserLog(0, '');
    courseUserLogItemView: CourseUserLog = new CourseUserLog(0, '');
    apiUploadUrl = `${environment.apiDomain.svFsSystemEndpoint}/FsItemPublic/UploadCourseItemLog`;
    uploadedFiles = [];

    isDoingExam = false;
    dataTest = [];
    totalQuestion = 0;
    config = { leftTime: 0 };
    currentQuestion: TestQuestion = new TestQuestion();
    currentQuestionIndex = 0;
    @ViewChild('countdown') public countdown: CountdownComponent;
    testQuestionType = TestQuestionTypeNew;
    currentQuestionTextEditor: TestQuestion = new TestQuestion();
    itemDrag = {};
    indexDrag = -1;
    itemDrop = {};
    indexDrog = -1;

    //learning history
    courseUserInfoExt: any = {};
    dsResult: [];

    scormUrl = '';
    isViewScorm = false;

    itemLiveIscomming = { id: 0, title: '', startTime: null };

    constructor(
        private player: NgScormPlayerService,
        private _courseTeacherService: CourseTeacherService,
        protected _activatedRoute: ActivatedRoute,
        protected _authenticationService: AuthenticationService,
        protected _courseUserService: CourseUserService,
        protected _router: Router,
        protected _courseService: CourseService,
        protected _courseItemService: CourseItemService,
        private _courseUserLogService: CourseUserLogService,
        private _courseItemAttachmentService: CourseItemAttachmentService,
        //private _courseItemLiveService: CourseItemLiveService,
        private _notifierService: NotifierService,
        private _courseUserLog_TestDetailService: CourseUserLog_TestDetailService,
        private _userService: UserService,
    ) {
        super();
        this.currentUser = this._authenticationService.getCurrentUser();
    }

    ngOnInit() {
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(idCourse => {
                this.idCourse = idCourse;
                this.getDetail(idCourse);
            });
    }

    goTab(tabId: number) {
        this.tabId = tabId;
        this.stopVideo();
        if (tabId == 2) {
            this.viewLearningHistory();
        }
    }

    viewLearningHistory() {
        // this._courseUserService.getDetailInfo(this.idCourse, this.currentUser.userId).then(rs => {
        //     if (rs.success) {
        //         this.courseUserInfoExt = rs.data;
        //     }
        // });
        this._courseUserLogService.getsMyResult(this.idCourse).then(rs => {
            if (rs.success) {
                this.dsResult = rs.data;
            }
        })
    }

    async getDetail(idCourse: number) {
        this._courseService.getDetail(idCourse).then(rs => {
            if (rs.success) {
                this.courseDetail = rs.data;
            }
        }, err => {
            this.exitLearningSpace();
        });
        // Kiểm tra xem người dùng có phải là giáo viên phụ trách lớp không
        await this._courseService.checkIsTeacherOfCourse(this.idCourse).then(rs => {
            if (rs.success) {
                this.isTeacher = rs.data;
            }
        })
        if (!this.isTeacher) {
            // Kiểm tra xem đã đăng ký chưa
            await this._courseUserService.checkRegistered(this.idCourse).then(rs => {
                if (rs.success) {
                    if (rs.data != null) {
                        this.courseUserInfo = rs.data;
                        if (rs.data.status != 1) {
                            this.exitLearningSpace();
                        }
                    } else {
                        this._userService.checkUserHasRole().then(rs => {
                            if (rs.success) {
                                this.isTeacher = rs.data;
                                if (rs.data != true) {
                                    this.exitLearningSpace();
                                }
                            } else {
                                this.exitLearningSpace();
                            }
                        }, error => {
                            this.exitLearningSpace();
                        });
                        //this.exitLearningSpace();
                    }
                }
            });
        }
        this.getsCourseItem();
    }



    //#region CourseItem
    getsCourseItem() {
        if (this.isTeacher) {
            this._courseItemService.searchTree(this.idCourse).then(rs => {
                if (rs.success) {
                    this.courseItems = rs.data;
                    this.courseItems.forEach(node => {
                        this.expandRecursive(node, true);
                    });
                }
            })
        } else {
            this._courseItemService.searchTreeWithUserLog(this.idCourse).then(rs => {
                if (rs.success) {
                    this.courseItems = rs.data;
                    this.courseItems.forEach(node => {
                        this.expandRecursive(node, true);
                    });
                }
            })
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }
    //#endregion

    //#region viewCourseItem
    viewCourseItem(item: any) {
        if (item.data == undefined) {
            item.data = item.id;
        }
        console.log(item);
        this.stopVideo();
        clearInterval(this.timerId);
        this.dsCourseItemAttactment = [];

        this.dataTest = [];
        this.isDoingExam = false;
        this.currentQuestionIndex = 0;
        this.totalQuestion = 0;
        this.currentQuestion = new TestQuestion();

        this.tabId = item.idCourseItemType;
        this._courseItemService.getDetail(item.data).then(rs => {
            if (rs.success) {
                this.courseItemDetail = rs.data;
                if (item.idCourseItemType == 11) {
                    this.openVideo();
                }
                if (item.idCourseItemType == 14) {
                    this.openScorm();
                }
                if (item.idCourseItemType == 12) {
                    this.openDocument();
                }
                if (item.idCourseItemType > 10 && item.idCourseItemType < 20) {
                    this.getsCourseItemAttactment(item.data);
                }
                if (item.idCourseItemType == 21) {
                    this.getMyLatestCourseUserLog(false);
                    this.checkCanDoTask();
                }
                if (item.idCourseItemType == 22) {
                    this.getMyLatestCourseUserLog(true);
                    this.checkCanDoTask();
                }
            }
        })
    }

    async openVideo() {
        if (this.courseItemDetail.idCourseItemType == 11) {
            // this.video1.nativeElement.src = this.courseItemDetail.fileViewUrl;
            // this.video1.nativeElement.load();
            // this.video1.nativeElement.play();

            this.hlsPlayer = videojs(document.getElementById('sxmvideo'), {
                fluid: true,
            });
            this.hlsPlayer.on('pause', function () {
                sessionStorage.setItem('hlsPlayer', '0');
            });
            this.hlsPlayer.on('playing', function () {
                sessionStorage.setItem('hlsPlayer', '1');
            });
            const fileExtArr = this.courseItemDetail.fileViewUrl.split('.');
            const fileExt = fileExtArr[fileExtArr.length - 1];
            if (fileExt == 'mp4') {
                this.hlsPlayer.src({
                    src: `${this.courseItemDetail.fileViewUrl}`,
                });
                this.hlsPlayer.load();
                this.hlsPlayer.play();
            } else {

                videojs.Hls.xhr.beforeRequest = (options: any) => {
                    // options.uri = options.uri + `?u=${this.currentUser.userId}&key=${this.encryptKey(this.xToken)}`;
                    // return options;
                    var userId = this.currentUser.userId;
                    // if (options.uri.indexOf('.ts') >= 0) {
                    $.ajax({
                        url: `${environment.apiDomain.svFsSystemEndpoint}/FsToken/GetKey/` + `${(new Date()).getTime()}`,
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem("access_token")
                        },
                        success: function ariaDecorate(elem) {
                            options.headers = {
                                "Authorization": "Bearer " + localStorage.getItem("access_token")
                            }
                            options.uri += `?u=${userId}&key=` + function (elems) {
                                {
                                    var key = elems.data;
                                    var result = "";
                                    var ranStr = "123456780ABCDEFGHKLMNOPYTRQW";
                                    for (let i = 0; i < key.length; i++) {
                                        if (i % 2 == 0) {
                                            result += key[i];
                                        } else {
                                            result += `${ranStr[Math.floor((Math.random() * ranStr.length))]}${key[i]}`;
                                        }
                                    }
                                    return result;
                                }
                            }(elem);
                        },
                        async: false,
                        cache: false
                    });
                    // }
                    //console.log(options);
                    return options;
                };

                this.hlsPlayer.src({
                    src: `${this.courseItemDetail.fileViewUrl}?u=${this.currentUser.userId}`,
                    type: "application/x-mpegURL"
                });
                this.hlsPlayer.load();
                this.hlsPlayer.play();
            }

            //Ghi Log
            if (!this.isTeacher) {
                this.isUpdateLog = true;
                this.timerId = setInterval(() => this.updateCourseUserLog(), 60000);
            }
        }
    }

    stopVideo() {
        clearInterval(this.timerId);
        this.isUpdateLog = false;
        if (this.hlsPlayer !== undefined) {
            this.hlsPlayer.pause();
            this.hlsPlayer.src({
                src: ''
            });
            this.hlsPlayer.load();
        }
    }

    async openScorm() {
        await this.getMyLatestCourseUserLog(false);
        var scormResult = new ScormResult();
        if (this.courseUserLogItem != null && this.courseUserLogItem.id > 0) {
            if (this.courseUserLogItem.scormResult) {
                scormResult = JSON.parse(this.courseUserLogItem.scormResult) as ScormResult;
            }
        } else {
            // Tạo log
            this.isUpdateLog = true;
            await this.updateCourseUserLog();
            if (this.courseUserLogItem.scormResult) {
                scormResult = JSON.parse(this.courseUserLogItem.scormResult) as ScormResult;
            }
        }
        //console.log(this.courseUserLogItem);
        this.isViewScorm = true;
        this.scormUrl = this.courseItemDetail.fileViewUrl;
        this.player.initializeEvent.subscribe(val => {
            if (scormResult) {
                this.player.runtimeData = scormResult.runtimeData;
            }
        });

        this.player.setValueEvent.subscribe(val => {
            this.courseUserLogItem.scormResult = JSON.stringify(val);
            sessionStorage.setItem('scorm', JSON.stringify(val));
            if (val.runtimeData['cmi.completion_status'] == 'completed' || val.runtimeData['cmi.success_status'] == 'passed') {
                this.courseUserLogItem.isLearned = true;
                console.log('setValueEvent - finish');
            }
            this.updateScormResult();
        });

        this.player.finishEvent.subscribe(val => {
            console.log('finishEvent:', val);
            this.courseUserLogItem.scormResult = JSON.stringify(val);
            this.courseUserLogItem.isLearned = true;
            this.updateScormResult();
        });

        //Ghi Log
        if (!this.isTeacher) {
            this.isUpdateLog = true;
            this.timerId = setInterval(() => this.updateCourseUserLog(), 60000);
        }

    }

    updateScormResult() {
        console.log('updateScormResult');
        this._courseUserLogService.updateScormResult(this.courseUserLogItem);
    }

    getsCourseItemAttactment(idCourseItem: number) {
        this._courseItemAttachmentService.getsByIdCourseItem(idCourseItem).then(rs => {
            if (rs.success) {
                this.dsCourseItemAttactment = rs.data;
            }
        })
    }

    openDocument() {
        const fileType = this.getFileType(this.courseItemDetail.fileViewUrl);
        const documentType = this.getDocumentType(fileType);
        console.log(documentType);
        if (documentType != '') {
            setTimeout(() => {
                this.initDocumentViewer(fileType, documentType, this.courseItemDetail.fileViewUrl, this.courseItemDetail.fileViewUrl);
            }, 500);
        };
        //Ghi Log
        if (!this.isTeacher) {
            this.isUpdateLog = true;
            this.updateCourseUserLog();
        }
    }

    initDocumentViewer(fileType, documentType, fileUrl, fileName) {
        console.log(fileUrl);
        const config = {
            'height': '100%',
            'width': '100%',
            'documentType': `${documentType}`,
            'editorConfig': {
                'mode': 'view',
                'customization': {
                    'chat': false,
                    'compactToolbar': true,
                    // 'logo': {
                    //     'image': `${logoUrl}`,
                    //     'imageEmbedded': `${logoUrl}`
                    // },
                    'about': false,
                    'comments': false
                }
            },
            'document': {
                'permissions': {
                    'comment': false,
                    'download': true,
                    'edit': false,
                    'print': true,
                    'review': false,
                    'reader': true
                },
                'fileType': `${fileType}`,
                'title': `${fileName}`,
                'url': `${fileUrl}`
            }
        };
        const docEditor = new DocsAPI.DocEditor('file-viewer', config);
    }

    getFileType(fileName: string) {
        const arr = fileName.split('.');
        return arr[arr.length - 1];
    }

    getDocumentType(fileType: string) {
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return 'image';
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return 'text';
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return 'spreadsheet';
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return 'presentation';
        }
        return '';
    }

    // Nộp bài tập
    checkCanDoTask() {
        this.showCanDoTask = false;
        let startTime = this.courseItemDetail.startTime ? new Date(this.courseItemDetail.startTime) : new Date(2000, 1, 1);
        let endTime = this.courseItemDetail.endTime ? new Date(this.courseItemDetail.endTime) : new Date(3000, 1, 1);
        let extraTime = this.courseItemDetail.extraTime ? new Date(this.courseItemDetail.extraTime) : new Date(3000, 1, 1);
        let currTime = new Date();
        if (this.courseItemDetail.haveExtraTime) {
            if (startTime <= currTime && currTime <= extraTime) {
                this.showCanDoTask = true;
            }
        } else {
            if (startTime <= currTime && currTime <= endTime) {
                this.showCanDoTask = true;
            }
        }

    }

    // Bài tập
    onUpload(event) {
        if (event.originalEvent.body.success) {
            for (let i = 0; i < event.originalEvent.body.data.length; i++) {
                var attactInfo = new CourseUserLog_AttactmentModel();
                attactInfo.idCourseUserLog = this.courseUserLogItem.id;
                attactInfo.title = event.files[i].name;
                attactInfo.fileViewUrl = `${environment.apiDomain.svFsSystemEndpoint}${event.originalEvent.body.data[i]}`;
                this.uploadedFiles.push(attactInfo);
            }
        }
    }

    onSendAssignment() {
        var model = this.courseUserLogItem;
        model.idCourse = this.courseDetail.id;
        model.idCourseItem = this.courseItemDetail.id;
        model.idCourseItemType = this.courseItemDetail.idCourseItemType;
        model.dsAttactment = this.uploadedFiles;
        this._courseUserLogService.updateLogAssignment(model).then(rs => {
            if (rs.success) {
                this._notifierService.showUpdateDataSuccess();
                this.getMyLatestCourseUserLog(true);
                this.uploadedFiles = [];
            } else {
                this._notifierService.showWarning(rs.error);
            }
        })
    }

    //End Bài tập

    //#endregion

    //#region doExam
    doExam() {
        var model = this.courseUserLogItem;
        model.idCourse = this.courseDetail.id;
        model.idCourseItem = this.courseItemDetail.id;
        model.idCourseItemType = this.courseItemDetail.idCourseItemType;
        model.totalTime = this.courseItemDetail.totalTime;
        this._courseUserLogService.startExam(model).then(rs => {
            if (rs.success) {
                this.courseUserLogItem = rs.data;
                this.config.leftTime = rs.data.countDown;
                this.doingExam();
            } else {
                this._notifierService.showWarning(rs.error);
            }
        })
    }

    async doingExam() {
        await this._courseUserLog_TestDetailService.getsForExam(this.courseUserLogItem.id, this.courseItemDetail.id).then(rs => {
            if (rs.success) {
                this.dataTest = rs.data;
                this.currentQuestion = rs.data[0];
                this.totalQuestion = rs.data.length - 1;
                this.isDoingExam = true;
            }
        })
    }

    doFinishExam() {
        this._notifierService.showConfirm("Bạn chắc chắn muốn kết thúc bài thi?").then(rs => {
            this._courseUserLogService.finishExam(this.courseUserLogItem.id).then(rs => {
                if (rs.success) {
                    this.isDoingExam = false;
                    this.courseUserLogItem = rs.data;
                }
            })
        });
    }
    autoFinishExam() {
        this._courseUserLogService.finishExam(this.courseUserLogItem.id).then(rs => {
            if (rs.success) {
                this.isDoingExam = false;
                this.courseUserLogItem = rs.data;

            }
        })
    }

    countdownEvent(evt: any) {
        if (evt.action == 'done') {
            if (this.courseUserLogItem.status == 1 && this.config.leftTime > 0) {
                this.autoFinishExam();
            }
        }
    }

    async goQuestion(i: number) {
        if (this.currentQuestionTextEditor.id > 0) {
            this.radioBtnClick(this.currentQuestionTextEditor);
        }
        this.currentQuestionIndex = i;
        this.currentQuestion = this.dataTest[i];
        this.currentQuestionTextEditor = new TestQuestion();
        console.log(this.currentQuestion);
    }

    goPrevQuestion() {
        if (this.currentQuestionIndex > 0) {
            this.goQuestion(this.currentQuestionIndex - 1);
        }

    }

    goNextQuestion() {
        if (this.currentQuestionIndex < this.totalQuestion) {
            this.goQuestion(this.currentQuestionIndex + 1);
        }
    }

    onTextEditorChange(question: any) {
        this.currentQuestionTextEditor = question;
    }

    radioBtnClick(question: any) {
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: question.answerText,
            idCourseUserLog: question.idCourseUserLog
        };
        if (question.idCourseTestQuestionParent > 0) {
            this.checkDoSubQuestion(question.idCourseTestQuestionParent);
        }
        this._courseUserLog_TestDetailService.updateLog(model);

    }

    async checkBoxChange(question: any) {
        var dsAns = question.courseTestQuestionAnswer.map(x => x);
        dsAns = dsAns.sort((a, b) => {
            return a.id - b.id;
        });
        var ans = [];
        await dsAns.forEach(element => {
            if (element.isUserAnswer == true) {
                ans.push(element.id);
            }
        });
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: ans.join(","),
            idCourseUserLog: question.idCourseUserLog
        };
        question.answerText = model.answerText;
        if (question.idCourseTestQuestionParent > 0) {
            this.checkDoSubQuestion(question.idCourseTestQuestionParent);
        }
        this._courseUserLog_TestDetailService.updateLog(model);
    }
    async onTextChange(question: any) {
        var ans = [];
        await question.courseTestQuestionAnswer.forEach(element => {
            ans.push(element.answerText);
        });
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: ans.join(","),
            idCourseUserLog: question.idCourseUserLog
        };
        question.answerText = model.answerText;
        if (question.idCourseTestQuestionParent > 0) {
            this.checkDoSubQuestion(question.idCourseTestQuestionParent);
        }
        this._courseUserLog_TestDetailService.updateLog(model);
    }

    checkDoSubQuestion(idCourseTestQuestionParent: number) {
        var parentQuesion = this.dataTest.filter(x => x.idCourseTestQuestion == idCourseTestQuestionParent)[0];
        var dsChildQuestion = parentQuesion.dsSubQuestion;
        var dsNotAnswer = dsChildQuestion.filter(x => x.answerText == null || x.answerText == undefined || x.answerText == '');

        console.log(dsNotAnswer);
        if (dsNotAnswer.length > 0) {
            parentQuesion.answerText = null;
            var model = {
                id: parentQuesion.idCourseUserLog_TestDetail,
                answerText: null,
                idCourseUserLog: parentQuesion.idCourseUserLog
            };
            this._courseUserLog_TestDetailService.updateLog(model);
        } else {
            parentQuesion.answerText = parentQuesion.id.toString();
            var model1 = {
                id: parentQuesion.idCourseUserLog_TestDetail,
                answerText: parentQuesion.id.toString()
            };
            this._courseUserLog_TestDetailService.updateLog(model1);
        }
    }

    onUploadInTesting(question: any, event) {
        if (event.originalEvent.body.success) {
            var model = {
                id: question.idCourseUserLog_TestDetail,
                answerFileUrl: `${environment.apiDomain.svFsSystemEndpoint}${event.originalEvent.body.data[0]}`,
                idCourseUserLog: question.idCourseUserLog
            };
            question.answerFileUrl = `${environment.apiDomain.svFsSystemEndpoint}${event.originalEvent.body.data[0]}`;
            if (question.idQuestionParent > 0) {
                this.checkDoSubQuestion(question.idQuestionParent);
            }
            this._courseUserLog_TestDetailService.updateLog(model);
        }
    }


    dragStart(dsAnswers: any[], event) {
        this.indexDrag = dsAnswers.findIndex(x => x.id == event.id);
        this.itemDrag = event;
    }

    drop(currentQuestion, dsAnswers, event) {
        this.indexDrog = dsAnswers.findIndex(x => x.id == event.id);
        this.itemDrop = event;
        if (this.indexDrag >= 0 && this.indexDrog >= 0) {
            dsAnswers[this.indexDrag] = this.itemDrop;
            dsAnswers[this.indexDrog] = this.itemDrag;
        }
        currentQuestion.answerText = (dsAnswers.map(x => x.id)).join(',');
        console.log(currentQuestion.answerText);

        var model = {
            id: currentQuestion.idCourseUserLog_TestDetail,
            answerText: currentQuestion.answerText,
            idCourseUserLog: currentQuestion.idCourseUserLog
        };

        if (currentQuestion.idQuestionParent > 0) {
            this.checkDoSubQuestion(currentQuestion.idQuestionParent);
        }
        this._courseUserLog_TestDetailService.updateLog(model);
    }

    //#region

    async getMyLatestCourseUserLog(hadAttactment: boolean) {
        if (!this.isTeacher) {
            await this._courseUserLogService.getMyLatestCourseUserLog(this.courseItemDetail.id).then(rs => {
                if (rs.success) {
                    if (rs.data != null) {
                        this.courseUserLogItem = rs.data;
                        this.courseUserLogItemView = Object.assign({}, rs.data);
                        if (this.courseItemDetail.idCourseItemType == 21) {
                            if (rs.data != null) {
                                if (this.courseUserLogItem.countDown <= 0) {
                                    this.autoFinishExam();
                                } else {
                                    if (rs.data.status == 1) {
                                        this.doingExam();
                                        this.config.leftTime = rs.data.countDown;
                                        if (this.countdown) {
                                            this.countdown.config = this.config;
                                            this.countdown.restart();
                                        }
                                    }

                                }
                            }
                        }
                    } else {
                        this.courseUserLogItem = new CourseUserLog(0, '');
                        this.courseUserLogItemView = new CourseUserLog(0, '');
                    }
                }
            });
        }
    }

    async updateCourseUserLog() {
        if (this.courseItemDetail.idCourseItemType == 11) {
            this.isUpdateLog = sessionStorage.getItem('hlsPlayer') == '1';
        }
        if (!this.isTeacher && this.isUpdateLog) {
            var userLog = {
                idCourse: this.courseDetail.id,
                idCourseItem: this.courseItemDetail.id,
                idCourseItemType: this.courseItemDetail.idCourseItemType,
                totalTime: this.courseItemDetail.totalTime,
                totalMark: this.courseItemDetail.totalMark,
                typeId: 1,
            };

            if (this.courseItemDetail.endTime != null) {
                var endTime = new Date(this.courseItemDetail.endTime);
                var currTime = new Date();
                if (currTime > endTime) {
                    userLog.typeId = 2;
                }
            }

            await this._courseUserLogService.updateLog(userLog).then(rs => {
                if (rs.success) {
                    this.courseUserLogItem = rs.data;
                }
            })
        }
    }


    exitLearningSpace() {
        this._router.navigate(['/']);
    }

    onGoItem(data: { type: number, value: any }) {
        console.log(data);
        if (data.type == 1) {
            this.viewCourseItem(data.value);
        }
    }
}

