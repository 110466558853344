<div class="invoice_table">
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__main-block">
                <div class="sv-table-wrapper">
                    <p-table [value]="dsResult" class="sv-table" scrollHeight="auto" [lazy]="true">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 80px;">STT</th>
                                <th>
                                    {{'Nhiệm vụ học tập' | translate}}
                                </th>
                                <th style="width: 120px;">{{'Điểm' | translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                            <tr>
                                <td>
                                    {{rowIndex + 1}}
                                </td>
                                <td>
                                    <p>{{rowData.title}}</p>
                                    <div class="col-md-12" style="font-style: italic; "
                                        *ngIf="rowData.approvalMark == true && rowData.teacherComment ">
                                        <b>Nhận xét của giáo viên:</b>
                                        <p style="font-style: italic;" [innerHTML]="rowData.teacherComment">
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <span *ngIf="rowData.approvalMark == true">{{rowData.finalMark}}</span>
                                    <span *ngIf="rowData.approvalMark != true">-----</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
