<main>
    <div class="col-md-12">
        <div class="posts" *ngIf="!showTopic">
            <div class="col-md-12">
                <a class="default-btn mb-3" (click)="addTopic()">
                    <i class="ci ci-add"></i>
                    {{'Tạo chủ đề mới'}}
                </a>
            </div>
            <div class="posts__head">
                <div class="posts__topic">{{'Chủ đề' | translate}}</div>
                <div class="posts__users">{{'Người tạo' | translate}}</div>
                <div class="posts__replies">{{'Thảo luận' | translate}}</div>
                <div class="posts__views">{{'Lượt xem' | translate}}</div>
                <div class="posts__activity">{{'Hoạt động' | translate}}</div>
            </div>
            <div class="posts__body">
                <ng-container *ngFor="let item of dataSource; index as i">
                    <div class="posts__item" [class.posts__item--bg-gradient]="i % 3 == 0 && item.pin != true"
                        [class.bg-f2f4f6]="i % 3 == 2" [class.bg-a7cdbd]="i % 3 == 2"
                        [class.bg-fef2e0]="item.pin == true">
                        <div class="posts__section-left">
                            <div class="posts__topic">
                                <div class="posts__content">
                                    <a *ngIf="!item.pin" (click)="updatePin(item, true)">
                                        <i class="ci ci-pin"></i>
                                    </a>
                                    <a *ngIf="item.pin" (click)="updatePin(item, false)">
                                        <i class="ci ci-unlink"></i>
                                    </a>
                                    <a (click)="viewTopicDetail(item.id)">
                                        <h3>{{item.title}}</h3>
                                    </a>
                                    <p [innerHTML]="item.bodyHtml"></p>
                                </div>
                            </div>
                        </div>
                        <div class="posts__section-right">
                            <div class="posts__users js-dropdown">
                                <div pTooltip="{{item.modifiedByName}}">
                                    <a class="avatar"><img src="{{item.modifiedByName | avatar}}" alt="avatar"></a>
                                </div>
                            </div>
                            <div class="posts__replies">{{item.replyCount}}</div>
                            <div class="posts__views">{{item.viewCount}}</div>
                            <div class="posts__activity">{{item.modified | timeAgo}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="showTopic">
            <div class="nav">
                <div class="nav__thread">
                    <a (click)="viewTopics()" class="nav__thread-btn nav__thread-btn--prev">
                        <i class="ci ci-undo"></i>Trở về
                    </a>
                    <!-- <a href="#" class="nav__thread-btn nav__thread-btn--next">Chủ đề tiếp theo<i class="icon-Arrow_Right"></i></a> -->
                </div>
            </div>
            <div class="topics">
                <div class="topics__heading">
                    <h2 class="topics__heading-title">{{itemTopic.title}}</h2>
                </div>
                <div class="topics__content">
                    <div class="topic">
                        <div class="topic__head">
                            <div class="topic__avatar">
                                <a class="avatar"><img src="{{itemTopic.modifiedByName | avatar}}" alt="avatar"></a>
                            </div>
                            <div class="topic__caption">
                                <div class="topic__name">
                                    <a>{{itemTopic.modifiedByName}}</a>
                                </div>
                                <div class="topic__date"><i class="icon-Watch_Later"></i>
                                    {{itemTopic.modified | date:'dd/MM/yyyy HH:mm'}}
                                </div>
                            </div>
                        </div>
                        <div class="topic__content">
                            <div class="topic__text">
                                <p [innerHTML]="itemTopic.bodyHtml"></p>
                            </div>
                            <div class="topic__footer">
                                <div class="topic__footer-likes">
                                    <div pTooltip="Số lượt xem">
                                        <i class="ci ci-view"></i>
                                        <span>{{itemTopic.viewCount}}</span>
                                    </div>
                                    <div pTooltip="Số lượt thảo luận">
                                        <i class="ci ci-user-follow"></i>
                                        <span>{{itemTopic.replyCount}}</span>
                                    </div>
                                </div>
                                <div class="topic__footer-share">
                                    <a (click)="addPost()" class="btn btn--type-02"><i class="ci ci-reply"
                                            style="color: white;"></i>
                                        Trả lời</a>
                                </div>
                            </div>
                            <div class="topic__footer" *ngIf="showAddPost">
                                <div class="col-md-12">
                                    <p-editor [(ngModel)]="itemPost.bodyHtml"
                                        [style]="{'height':'200px', 'width': '100%'}">
                                    </p-editor>
                                </div>
                                <div class="topic__footer-share">
                                    <a (click)="cancelPost()" class="nav__thread-btn nav__thread-btn--prev">
                                        <i class="ci ci-undo"></i>Hủy
                                    </a>
                                    <a (click)="savePost()" class="btn btn--type-02">
                                        <i class="ci ci-save" style="color: white;"></i>Tạo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let itemTopic of dataPost">
                        <div class="topic">
                            <div class="topic__head">
                                <div class="topic__avatar">
                                    <a class="avatar"><img src="{{itemTopic.modifiedByName | avatar}}" alt="avatar"></a>
                                </div>
                                <div class="topic__caption">
                                    <div class="topic__name">
                                        <a>{{itemTopic.modifiedByName}}</a>
                                    </div>
                                    <div class="topic__date"><i class="icon-Watch_Later"></i>
                                        {{itemTopic.modified | date:'dd/MM/yyyy HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <div class="topic__content">
                                <div class="topic__text">
                                    <p [innerHTML]="itemTopic.bodyHtml"></p>
                                </div>
                                <div class="topic__footer">
                                    <div class="topic__footer-likes">
                                        <div>
                                            <a (click)="updateCount(itemTopic, 1, 0, 0)"><i
                                                    class="ci ci-face-activated-add"></i></a>
                                            <span>{{itemTopic.likeCount}}</span>
                                        </div>
                                        <div>
                                            <a (click)="updateCount(itemTopic, 0, 1, 0)"><i
                                                    class="ci ci-face-dissatisfied"></i></a>
                                            <span>{{itemTopic.dislikeCount}}</span>
                                        </div>
                                        <div>
                                            <a (click)="updateCount(itemTopic, 0, 0, 1)"><i
                                                    class="ci ci-favorite"></i></a>
                                            <span>{{itemTopic.loveCount}}</span>
                                        </div>
                                    </div>
                                    <div class="topic__footer-share">
                                        <a (click)="replyPost(itemTopic.id)" class="btn btn--type-02"><i
                                                class="ci ci-reply" style="color: white;"></i>Trả lời
                                        </a>
                                    </div>
                                </div>
                                <div class="topic__footer" *ngIf="showAddReplyPost && currentIdPost == itemTopic.id">
                                    <div class="col-md-12">
                                        <p-editor [(ngModel)]="itemPost.bodyHtml"
                                            [style]="{'height':'200px', 'width': '100%'}">
                                        </p-editor>
                                    </div>
                                    <div class="topic__footer-share">
                                        <a (click)="cancelPost()" class="nav__thread-btn nav__thread-btn--prev">
                                            <i class="ci ci-undo"></i>Hủy
                                        </a>
                                        <a (click)="savePost()" class="btn btn--type-02"><i class="ci ci-save"
                                                style="color: white;"></i>Tạo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="topic topic--comment" *ngFor="let item of itemTopic.children">
                            <div class="topic__head">
                                <div class="topic__avatar">
                                    <a class="avatar"><img src="{{item.modifiedByName | avatar}}" alt="avatar"></a>
                                </div>
                                <div class="topic__caption">
                                    <div class="topic__name">
                                        <a>{{item.modifiedByName}}</a>
                                    </div>
                                </div>
                                <!-- <a href="#" class="topic__arrow topic__arrow--up"><i class="icon-Arrow_Up"></i></a> -->
                            </div>
                            <div class="topic__content">
                                <div class="topic__text">
                                    <p [innerHTML]="item.bodyHtml"></p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</main>
<app-learning-space-forum-add-topic #pEdit (closePopup)="onPopupClosed($event)"></app-learning-space-forum-add-topic>
