import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { TestQuestionType } from '../../../../../config/enums';
import { InteractiveVideoTestQuestionService } from '../../../../../services/interactive-video-test-question.service';

@Component({
    selector: 'app-interactive-video-test-question-mc',
    templateUrl: './interactive-video-test-question-mc.component.html',
    styleUrls: ['./interactive-video-test-question-mc.component.scss']
})
export class InteractiveVideoTestQuestionMcComponent extends SecondPageEditBase implements OnInit {

    showEditor = false;
    itemAnswer = { id: 0, idInteractiveVideoTestQuestion: 0, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: 0 }
    indexAnswer = -1;
    correctAnswer = [];
    idQuestionParent = null;
    idInteractiveVideoTest = 0;
    editor_modules = {
        imageResize: true
    };
    constructor(
        protected _testquestionService: InteractiveVideoTestQuestionService,
        protected _injector: Injector,
    ) {
        super(_testquestionService, _injector);

        this.formGroup = new FormGroup({
            questionText: new FormControl('', Validators.required),
            orderNo: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    async onShowPopup(item: { id: 0, idInteractiveVideoTest: 0, idQuestionParent: null }) {
        this.correctAnswer = [];
        this.idInteractiveVideoTest = item.idInteractiveVideoTest;
        this.idQuestionParent = item.idQuestionParent;
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (item.id > 0) {
            this._testquestionService.getDetail(item.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    for (let index = 0; index < rs.data.dsAnswers.length; index++) {
                        if (rs.data.dsAnswers[index].isCorrect == true) {
                            this.correctAnswer.push(`${index}`);
                        }
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = {
                id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: item.idQuestionParent, idTestQuestionType: TestQuestionType.MC, levelOfDifficult: 1, mark: 1, status: 1
                , dsAnswers: [
                    { id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: this.idInteractiveVideoTest },
                    { id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: this.idInteractiveVideoTest },
                    { id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: this.idInteractiveVideoTest },
                    { id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: this.idInteractiveVideoTest }
                ]
            };
            this.submitting = false;
        }
    }

    onBeforeSave(): boolean {
        if (this.itemDetail.dsAnswers.length == 0) {
            this._notifierService.showWarning(this._translateService.instant('TestQuestion_MESSAGE_INPUTERROR'));
            return false;
        }
        return true;
    }

    addAnswer() {
        this.itemDetail.dsAnswers.push(
            { id: 0, idInteractiveVideoTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false, idInteractiveVideoTest: this.idInteractiveVideoTest }
        )
    }


    deleteAnswer(index: number) {
        this._notifierService.showDeleteConfirm().then(rs => {
            if (this.itemDetail.dsAnswers[index].isCorrect) {
                this.correctAnswer.splice(this.correctAnswer.indexOf(`${index}`), 1);
            }
            this.itemDetail.dsAnswers.splice(index, 1);

        });
    }

    onChangeCorrectAnswer() {
        this.itemDetail.dsAnswers.forEach(element => {
            element.isCorrect = false;
        });
        this.correctAnswer.forEach(element => {
            this.itemDetail.dsAnswers[parseInt(element)].isCorrect = true;
        });
    }

    delete() {
        this.submitting = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._testquestionService.deleteById(this.itemDetail.id)
                .then(response => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                    this.submitting = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.submitting = false;
                });
        });
    }

    closePopupMethod(data: any) {
        this.isShow = false;
        this.formGroup.reset();
        this.closePopup.next(data);
    }

    changeEditorView() {
        this.showEditor = !this.showEditor;
    }
}

