import { environment } from '../../../../../../../src/environments/environment';
import { FsItemModel } from '../../../models/fsitem';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import * as $ from 'jquery'
import { SvUser } from '../../../../lib/models/sv-user';
import { AuthenticationService } from '../../../../lib/services/authentication.service';
declare var DocsAPI: any;
declare const videojs: any;

@Component({
    selector: 'app-fssystem-file-viewer',
    templateUrl: './fssystem-file-viewer.component.html',
    styleUrls: ['./fssystem-file-viewer.component.scss']
})
export class FssystemFileViewerComponent implements OnInit {
    fsItem = new FsItemModel();
    fileUrl = '';
    @Output() closePopup = new EventEmitter<any>();
    @ViewChild('dialog') dialog: Dialog;
    isShow = false;
    fileType = 0;
    isMaximized = false;

    private hlsPlayer: any;
    currentUser: SvUser = new SvUser();
    @ViewChild('pVideo') pVideo: ElementRef;

    constructor(
        private _authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.currentUser = this._authenticationService.getCurrentUser();
    }

    initDocumentViewer(fileType, documentType, fileUrl, fileName) {
        console.log(fileUrl);
        const config = {
            'height': '100%',
            'width': '100%',
            'documentType': `${documentType}`,
            'editorConfig': {
                'mode': 'view',
                'customization': {
                    'chat': false,
                    'compactToolbar': true,
                    // 'logo': {
                    //     'image': `${logoUrl}`,
                    //     'imageEmbedded': `${logoUrl}`
                    // },
                    'about': false,
                    'comments': false
                }
            },
            'document': {
                'permissions': {
                    'comment': false,
                    'download': true,
                    'edit': false,
                    'print': true,
                    'review': false,
                    'reader': true
                },
                'fileType': `${fileType}`,
                'title': `${fileName}`,
                'url': `${fileUrl}`
            }
        };
        const docEditor = new DocsAPI.DocEditor('file-viewer', config);
    }

    getFileType(fileName: string) {
        const arr = fileName.split('.');
        return arr[arr.length - 1];
    }

    getDocumentType(fileType: string) {
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return 'image';
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return 'text';
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return 'spreadsheet';
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return 'presentation';
        } else if ((/(mp4|m3u8)$/i).test(fileType)) {
            return 'video';
        }

        return '';
    }

    async showPopup(status: boolean, data: any = {}) {
        this.isShow = status;
        this.fsItem = data;
        const fileType = this.getFileType(this.fsItem.title);
        const documentType = this.getDocumentType(fileType);
        this.fileUrl = this.fsItem.webPath.startsWith("http") ? this.fsItem.webPath : `${environment.apiDomain.svFsSystemEndpoint}${this.fsItem.webPath}`;
        console.log(this.fileUrl);
        if (documentType === '') {
            window.open(this.fileUrl, "_blank");
        }
        else if (documentType === 'video') {
            this.fileType = 3;
            this.hlsPlayer = videojs(this.pVideo.nativeElement, {
                fluid: true,
            });
            this.currentUser = this._authenticationService.getCurrentUser();
            const fileExtArr = this.fileUrl.split('.');
            const fileExt = fileExtArr[fileExtArr.length - 1];
            if (fileExt == 'mp4') {
                this.hlsPlayer.src({
                    src: `${this.fileUrl}`,
                });
                this.hlsPlayer.load();
                this.hlsPlayer.play();
            } else {
                videojs.Hls.xhr.beforeRequest = (options: any) => {
                    // options.uri = options.uri + `?u=${this.currentUser.userId}&key=${this.encryptKey(this.xToken)}`;
                    // return options;
                    var userId = this.currentUser.userId;
                    // if (options.uri.indexOf('.ts') >= 0) {
                    $.ajax({
                        url: `${environment.apiDomain.svFsSystemEndpoint}/FsToken/GetKey/` + `${(new Date()).getTime()}`,
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem("access_token")
                        },
                        success: function ariaDecorate(elem) {
                            options.headers = {
                                "Authorization": "Bearer " + localStorage.getItem("access_token")
                            }
                            options.uri += `?u=${userId}&key=` + function (elems) {
                                {
                                    var key = elems.data;
                                    var result = "";
                                    var ranStr = "123456780ABCDEFGHKLMNOPYTRQW";
                                    for (let i = 0; i < key.length; i++) {
                                        if (i % 2 == 0) {
                                            result += key[i];
                                        } else {
                                            result += `${ranStr[Math.floor((Math.random() * ranStr.length))]}${key[i]}`;
                                        }
                                    }
                                    return result;
                                }
                            }(elem);
                        },
                        async: false,
                        cache: false
                    });
                    // }
                    //console.log(options);
                    return options;
                };

                this.hlsPlayer.src({
                    src: `${this.fileUrl}`,
                    type: "application/x-mpegURL"
                });
                this.hlsPlayer.load();
                this.hlsPlayer.play();
            }
        }
        else if (documentType === 'image') {
            this.fileType = 1;
        } else {
            this.fileType = 2;
            setTimeout(() => {
                this.dialog.maximize();
                var view = document.getElementById("fileViewContent").parentElement.style;
                view.height = "calc(100% - 50px)";
                view.margin = "-15px";
                this.initDocumentViewer(fileType, documentType, this.fileUrl, data.title);
            }, 500);
        }
    }
}
