import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';

import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InteractiveVideoService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/interactivevideo`);
    }

    getsMyVideo(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyVideo`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    addEditItem(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/AddEditItem`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsSharedForMe(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/getsSharedForMe`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

}
