import { Component, OnInit, Injector, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { TestQuestionType } from '../../../../config/enums';
import { CourseTestQuestionService } from '../../../../services/course-test-question.service';

@Component({
    selector: 'app-course-test-question-mw',
    templateUrl: './course-test-question-mw.component.html',
    styleUrls: ['./course-test-question-mw.component.scss']
})
export class CourseTestQuestionMwComponent extends SecondPageEditBase implements OnInit {
    dsCategory = [];
    dsNumber = [];
    isShowAnswer = false;
    idQuestionParent = null;
    idCourseItem = 0;
    idCourse = 0;
    constructor(
        protected _testquestionService: CourseTestQuestionService,
        protected _injector: Injector,
    ) {
        super(_testquestionService, _injector);

        this.formGroup = new FormGroup({
            questionText: new FormControl('', Validators.required),
            mark: new FormControl(''),
            orderNo: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    getDs() {
        if (this.dsNumber.length == 0) {
            for (let index = 1; index < 20; index++) {
                this.dsNumber.push({ label: index, value: index })
            }
        }

    }

    async onShowPopup(item: { id: 0, idCourse: 0, idCourseItem: 0, idQuestionParent: null }) {
        this.continueAdding = true;
        this.getDs();
        this.idQuestionParent = item.idQuestionParent;
        this.idCourseItem = item.idCourseItem;
        this.idCourse = item.idCourse;
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (item.id > 0) {
            this._testquestionService.getDetail(item.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: item.idQuestionParent, idTestQuestionType: TestQuestionType.MW, levelOfDifficult: 1, mark: 1, status: 1, dsAnswers: [] };
            this.submitting = false;
        }
    }

    resetForm() {
        // this.validationSummary.resetErrorMessages();
        this.formGroup.reset();
        this.itemDetail = { id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.idQuestionParent, idTestQuestionType: TestQuestionType.MW, levelOfDifficult: 1, mark: 1, status: 1, dsAnswers: [] };
        this.submitting = false;
    }

    delete() {
        this.submitting = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._testquestionService.deleteById(this.itemDetail.idCourse, this.itemDetail.id)
                .then(response => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                    this.submitting = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.submitting = false;
                });
        });
    }

}

