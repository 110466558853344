import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseUserLogService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseuserlog`);
    }

    // getsByIdCourseItem(idCourseItem: number): Promise<ResponseResult> {
    //     const apiUrl = `${this.serviceUri}/GetsByIdCourseItem/${idCourseItem}`;
    //     return this.defaultGet(apiUrl);
    // }

    updateLog(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateLog`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    updateLogAssignment(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateLogAssignment`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    startExam(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/StartExam`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    finishExam(idTestExamUser): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/FinishExam/${idTestExamUser}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getMyLatestCourseUserLog(idCourseItem: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetMyLatestCourseUserLog/${idCourseItem}`;
        return this.defaultGet(apiUrl);
    }

    getsMyResult(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyResult/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    updateScormResult(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateScormResult`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
