<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Lịch sử học tập: {{itemDetail.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3 pt-3">
                <div class="col-md-12">
                    <div class="float-end">
                        <a class="second-btn " (click)="goBack()">
                            <i class="bx bx-arrow-back"></i>
                            {{'Trở về' | translate}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="tabs-container">
                <div class="pane">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width: 5%">STT</th>
                                <th style="width: 5%">Lớp học</th>
                                <th style="width: 10%">Tài khoản</th>
                                <th style="width: 20%">Họ & tên</th>
                                <th style="width: 60%">Lịch sử học tập</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of dataSource; index as i">
                                <tr>
                                    <td>{{i + 1}}</td>
                                    <td>{{item.tenDmLopHoc}}</td>
                                    <td>{{item.userName}}</td>
                                    <td>{{item.displayName}}</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let logItem of item.dsCourseUserLog; index as j">
                                    <td colspan="4"></td>
                                    <td>
                                        <div class="col-md-12">
                                            <span class="row" style="font-weight: bold;">
                                                {{j + 1}}.
                                                {{logItem.timeIn | date:'dd/MM/yyyy HH:mm' }} - {{logItem.timeOut |
                                                date:'dd/MM/yyyy HH:mm' }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
