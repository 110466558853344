<div class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Tin tức / thông báo / blog</span>
            <h2>Thông tin mới nhất của chúng tôi</h2>
        </div>
        <div class="row justify-content-center">
            <ng-container *ngFor="let item of dataSource">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a routerLink="/news-detail/{{item.id}}" class="d-block">
                                <img src="{{item.imageUrl}}" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">{{item.titleCategory}}</a>
                            <h3><a routerLink="/news-detail/{{item.id}}">{{item.title}}</a>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                </li>
                                <li><i class='flaticon-calendar'></i> {{item.publishedDate | date : 'dd/MM/yyyy'}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="col-lg-12 col-md-12">
                <div class="blog-post-info">
                    <p>Tìm hiểu chi tiết ngay bây giờ? <a routerLink="/news">Xem tất cả</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
