<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Giảng dạy & đánh giá' | translate}}</li>
            </ul>
            <h2>{{'Giảng dạy & đánh giá' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3">
                <div class="col-md-12 option-item">
                    <a class="default-btn float-end" [routerLink]="['/teacher/course/form']">
                        <i class="bx bx-plus-circle"></i>
                        {{'Tạo lớp học phần' | translate}}
                    </a>
                </div>
            </div>
            <ngx-tabset>
                <ngx-tab tabTitle="{{'Đang giảng dạy' | translate}}">
                    <div class="row">
                        <div class="col-lg-4 col-md-6" *ngFor="let item of dataSource">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/course-detail/{{item.id}}" class="d-block image">
                                        <img src="{{item.imageUrl | fsFileUrl}}" alt="image">
                                    </a>
                                    <!-- <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a> -->
                                    <!-- <div class="price shadow">$39</div> -->
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="{{item.avatarTeacher | fsFileUrl}}" class="rounded-circle"
                                            alt="image">
                                        <span>{{item.displayNameTeacher}}</span>
                                    </div>
                                    <h3><a routerLink="/course-detail/{{item.id}}">{{item.title}}</a></h3>
                                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p> -->
                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i class='flaticon-agenda'></i> {{item.countCourseItem}} {{'Nội dung' |
                                            translate}}</li>
                                        <li><i class='flaticon-people'></i> {{item.countCourseUser}} {{'Học viên' |
                                            translate}}</li>
                                    </ul>

                                    <ul
                                        class="courses-box-footer-btn d-flex justify-content-between align-items-center ps-0">
                                        <li>
                                            <a class="default-btn" href="/teacher/course/item/{{item.id}}">
                                                <i class='bx bx-outline'></i> Soạn bài
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/learning-space/{{item.id}}" class="default-btn">
                                                <i class='bx bx-movie-play'></i> Vào lớp
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'Đã giảng dạy' | translate}}">
                    <div class="row pb-3">
                        <div class="col-lg-12 col-md-12 ordering text-end">
                            <div class="select-box">
                                <label>{{'Chọn năm học' | translate}}</label> &nbsp;
                                <p-dropdown [options]="dsSvSchoolYear" [(ngModel)]="idSchoolYearOld"
                                    (onChange)="getOldData()"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6" *ngFor="let item of dataSourceOld">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/course-detail/{{item.id}}" class="d-block image">
                                        <img src="{{item.imageUrl | fsFileUrl}}" alt="image">
                                    </a>
                                    <!-- <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a> -->
                                    <!-- <div class="price shadow">$39</div> -->
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="{{item.avatarTeacher | fsFileUrl}}" class="rounded-circle"
                                            alt="image">
                                        <span>{{item.displayNameTeacher}}</span>
                                    </div>
                                    <h3><a routerLink="/course-detail/{{item.id}}">{{item.title}}</a></h3>
                                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p> -->
                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i class='flaticon-agenda'></i> {{item.countCourseItem}} {{'Nội dung' |
                                            translate}}</li>
                                        <li><i class='flaticon-people'></i> {{item.countCourseUser}} {{'Học viên' |
                                            translate}}</li>
                                    </ul>

                                    <ul
                                        class="courses-box-footer-btn d-flex justify-content-between align-items-center ps-0">
                                        <li>
                                            <a class="default-btn" href="/teacher/course/item/{{item.id}}">
                                                <i class='bx bx-outline'></i> Nội dung
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/learning-space/{{item.id}}" class="default-btn">
                                                <i class='bx bx-movie-play'></i> Vào lớp
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'Thùng rác' | translate}}">
                    <div class="row">
                        <div class="col-lg-4 col-md-6" *ngFor="let item of dataTrash">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/course-detail/{{item.id}}" class="d-block image">
                                        <img src="{{item.imageUrl | fsFileUrl}}" alt="image">
                                    </a>
                                    <!-- <a routerLink="/single-courses-1" class="fav"><i class="flaticon-heart"></i></a> -->
                                    <!-- <div class="price shadow">$39</div> -->
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="{{item.avatarTeacher | fsFileUrl}}" class="rounded-circle"
                                            alt="image">
                                        <span>{{item.displayNameTeacher}}</span>
                                    </div>
                                    <h3><a routerLink="/course-detail/{{item.id}}">{{item.title}}</a></h3>
                                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p> -->
                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i class='flaticon-agenda'></i> {{item.countCourseItem}} {{'Nội dung' |
                                            translate}}</li>
                                        <li><i class='flaticon-people'></i> {{item.countCourseUser}} {{'Học viên' |
                                            translate}}</li>
                                    </ul>

                                    <ul
                                        class="courses-box-footer-btn d-flex justify-content-between align-items-center ps-0">
                                        <li>
                                        </li>
                                        <li>
                                            <a href="/teacher/course/form/{{item.id}}" class="default-btn">
                                                <i class='bx bx-movie-play'></i> Sửa thông tin
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>
