<div class="col-lg-12 col-md-12">
    <div class="col-lg-12 col-md-12" [hidden]="!showVideo">
        <div class=" s5v-container s5v-standalone s5v-interactive-video" [class.s5v-fullscreen]="fullScreen"
            style="font-size: 27.25px;">
            <div class="s5v-video-wrapper s5v-video hardware-accelerated">
                <video id='sxmvideo' #pVideo (timeupdate)="onTimeUpdate($event)" (progress)="onVideoProgress($event)"
                    (loadeddata)="onLoadeddata($event)" class="video-js vjs-default-skin s5v-video">
                </video>

            </div>
            <div role="toolbar" class="s5v-controls">
                <div class="s5v-controls-left">
                    <div role="button" class="s5v-control s5v-play" [class.s5v-pause]="!videoPlay"
                        (click)="onPlayPause()">
                    </div>
                </div>
                <div class="s5v-control s5v-slider" #control>
                    <!-- <div class="s5v-bookmarks-container">
                    </div> -->
                    <div class="s5v-endscreens-container"></div>
                    <div class="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"
                        (click)="onSeek($event)">
                        <div class="s5v-buffered" [style.width.%]="loadedPercentage"></div>
                        <div class="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min"
                            [style.width.%]="currentTimePercent"></div>
                        <a class="ui-slider-handle ui-state-default ui-corner-all"
                            [style.margin-left.%]="currentTimePercent"></a>
                    </div>
                    <div role="menu" class="s5v-interactions-container">
                        <div role="menuitem" class="s5v-seekbar-interaction s5v-multichoice-interaction"
                            *ngFor="let item of dsExam" pTooltip="{{item.ten}}" [style.left.%]="item.percentTime">
                        </div>
                    </div>
                </div>
                <div class="s5v-controls-right">
                    <div class="s5v-control s5v-time">
                        <time class="s5v-current">
                            <span class="human-time">{{ currentTime * 1000 | date:'mm:ss'}}</span>
                        </time>
                        <span> / </span>
                        <time class="s5v-total">
                            <span class="human-time">{{ totalTime * 1000 | date:'mm:ss'}}</span>
                        </time>
                    </div>
                    <div role="button" class="s5v-control s5v-mute" [class.s5v-muted]="videoMute" (click)="onMute()">
                    </div>
                </div>
                <div role="button" class="s5v-control s5v-fullscreen" (click)="onFullScrenn()">
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 col-md-12" [hidden]="showVideo">
        <div class="row" *ngIf="!showResult">
            <div class="col-lg-8 col-md-8">
                <div class="question-content">
                    <ng-container *ngIf="currentQuestion.idTestQuestionType != 3">
                        <div class="col-md-12" [innerHTML]="currentQuestion.questionText"></div>
                    </ng-container>
                    <ng-container *ngIf="currentQuestion.idTestQuestionType == 3">
                        <div class="col-md-12">
                            <ng-container *ngFor="let item of currentQuestion.dsAnswers; index as i">
                                <normal [innerHTML]="currentQuestion.dsQuestionText[i]"></normal>
                                <input type="text" class="mwText" [(ngModel)]="item.answerText"
                                    (change)="onTextChange(currentQuestion)" />
                            </ng-container>
                            <normal [innerHTML]="currentQuestion.dsQuestionText[currentQuestion.dsAnswers.length]">
                            </normal>
                        </div>
                    </ng-container>

                    <div class="answer row">
                        <!-- SC -->
                        <div class="row" *ngIf="currentQuestion.idTestQuestionType == 1">
                            <div class="row" *ngFor="let ans of currentQuestion.dsAnswers">
                                <div class="col-md-1 pd-tb0">
                                    <p-radioButton name="{{currentQuestion.id}}" value="{{ans.id}}"
                                        (onClick)="radioBtnClick(currentQuestion)"
                                        [(ngModel)]="+currentQuestion.answerText">
                                    </p-radioButton>
                                </div>
                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                            </div>
                        </div>
                        <div class="row" *ngIf="currentQuestion.idTestQuestionType == 2">
                            <div class="row" *ngFor="let ans of currentQuestion.dsAnswers">
                                <div class="col-md-1 pd-tb0">
                                    <p-checkbox name="{{ans.id}}" binary="true"
                                        (onChange)="checkBoxChange(currentQuestion)" [(ngModel)]="ans.isUserAnswer">
                                    </p-checkbox>
                                </div>
                                <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                            </div>
                        </div>
                        <div class="row" *ngIf="currentQuestion.idTestQuestionType == 6">
                            <div class="col-md-12 pb-2" *ngFor="let item of currentQuestion.dsAnswers">
                                <div class="col-md-12 btn btn-secondary" pDraggable="items"
                                    (onDragStart)="dragStart(currentQuestion.dsAnswers, item)" pDroppable="items"
                                    (onDrop)="drop(currentQuestion, currentQuestion.dsAnswers, item)"
                                    [innerHTML]="item.answerText">
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="currentQuestion.idTestQuestionType == 7">
                            <div class="col-md-6 pd-2">
                                <div class="row pb-2" *ngFor="let item of currentQuestion.dsAnswers">
                                    <div class="col-md-12 btn btn-light" [innerHTML]="item.answerText">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pd-2">
                                <div class="row pb-2" *ngFor="let item of currentQuestion.dsMatching">
                                    <div class="col-md-12 btn btn-secondary dragdrop" pDraggable="items"
                                        (onDragStart)="dragStart(currentQuestion.dsMatching, item)" pDroppable="items"
                                        (onDrop)="drop(currentQuestion, currentQuestion.dsMatching, item)"
                                        [innerHTML]="item.matching">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <aside class="question-sidebar">
                    <div class="score-sb">
                        <div class="list-wrap ps-container ps-active-x">
                            <ul>
                                <li [class]="(currentQuestionIndex == i) ? 'err': (item.answerText !== null ? 'active': 'val')"
                                    (click)="goQuestion(i)" style="cursor: pointer;"
                                    *ngFor="let item of dataTest; let i = index"><i class="icon"></i>Câu
                                    {{i + 1}}<span>{{item.mark}}</span></li>
                            </ul>
                            <div class="btn-box">
                                <a class="default-btn float-end" (click)="doFinishExam()" [disabled]="isLoading">
                                    <i class="__icon ci ci-save"></i> Nộp bài</a>
                            </div>
                            <div class="ps-scrollbar-x-rail" style="width: 326px; display: inherit; left: 0px;">
                                <div class="ps-scrollbar-x" style="left: 0px; width: 150px;"></div>
                            </div>
                            <div class="ps-scrollbar-y-rail"
                                style="top: 0px; height: 520px; display: none; right: 0px;">
                                <div class="ps-scrollbar-y" style="top: 0px; height: 0px;"></div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
        <div class="row" *ngIf="showResult">
            <div class="col-lg-12 col-md-12">
                <table class="table-question mb10">
                    <thead>
                        <tr>
                            <th colspan="2" style="width: 20%;">Chi tiết bài làm</th>
                            <th style="width: 40%;">Trả lời</th>
                            <th style="width: 40%;">Đáp án đúng</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dataTest; index as i">
                            <td>
                                <i class="bx bx-x" *ngIf="!item.isCorrect"></i>
                                <i class="bx bx-check" *ngIf="item.isCorrect"></i>
                            </td>
                            <td class="td-quest">
                                Câu {{i + 1}}
                            </td>
                            <ng-container *ngIf="item.idTestQuestionType == 1 || item.idTestQuestionType == 2">
                                <td>
                                    <div class="row" *ngFor="let itemAns of item.userAnswer">
                                        <div class="col-md-12" [innerHTML]="itemAns.answerText"></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row" *ngFor="let itemCorrect of item.dsAnswers">
                                        <div class="col-md-12" [innerHTML]="itemCorrect.answerText"
                                            *ngIf="itemCorrect.isCorrect == true"></div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="item.idTestQuestionType == 3">
                                <td>{{item.answerText}}</td>
                                <td>{{item.correctAnswer}}</td>
                            </ng-container>
                            <ng-container *ngIf="item.idTestQuestionType == 6">
                                <td>
                                    <div class="col-md-12 pb-2" *ngFor="let itemAns of item.userAnswer">
                                        <div class="col-md-12 btn btn-light" [innerHTML]="itemAns.answerText"></div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-md-12 pb-2" *ngFor="let itemCorrect of item.dsAnswers">
                                        <div class="col-md-12 btn btn-light" [innerHTML]="itemCorrect.answerText">
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="item.idTestQuestionType == 7">
                                <td>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="col-md-12 pb-2" *ngFor="let itemAns1 of item.dsAnswers">
                                                <div class="col-md-12 btn btn-light" [innerHTML]="itemAns1.answerText">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="col-md-12 pb-2" *ngFor="let itemAns of item.userAnswer">
                                                <div class="col-md-12 btn btn-light" [innerHTML]="itemAns.answerText">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="col-md-12 pb-2" *ngFor="let itemAns of item.dsAnswers">
                                                <div class="col-md-12 btn btn-light" [innerHTML]="itemAns.answerText">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="col-md-12 pb-2" *ngFor="let itemAns1 of item.dsAnswers">
                                                <div class="col-md-12 btn btn-light" [innerHTML]="itemAns1.answerText">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12 col-md-12 pt-2">
                <a class="default-btn float-end" (click)="goLearning()" [disabled]="isLoading">
                    <i class="bx bx-play"></i> Tiếp tục học
                </a>
            </div>
        </div>
    </div>
</div>
