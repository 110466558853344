import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseTeacherService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseteacher`);
    }

    getsByIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    updateByIdCourse(idCourse: number, model: any[]): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateByIdCourse/${idCourse}`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
