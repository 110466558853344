import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'sv-shared';
import { environment } from '../../../../environments/environment';
import { InteractiveVideoTest } from '../../../models/interactive-video-test';
import { InteractiveVideoTestQuestion } from '../../../models/interactive-video-testquestion';
import { InteractiveVideoTestQuestionService } from '../../../services/interactive-video-test-question.service';
import { InteractiveVideoTestService } from '../../../services/interactive-video-test.service';
import { InteractiveVideoService } from '../../../services/interactive-video.service';

import videojs from 'video.js';

@Component({
    selector: 'app-interactive-video-player',
    templateUrl: './interactive-video-player.component.html',
    styleUrls: ['./interactive-video-player.component.scss']
})
export class InteractiveVideoPlayerComponent implements OnInit, OnDestroy {

    @ViewChild('control', { static: true }) control: ElementRef;
    currentTime = 0;
    totalTime = 0;
    loadedPercentage = 0;
    currentTimePercent = 0;
    offsetX = 0;
    videoMute = false;
    fullScreen = false;
    videoPlay = true;
    @ViewChild('pVideo') pVideo: ElementRef;
    currentQuestion = new InteractiveVideoTestQuestion();
    currentQuestionIndex = 0;
    dsExam = [];
    dsExamLog = [];
    showVideo = true;
    dataTest = [];
    totalQuestion = 0;
    itemDrag = {};
    indexDrag = -1;
    itemDrop = {};
    indexDrog = -1
    itemInteractiveVideoTest = new InteractiveVideoTest();
    showResult = false;
    itemDetail: any = {};
    @Input() idInteractiveVideo: number;
    private videojsPlayer: videojs.Player;

    constructor(
        protected _interactivevideoService: InteractiveVideoService,
        protected _interactiveVideoTestService: InteractiveVideoTestService,
        protected _interactiveVideoTestQuestionService: InteractiveVideoTestQuestionService,
        private _notifierService: NotifierService,
        private _translateService: TranslateService
    ) { }
    ngOnDestroy(): void {
        if (this.videojsPlayer) {
            this.videojsPlayer.dispose();
        }
    }

    ngOnInit() {
    }

    async playIntractiveVideo(id: number) {
        // console.log('playIntractiveVideo ..... ' + id)
        this.idInteractiveVideo = id;
        if (id > 0) {
            this.showVideo = true;
            this.dataTest = [];
            this.totalQuestion = 0;
            this.currentQuestionIndex = 0;
            this.currentQuestion = new InteractiveVideoTestQuestion();
            this.showResult = false;
            if (this.idInteractiveVideo > 0) {
                this._interactiveVideoTestService.getsByIdInteractiveVideo(this.idInteractiveVideo).then(rs => {
                    if (rs.success) {
                        this.dsExam = [...rs.data];
                        this.dsExamLog = rs.data;
                    }
                });

                this.videojsPlayer = videojs(this.pVideo.nativeElement, {
                    fluid: true
                });

                this._interactivevideoService.getDetail(this.idInteractiveVideo).then(rs => {

                    if (rs.success) {
                        this.itemDetail = rs.data;
                        const videoUrl = `${environment.apiDomain.svFsSystemEndpoint}/${rs.data.videoUrl}`;
                        const fileExtArr = rs.data.videoUrl.split('.');
                        const fileExt = fileExtArr[fileExtArr.length - 1];

                        if (fileExt == 'm3u8') {
                            this.videojsPlayer.src({
                                src: `${videoUrl}`,
                                type: "application/x-mpegURL"
                            });
                            this.videojsPlayer.load();
                            this.videojsPlayer.play();
                            this.videoPlay = true;
                        } else {
                            this.videojsPlayer.src({
                                src: `${videoUrl}`,
                            });
                            this.videojsPlayer.load();
                            this.videojsPlayer.play();
                            this.videoPlay = true;

                        }

                        // this.video.nativeElement.src = `${environment.apiDomain.svFsSystemEndpoint}/${rs.data.videoUrl}`;
                        // this.video.nativeElement.load();
                        // this.video.nativeElement.play();
                        // this.videoPlay = true;
                    }
                }, error => {
                    this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));

                });
            }
        }
    }


    onTimeUpdate(event) {
        // console.log(event.target.currentTime);
        this.currentTimePercent = event.target.currentTime * 100 / this.totalTime;
        this.currentTime = parseInt(event.target.currentTime);
        if (this.dsExamLog.length > 0) {
            if (this.currentTime >= this.dsExamLog[0].startTime) {
                //this.video.nativeElement.pause();
                this.videojsPlayer.pause();
                this.videoPlay = false;
                this.itemInteractiveVideoTest = this.dsExamLog[0];
                this.doingExam(this.dsExamLog[0].id);
            }
        }
    }

    doingExam(idTest: number) {
        this.showVideo = false;
        this.dataTest = [];
        this.totalQuestion = 0;
        this.currentQuestionIndex = 0;
        this.currentQuestion = new InteractiveVideoTestQuestion();
        this._interactiveVideoTestQuestionService.getsForExamByIdInteractiveVideoTest(idTest).then(rs => {
            if (rs.success) {
                if (rs.data.length == 0) {
                    this.dsExamLog.shift();
                    this.showVideo = true;
                    //this.video.nativeElement.play();
                    this.videojsPlayer.play();

                    this.videoPlay = true;
                    return;
                }
                this.dataTest = rs.data;
                this.totalQuestion = rs.data.length - 1;
                this.currentQuestion = this.dataTest[this.currentQuestionIndex];
            }
        })
    }
    doFinishExam() {
        this._notifierService.showConfirm("Bạn chắc chắn muốn nộp bài?").then(rs => {
            this._interactiveVideoTestQuestionService.finishExam(this.itemInteractiveVideoTest.id, this.dataTest).then(rs => {
                if (rs.success) {
                    this.dataTest = rs.data;
                    this.showResult = true;
                }
            })
        });
    }

    goLearning() {
        this.showVideo = true;
        //this.video.nativeElement.play();
        this.videojsPlayer.play();
        this.videoPlay = true;
        this.dsExamLog.shift();
        // console.log(this.dsExam);
    }

    goQuestion(i: number) {
        this.currentQuestionIndex = i;
        this.currentQuestion = this.dataTest[i];
    }

    radioBtnClick(question: any) {
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: question.answerText
        };
        question.answerText = model.answerText;
        // this._courseUserLog_TestDetailService.updateLog(model);

    }

    async checkBoxChange(question: any) {
        // var dsAns = question.dsAnswers.sort((a, b) => {
        //     return a.id - b.id;
        // });
        var ans = [];
        await question.dsAnswers.forEach(element => {
            if (element.isUserAnswer == true) {
                ans.push(element.id);
            }
        });
        ans = ans.sort((a, b) => {
            return a.id - b.id;
        });
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: ans.join(",")
        };
        question.answerText = model.answerText;

        // this._courseUserLog_TestDetailService.updateLog(model);
    }
    async onTextChange(question: any) {
        var ans = [];
        await question.dsAnswers.forEach(element => {
            ans.push(element.answerText);
        });
        var model = {
            id: question.idCourseUserLog_TestDetail,
            answerText: ans.join(",")
        };
        question.answerText = model.answerText;

        // this._courseUserLog_TestDetailService.updateLog(model);
    }

    dragStart(dsAnswers: any[], event) {
        this.indexDrag = dsAnswers.findIndex(x => x.id == event.id);
        this.itemDrag = event;
    }

    drop(currentQuestion, dsAnswers, event) {
        this.indexDrog = dsAnswers.findIndex(x => x.id == event.id);
        this.itemDrop = event;
        if (this.indexDrag >= 0 && this.indexDrog >= 0) {
            dsAnswers[this.indexDrag] = this.itemDrop;
            dsAnswers[this.indexDrog] = this.itemDrag;
        }
        currentQuestion.answerText = (dsAnswers.map(x => x.id)).join(',');
        // console.log(currentQuestion.answerText);

        var model = {
            id: currentQuestion.idCourseUserLog_TestDetail,
            answerText: currentQuestion.answerText
        };

        // this._courseUserLog_TestDetailService.updateLog(model);
    }



    //#region VideoPlayer
    onLoadeddata(event) {
        //this.totalTime = this.video.nativeElement.duration;
        this.totalTime = this.videojsPlayer.duration();
    }
    onVideoProgress(event) {
        // console.log(event);
        //this.loadedPercentage = this.video.nativeElement.buffered.end(0) * 100 / this.video.nativeElement.duration;

        this.loadedPercentage = this.videojsPlayer.buffered().end * 100 / this.videojsPlayer.duration();
        // console.log(this.video.nativeElement.buffered.end(0));
    }

    onSeek(event) {
        var width = this.control.nativeElement.offsetWidth - 16; // margin        
        if (event.offsetX > 0) {
            this.offsetX = event.offsetX;
        }
        console.log(this.offsetX + ' -- ' + width);
        // this.currentTimePercent = this.offsetX / width * 100;
        //this.video.nativeElement.currentTime = this.offsetX / width * this.totalTime;

        this.videojsPlayer.currentTime(this.offsetX / width * this.totalTime);
    }

    onMute() {
        // this.video.nativeElement.muted = !this.video.nativeElement.muted;
        // this.videoMute = !this.videoMute;

        this.videoMute = !this.videoMute;
        this.videojsPlayer.muted(this.videoMute)

    }

    onFullScrenn() {
        // if (!this.fullScreen) {
        //     if (this.video.nativeElement.requestFullscreen) {
        //         this.video.nativeElement.requestFullscreen();
        //     } else if (this.video.nativeElement.mozRequestFullScreen) {
        //         /* Firefox */
        //         this.video.nativeElement.mozRequestFullScreen();
        //     } else if (this.video.nativeElement.webkitRequestFullscreen) {
        //         /* Chrome, Safari and Opera */
        //         this.video.nativeElement.webkitRequestFullscreen();
        //     } else if (this.video.nativeElement.msRequestFullscreen) {
        //         /* IE/Edge */
        //         this.video.nativeElement.msRequestFullscreen();
        //     }
        // } else {

        // }       

        //this.fullScreen = !this.fullScreen;

        this.fullScreen = !this.fullScreen;
        if (this.fullScreen) {
            this.videojsPlayer.requestFullscreen();
        } else {
            this.videojsPlayer.exitFullscreen();
        }
    }

    onPlayPause() {
        // if (this.videoPlay) {
        //     this.video.nativeElement.pause();
        // } else {
        //     this.video.nativeElement.play();
        // }
        // this.videoPlay = !this.videoPlay;

        this.videoPlay = !this.videoPlay;
        if (this.videoPlay) {
            this.videojsPlayer.pause();
        } else {
            this.videojsPlayer.play();
        }


    }
    //#endregion

}
