<div class="get-instant-courses-area-two bg-f9fbff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="get-instant-courses-content-style-two">
                    <h2>Giảng viên chủ động xây dựng nội dung</h2>
                    <p>Các công cụ quản lý / xây dựng bài giảng tương tác giúp giáo viên hoàn toàn chủ động xây dựng kho
                        nội dung cá nhân & sử dụng kho nội dung này để tạo nội dung trong các khóa học / lớp học
                        phần .</p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="get-instant-courses-image-style-two">
                    <img src="assets/img/setting.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="bulb"><img src="assets/img/bulb2.png" alt="image"></div>
</div>
