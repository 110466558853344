<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Soạn bài trắc nghiệm trong video tương tác' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="row pb-3 pt-3">
                <div class="col-md-12">
                    <div class="float-end">
                        <a class="default-btn me-3" (click)="actionAddHidden.toggle($event)">
                            <i class="bx bx-plus"></i>
                            {{'Thêm mới câu hỏi' | translate}}
                        </a>
                        <a class="default-btn me-3" (click)="onImport()">
                            <i class="bx bx-file"></i>
                            {{'Thêm từ file Word' | translate}}
                        </a>
                        <a class="default-btn me-3" (click)="deleteAll()">
                            <i class="bx bx-trash"></i>
                            {{'Xóa tất cả câu hỏi' | translate}}
                        </a>
                        <a class="second-btn " (click)="goBack()">
                            <i class="bx bx-arrow-back"></i>
                            {{'Trở về' | translate}}
                        </a>
                    </div>
                    <p-overlayPanel #actionAddHidden [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                        appendTo="body" styleClass="sv-overlay-panel style-1">
                        <div class="__item-wrapper">
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.SC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SC) Câu hỏi một lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MC)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MC) Câu hỏi nhiều lựa chọn</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MW)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MW) Câu hỏi điền từ vào chỗ trống</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.SQ)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(SQ) Câu hỏi sắp xếp thứ tự</span>
                                </button>
                            </div>
                            <div class="__item">
                                <button type="button" (click)="addQuestion(testQuestionType.MA)"
                                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--cyan-60 focus-style-1">
                                    <span class="__icon ci ci-add"></span> &nbsp;
                                    <span class="sv-btn__label">(MA) Câu hỏi ghép đôi phù hợp</span>
                                </button>
                            </div>
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card mb-2" *ngFor="let item of dataSource; index as i">
                        <div class="card-header bg-success text-white" [ngClass]="{'bg-danger' : item.status == 2}">
                            <div class="row">
                                <div class="col">
                                    <h3 class="panel-title">
                                        Câu {{i + 1}}: ({{item.codeTestQuestionType}}) - ({{item.mark}} điểm)
                                    </h3>
                                </div>
                                <div class="col-auto">
                                    <div class="button-tool">
                                        <a (click)="editItem(item)" pTooltip="Sửa"><i class="bx bx-edit"
                                                aria-hidden="true"></i></a>
                                        <a (click)="deleteItem(item)" pTooltip="Xóa"><i class="bx bx-trash-alt"
                                                aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="fw-bold" [innerHTML]="item.questionText"></p>
                                    <div class="row" *ngIf="item.questionFile">
                                        <audio controls>
                                            <source src="{{item.questionFile}}" type="audio/mpeg">
                                        </audio>
                                    </div>
                                </div>
                            </div>
                            <!-- SC -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 1">
                                <div class="row" *ngFor="let ans of item.dsAnswers">
                                    <div class="col-md-1 pd-tb0">
                                        <p-radioButton name="{{item.id}}" value="{{ans.id}}" disabled
                                            [(ngModel)]="+item.correctAnswer">
                                        </p-radioButton>
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- MC -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 2">
                                <div class="row" *ngFor="let ans of item.dsAnswers">
                                    <div class="col-md-1 pd-tb0">
                                        <p-checkbox disabled name="{{ans.id}}" binary="true"
                                            [(ngModel)]="ans.isCorrect"></p-checkbox>
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- SQ -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 6">
                                <div class="row" *ngFor="let ans of item.dsAnswers; index as i">
                                    <div class="col-md-1 pd-tb0">
                                        {{i + 1}}.
                                    </div>
                                    <div class="col-md-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                </div>
                            </div>
                            <!-- MA -->
                            <div class="row mb-2 ms-5" *ngIf="item.idTestQuestionType == 7">
                                <div class="row pd-tb0" *ngFor="let ans of item.dsAnswers; index as i">
                                    <div class="col-md-2 pd-tb0">
                                        {{i + 1}}.
                                    </div>
                                    <div class="col-md-5 pd-tb0" [innerHTML]="ans.answerText"></div>
                                    <div class="col-md-5 pd-tb0" [innerHTML]="ans.matching"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-interactive-video-test-question-sc #pEditSc (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-sc>
<app-interactive-video-test-question-mc #pEditMc (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-mc>
<app-interactive-video-test-question-mw #pEditMw (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-mw>
<app-interactive-video-test-question-sq #pEditSQ (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-sq>
<app-interactive-video-test-question-ma #pEditMA (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-ma>
<app-interactive-video-test-question-import #pImport (closePopup)="onPopupClosed($event)">
</app-interactive-video-test-question-import>
