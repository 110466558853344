import { ResponseResult } from '../../../models/response-result';
import { FsItemModel } from '../../../models/fsitem';
import { environment } from '../../../../../../../src/environments/environment';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../../services/base.service';

@Injectable({
    providedIn: 'root'
})
export class FsFileUserService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svFsSystemEndpoint}/FsFileUser`);
    }

    getsByFsFolder(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByFsFolder`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    deleteFile(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteFile`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    unZipFile(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UnZipFile`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getUploadUrl() {
        return `${this.serviceUri}/DragDropUpload`;
    }

    encodeMp4(model: FsItemModel): Promise<ResponseResult> {
        const url = `${this.serviceUri}/EncodeMp4`;
        return this._http
            .post<ResponseResult>(url, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
