import { ResponseResult } from '../../../models/response-result';
import { FsItemModel } from '../../../models/fsitem';
import { environment } from '../../../../../../../src/environments/environment';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../../services/base.service';

@Injectable({
    providedIn: 'root'
})
export class FsItemTypeService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svFsSystemEndpoint}/FsFileUser`);
    }

    getAnonymousDownloadUrl(instalceId: string) {
        return instalceId.startsWith("http") ? instalceId : `${environment.apiDomain.svFsSystemEndpoint}/${instalceId}`;
    }

    isSupportedViewOnline(fileName: string): boolean {
        // file không có đuổi -> loại
        if (fileName.lastIndexOf('.') === -1) {
            return false;
        }

        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);

        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return true;
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return true;
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return true;
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return true;
        } else if ((/(mp4|m3u8)$/i).test(fileType)) {
            return true;
        }

        return false;
    }
}
