import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../services/news.service';

@Component({
    selector: 'app-mlearning-blog',
    templateUrl: './mlearning-blog.component.html',
    styleUrls: ['./mlearning-blog.component.scss']
})
export class MlearningBlogComponent implements OnInit {

    dataSource = [];
    constructor(
        private _newsService: NewsService,
    ) { }

    ngOnInit() {
        this._newsService.getsTop(3, 1).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

}
