import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'sv-shared';
import { CourseTopicPostService } from '../../../services/course-topic-post.service';
import { CourseTopicService } from '../../../services/course-topic.service';
import { LearningSpaceForumAddTopicComponent } from './learning-space-forum-add-topic/learning-space-forum-add-topic.component';

@Component({
    selector: 'app-learning-space-forum',
    templateUrl: './learning-space-forum.component.html',
    styleUrls: ['./learning-space-forum.component.scss']
})
export class LearningSpaceForumComponent implements OnInit {

    @Input() idCourse: number;
    dataSource = [];
    @ViewChild(LearningSpaceForumAddTopicComponent, { static: true }) pEdit: LearningSpaceForumAddTopicComponent;
    showTopic = false;
    itemTopic = { id: 0, idCourse: 0, title: '', bodyHtml: '', viewCount: 0, veplyCount: 0, pin: false, modified: '', modifiedByName: '' };

    showAddPost = false;
    itemPost = { id: 0, idCourseTopic: 0, idParent: 0, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
    dataPost = [];
    showAddReplyPost = false;
    currentIdPost = 0;
    @Input() isTeacher: boolean;

    constructor(
        private _courseTopicService: CourseTopicService,
        private _notifierService: NotifierService,
        private _courseTopicPostService: CourseTopicPostService,
    ) { }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this._courseTopicService.getsByIdCourse(this.idCourse, 1).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }
    addTopic() {
        //console.log(this.pEdit)
        this.pEdit.showPopup({ id: 0, idCourse: this.idCourse });
    }

    onPopupClosed(data) {
        if (data) {
            this.getData();
        }
    }
    viewTopicDetail(id: number) {
        this.showTopic = true;
        this.showAddPost = false;
        this.showAddReplyPost = false;
        this._courseTopicService.getDetail(id).then(rs => {
            if (rs.success) {
                this.itemTopic = rs.data;
            }
        });
        this.getPosts(id);

    }

    getPosts(id: number) {
        this.dataPost = [];
        this._courseTopicPostService.getsByIdTopic(id, 1).then(rs => {
            if (rs.success) {
                this.dataPost = rs.data;
            }
        })
    }

    viewTopics() {
        this.showTopic = false;
        this.getData();
    }

    addPost() {
        this.showAddPost = true;
        this.showAddReplyPost = false;
        this.itemPost = { id: 0, idCourseTopic: this.itemTopic.id, idParent: 0, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
    }
    savePost() {
        if (!this.itemPost.bodyHtml) {
            this._notifierService.showWarning('Bạn chưa nhập nội dung trả lời');
            return;
        } else {
            this._courseTopicPostService.post(this.itemPost).then(rs => {
                if (rs.success) {
                    this._notifierService.showInsertDataSuccess();
                    this.showAddPost = false;
                    this.showAddReplyPost = false;
                    this.getPosts(this.itemTopic.id);
                    this.currentIdPost = 0;
                }
            })
        }
    }

    replyPost(idParent: number) {
        this.showAddPost = false;
        this.showAddReplyPost = true;
        this.currentIdPost = idParent;
        this.itemPost = { id: 0, idCourseTopic: this.itemTopic.id, idParent: idParent, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
    }
    cancelPost() {
        this.showAddPost = false;
        this.showAddReplyPost = false;
    }

    updateCount(itemPost: any, likeCount: number, dislikeCount: number, loveCount: number) {
        this._courseTopicPostService.updateCount(itemPost.id, likeCount, dislikeCount, loveCount).then(rs => {
            if (rs.success) {
                //this._notifierService.showUpdateDataSuccess();
                itemPost.likeCount += likeCount;
                itemPost.dislikeCount += dislikeCount;
                itemPost.loveCount += loveCount;
            }
        })
    }

    updatePin(topic: any, pin: boolean) {
        if (this.isTeacher == true) {
            this._notifierService.showConfirm("Bạn có muốn ghim / bỏ ghim topic này?").then(() => {
                this._courseTopicService.updatePin(topic.id, pin).then(rs => {
                    if (rs.success) {
                        topic.pin = pin;
                    }
                })
            });
        }
    }
}
