import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SvSubjectService extends BaseService {
    constructor(http: HttpClient, injector: Injector, private _exportService: ExportService) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/svsubject`);
    }

    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/find`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getExcelTemplate(model: any[]) {
        const url = `${this.serviceUri}/GetExcelTemplate`;
        return this._http
            .post(`${url}`, model, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .subscribe(res => {
                this._exportService.saveAsExcelFile(res, 'import_dm_hocphan');
            });
    }

    importHocPhan(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/ImportHocPhan`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    insertMany(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/InsertMany`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
