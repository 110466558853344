<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>Kết quả học tập</li>
            </ul>
            <h2>{{itemDetail.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="tabs-container">
                <div class="pane">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style="width: 100px;">STT</th>
                                    <th>Nhiệm vụ học tập</th>
                                    <th style="width: 100px;">Điểm</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dsResult; index as i">
                                    <td>{{i + 1}}</td>
                                    <td>
                                        <p>{{item.title}}</p>
                                        <div class="col-md-12" style="font-style: italic; "
                                            *ngIf="item.approvalMark == true && item.teacherComment ">
                                            <b>Nhận xét của giáo viên:</b>
                                            <p style="font-style: italic;" [innerHTML]="item.teacherComment">
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="item.approvalMark == true">{{item.finalMark}}</span>
                                        <span *ngIf="item.approvalMark != true">-----</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
