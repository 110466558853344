<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <form class="search-box">
                    <input type="text" class="input-search" placeholder="Tìm lớp học phần" [(ngModel)]="query"
                        (keyup.enter)="onSearch()" name="query">
                    <button type="submit"><i class="flaticon-search" (click)="onSearch()"></i></button>
                </form>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a [routerLink]="['/']" routerLinkActive="router-link-active" class="nav-link"
                            [routerLinkActiveOptions]="{exact: true}">
                            {{'Trang chủ' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/news']" routerLinkActive="router-link-active"
                            [routerLinkActiveOptions]="{exact: true}" class="nav-link">{{'Thông báo' | translate}} </a>
                    </li>
                    <li class="nav-item ">
                        <a href="javascript:void(0)" class="nav-link">
                            {{'Lớp học phần' | translate}}
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a [routerLink]="['/courses']" [queryParams]="{typeId: '1'}" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Danh sách lớp
                                    học phần
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/courses']" [queryParams]="{typeId: '2'}" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Kỹ năng mềm
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/course-categories" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Chuyên mục phân loại
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/document" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{'Tài liệu'}}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser.typeId == 1">
                        <a routerLink="/teacher" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{'Giảng dạy & đánh giá' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser.typeId == 2">
                        <a routerLink="/student" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{'Các lớp đang học' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="isLogin">
                        <a href="javascript:void(0)" class="nav-link"> <i class="bx bx-user-circle"></i>
                            {{currentUser.displayName}} <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/profile" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <i class='bx bx-user'></i>{{'Thông tin cá nhân' | translate}}
                                </a>
                            </li>
                            <ng-container *ngIf="currentUser.typeId == 1">
                                <li class="nav-item">
                                    <a routerLink="/teacher/interactive-video" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class='bx bx-video-plus'></i>{{'Bài giảng tương tác' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/teacher/mydrive" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class='bx bx-laptop'></i>{{'Kho học liệu' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="isAdmin">
                                    <a routerLink="/admin" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class='bx bx-cog'></i>{{'Quản trị' | translate}}
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="currentUser.typeId == 2">
                                <li class="nav-item">
                                    <a routerLink="/student/mytask" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class='bx bx-task'></i>{{'Nhiệm vụ học tập' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/student" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class='bx bx-video-plus'></i>{{'Học tập' | translate}}
                                    </a>
                                </li>
                            </ng-container>
                            <li class="nav-item">
                                <a (click)="doLogOut()" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <i class='bx bx-log-out-circle'></i>{{'Đăng xuất' | translate}}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a [routerLink]="['/']" routerLinkActive="router-link-active" class="nav-link">
                            {{'Trang chủ' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">{{'Thông báo' | translate}} </a>
                    </li>
                    <li class="nav-item megamenu">
                        <a [routerLink]="['/courses']" routerLinkActive="router-link-active" class="nav-link">
                            {{'Lớp học phần' | translate}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">{{'Tài liệu'}}</a>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item">
                    <div class="cart-btn" *ngIf="totalUnRead > 0">
                        <a routerLink="/notification"><i class='bx bx-bell'></i><span>{{totalUnRead}}</span></a>
                    </div>
                </div>
                <div class="option-item" *ngIf="!isLogin">
                    <a routerLink="/signin" class="default-btn"><i class="flaticon-user"></i>{{'Đăng
                        nhập' | translate}}<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>