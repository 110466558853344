<p-dialog #dialog header="{{'FsSystem.Title.Modal' | translate}}" styleClass="sv-modal" [(visible)]="isShow"
    [modal]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <p-tabView class="tn-modal-tab">
        <p-tabPanel header=" {{'FsSystem.Title_MyFile' | translate}}">
            <app-fssystem-modal-private #pModal (closePopup)="onPopupClosed($event)">
            </app-fssystem-modal-private>
        </p-tabPanel>
        <p-tabPanel header="{{'Chia sẻ với tôi' | translate}}">
            <app-fssystem-modal-shared-with-me #pModalShareWithMe (closePopup)="onPopupClosed($event)">
            </app-fssystem-modal-shared-with-me>
        </p-tabPanel>
    </p-tabView>
</p-dialog>
