import { SvSchoolYear } from './../../../models/sv-school-year';
import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from 'sv-shared';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';
import { CourseService } from '../../../services/course.service';

@Component({
    selector: 'app-student-task-unfinished',
    templateUrl: './student-task-unfinished.component.html',
    styleUrls: ['./student-task-unfinished.component.scss']
})
export class StudentTaskUnfinishedComponent extends SecondPageIndexBase implements OnInit {
    currYear: SvSchoolYear = new SvSchoolYear();
    constructor(
        protected _svSchoolYearService: SvSchoolYearService,
        protected _courseService: CourseService,
        protected _injector: Injector,
    ) {
        super(_courseService, _injector);
    }

    async ngOnInit() {
        await this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        })
        await this.getData();
    }

    getData() {
        this._courseService.getsMyStudyCourseWithUnfinishedTask(this.currYear.id).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }
    goTask(taskItem: any) {
        this._router.navigateByUrl(`/learning-space/${taskItem.idCourse}?itemid=${taskItem.id}`);
    }

}
