<ng-container *ngFor="let item of dataSource; index as i">
    <div class="as-member">
        <div class="as-member__name">{{item.displayName}}</div>
        <div class="as-member__body">
            <div class="row g-2 mb-1">
                <div class="col-auto">
                    <div class="ratio ratio-1x1" style="width: 50px"><img src="{{item.avatar | avatar}}" alt="">
                    </div>
                </div>
                <div class="col text-muted">
                    <div class="fs-14 fw-500">{{item.fullPathOrganization}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!-- <div class="col-md-12" *ngIf="pageIndex <= totalRecord / pageSize">
    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"></div>
    <div class="main-loader mt-50" *ngIf="isLoading">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</div> -->
