import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { SecondPageIndexBase } from 'sv-shared';
import { CourseTestQuestionService } from '../../../services/course-test-question.service';
import { map, takeUntil } from 'rxjs/operators';
import { TestQuestionType } from '../../../config/enums';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CourseTestQuestionScComponent } from './course-test-question-sc/course-test-question-sc.component';
import { CourseTestQuestionMcComponent } from './course-test-question-mc/course-test-question-mc.component';
import { CourseTestQuestionMwComponent } from './course-test-question-mw/course-test-question-mw.component';
import { CourseTestQuestionSqComponent } from './course-test-question-sq/course-test-question-sq.component';
import { CourseTestQuestionEsComponent } from './course-test-question-es/course-test-question-es.component';
import { CourseTestQuestionMaComponent } from './course-test-question-ma/course-test-question-ma.component';
import { CourseTestQuestionUpComponent } from './course-test-question-up/course-test-question-up.component';
import { CourseTestQuestionSbComponent } from './course-test-question-sb/course-test-question-sb.component';
import { CourseTestQuestionImportComponent } from './course-test-question-import/course-test-question-import.component';

@Component({
    selector: 'app-course-test-question',
    templateUrl: './course-test-question.component.html',
    styleUrls: ['./course-test-question.component.scss']
})
export class CourseTestQuestionComponent extends SecondPageIndexBase implements OnInit {

    idCourse = 0;
    idCourseItem = 0;
    testQuestionType = TestQuestionType;
    @ViewChild('pEditSc') pEditSc: CourseTestQuestionScComponent;
    @ViewChild('pEditMc') pEditMc: CourseTestQuestionMcComponent;
    @ViewChild('pEditMW') pEditMW: CourseTestQuestionMwComponent;
    @ViewChild('pEditSQ') pEditSQ: CourseTestQuestionSqComponent;
    @ViewChild('pEditES') pEditES: CourseTestQuestionEsComponent;
    @ViewChild('pEditMA') pEditMA: CourseTestQuestionMaComponent;
    @ViewChild('pEditUp') pEditUp: CourseTestQuestionUpComponent;
    @ViewChild('pEditSB') pEditSB: CourseTestQuestionSbComponent;
    @ViewChild('pImport') pImport: CourseTestQuestionImportComponent;

    @ViewChild('actionAddHiddenSB') actionAddHiddenSB: OverlayPanel;
    @ViewChild('actionAddHidden') actionAddHidden: OverlayPanel;
    selectedQuestion: any = {};

    constructor(
        protected _courseTestQuestionService: CourseTestQuestionService,
        protected _injector: Injector,
        private _location: Location
    ) {
        super(_courseTestQuestionService, _injector);
    }

    async ngOnInit() {
        this.cols = [
            { field: 'questionText', header: this._translateService.instant('TestQuestion.QuestionText'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA_MODIFIED'), visible: false, width: '120px', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA_MODIFIED_BY'), visible: false, width: '120px' },
        ];
        await this._activatedRoute.params
            .pipe(map(params => params['idCourse']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourse = pid;
            });
        await this._activatedRoute.params
            .pipe(map(params => params['idCourseItem']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourseItem = pid;
            });
        await this.getData();
    }
    goBack() {
        this._router.navigate([`teacher/course/item/${this.idCourse}`]);
    }

    async getData() {
        this._courseTestQuestionService.getsByIdCourseItem(this.idCourse, this.idCourseItem).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    addQuestion(type: number) {
        this.actionAddHidden.hide();
        this.actionAddHiddenSB.hide();
        switch (type) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.MW:
                this.pEditMW.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.ES:
                this.pEditES.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.UP:
                this.pEditUp.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;
            case TestQuestionType.SB:
                this.pEditSB.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
                break;



        }
    }

    editItem(item: any) {
        switch (item.idTestQuestionType) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup(item);
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup(item);
                break;
            case TestQuestionType.MW:
                this.pEditMW.showPopup(item);
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup(item);
                break;
            case TestQuestionType.ES:
                this.pEditES.showPopup(item);
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup(item);
                break;
            case TestQuestionType.UP:
                this.pEditUp.showPopup(item);
                break;
            case TestQuestionType.SB:
                this.pEditSB.showPopup(item);
                break;

        }
    }

    doOpenPanel(event: any, item: any) {
        this.selectedQuestion = item;
        this.actionAddHiddenSB.toggle(event);
    }

    addSubQuestion(type: number) {
        this.actionAddHidden.hide();
        this.actionAddHiddenSB.hide();
        switch (type) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.MW:
                this.pEditMW.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.ES:
                this.pEditES.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
            case TestQuestionType.UP:
                this.pEditUp.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.selectedQuestion.id });
                break;
        }
    }

    editSubquestion(subItem: any) {
        switch (subItem.idTestQuestionType) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup(subItem);
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup(subItem);
                break;
            case TestQuestionType.MW:
                this.pEditMW.showPopup(subItem);
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup(subItem);
                break;
            case TestQuestionType.ES:
                this.pEditES.showPopup(subItem);
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup(subItem);
                break;
            case TestQuestionType.UP:
                this.pEditUp.showPopup(subItem);
                break;
        }
    }

    deleteItem(item: any) {
        this.isLoading = false;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._courseTestQuestionService.deleteById(item.idCourse, item.id)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }
    deleteAll() {
        this.isLoading = false;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._courseTestQuestionService.deleteAllByIdCourseItem(this.idCourse, this.idCourseItem)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    onImport() {
        this.pImport.showPopup({ idCourse: this.idCourse, idCourseItem: this.idCourseItem });
    }

}
