export enum RootCategory {
    Dm_LopHocPhan = 1,
    Dm_TinTuc = 2,
}


export enum TestQuestionType {
    SC = 1,
    MC = 2,
    MW = 3,
    ES = 4,
    SB = 5,
    SQ = 6,
    MA = 7,
    UP = 8,
}

export enum TestQuestionTypeNew {
    MC = 1,
    CL = 2,
    GF = 3,
    SA = 4,
    SB = 5,
    SQ = 6,
    MA = 7,
    UP = 8,
    TF = 9,
    TFNG = 10,
    YN = 11,
    SP = 12,
}

export enum CourseRegisterType {
    KhongChoDangKy = 1,
    DangKyTuDo = 2,
}
