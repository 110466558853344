import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InteractiveVideoTestQuestionService extends BaseService {
    constructor(http: HttpClient, injector: Injector, private _exportService: ExportService) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/interactivevideotestquestion`);
    }

    getsByIdInteractiveVideoTest(idInteractiveVideoTest: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdInteractiveVideoTest/${idInteractiveVideoTest}`;
        return this.defaultGet(apiUrl);
    }

    getsForExamByIdInteractiveVideoTest(idInteractiveVideoTest: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsForExamByIdInteractiveVideoTest/${idInteractiveVideoTest}`;
        return this.defaultGet(apiUrl);
    }

    deleteById(id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteById/${id}`;
        return this._http.delete<ResponseResult>(url).toPromise();
    }
    deleteAllByIdInteractiveVideoTest(idInteractiveVideoTest: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteAllByIdInteractiveVideoTest/${idInteractiveVideoTest}`;
        return this._http.delete<ResponseResult>(url).toPromise();
    }

    importFromWord(idInteractiveVideoTest: number, item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/ImportFromWord/${idInteractiveVideoTest}`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getTemplateWord() {
        const url = `${this.serviceUri}/getTemplateWord`;
        return this._http
            .post(`${url}`, null, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .subscribe(res => {
                this._exportService.saveAsWordFile(res, 'questions_import');
            });
    }

    finishExam(idInteractiveVideoTest: number, dsQuestions: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/FinishExam/${idInteractiveVideoTest}`;
        return this._http.post<ResponseResult>(url, dsQuestions)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
