import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { SecondPageEditBase, ExportService } from 'sv-shared';
import { CourseTestQuestionService } from '../../../../services/course-test-question.service';

@Component({
    selector: 'app-course-test-question-import',
    templateUrl: './course-test-question-import.component.html',
    styleUrls: ['./course-test-question-import.component.scss']
})
export class CourseTestQuestionImportComponent extends SecondPageEditBase implements OnInit {

    @ViewChild('fileControl') fileControl: FileUpload;
    rawFileName = '';

    isUploading = false;
    idCourse = 0;
    idCourseItem = 0;
    constructor(
        protected _testQuestionService: CourseTestQuestionService,
        protected _injector: Injector,
        protected _exportService: ExportService,

    ) {
        super(_testQuestionService, _injector);
    }

    ngOnInit() {
    }

    myUploader(event) {
        this.submitting = true;
        const files = event.files;
        if (files.length > 0) {
            this.isUploading = true;
            this.rawFileName = files[0].name;
            const formData: FormData = new FormData();
            formData.append(this.rawFileName, files[0]);
            this._testQuestionService.importFromWord(this.idCourse, this.idCourseItem, formData).then(rs => {
                if (rs.success) {
                    this._notifierService.showInsertDataSuccess();
                    this.closePopupMethod(true);
                } else {
                    this._notifierService.showHttpUnknowError();
                }
                this.submitting = false;
                this.isUploading = false;
            }).catch(err => {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
                this._notifierService.showWarning('Có lỗi xảy ra, vui lòng thử lại ');
                this.submitting = false;
                this.isUploading = false;
            });
        }
    }
    onShowPopup(data: { idCourse: 0, idCourseItem: 0 }) {
        this.idCourse = data.idCourse;
        this.idCourseItem = data.idCourseItem;
    }
    onUpload() {
        this.fileControl.upload();
    }
    downloadForm() {
        this._testQuestionService.getTemplateWord();
    }
}
