import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { SecondPageIndexBase } from '../../../../lib/classes/base/second-page-index-base';

@Component({
    selector: 'app-fssystem-modal',
    templateUrl: './fssystem-modal.component.html',
    styleUrls: ['./fssystem-modal.component.scss']
})
export class FssystemModalComponent extends SecondPageIndexBase implements OnInit {

    @Output() closePopup = new EventEmitter<any>();
    isShow = false;
    constructor(
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
    }

    onPopupClosed(data) {
        console.log(data);
        this.closePopup.next(data);
        this.isShow = false;
    }

    onShowPopup(data: any) {
        this.isShow = true;
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }

}
