<p-dialog #dialog header="{{ title }}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '1300px'}" closeIcon="bx bx-x" maximizeIcon="bx bx-expand" minimizeIcon="bx bx-collapse"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="idCourseItemType">Phân loại</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input sv-dropdown" [options]="arrCourseItemType"
                                [autoDisplayFirst]="false" dropdownIcon="bx bx-chevron-down" appendTo="body"
                                [(ngModel)]="itemDetail.idCourseItemType" formControlName="idCourseItemType"
                                [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="itemDetail.idCourseItemType != 1">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="idCourseItemParent">Chọn nhóm</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input sv-dropdown" [options]="arrGroup"
                                [autoDisplayFirst]="false" formControlName="idCourseItemParent"
                                dropdownIcon="bx bx-chevron-down" appendTo="body"
                                [(ngModel)]="itemDetail.idCourseItemParent" [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="title">Tiêu đề</div>
                        <div class="sv-form-item__input-wrapper  -type-text-field">
                            <input type="text" class="sv-form-item__input sv-text-field" [(ngModel)]="itemDetail.title"
                                formControlName="title">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12"
                    *ngIf="itemDetail.idCourseItemType != 1 && itemDetail.idCourseItemType != 13">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="fileViewUrl">Url Học liệu / nhiệm vụ</div>
                        <div class="sv-form-item__input-wrapper">
                            <input type="text" class="sv-form-item__input sv-text-field"
                                [(ngModel)]="itemDetail.fileViewUrl" formControlName="fileViewUrl">
                            &nbsp;
                            <a class="btn btn-light" id="upload_link" (click)="showFileExplorer(1)"
                                pTooltip="Chọn học liệu">
                                <i class="bx bx-file-find" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="itemDetail.idCourseItemType == 13">
                    <div class="sv-form-item required">
                        <div class="sv-form-item__label" for="idItemData">Chọn video tương tác</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input sv-dropdown" [options]="dsInteractiveVideo"
                                [autoDisplayFirst]="false" formControlName="idItemData"
                                dropdownIcon="bx bx-chevron-down" appendTo="body" [(ngModel)]="itemDetail.idItemData"
                                [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="ten">Thứ tự hiển thị</div>
                        <div class="sv-form-item__input-wrapper">
                            <input type="number" class="sv-form-item__input sv-text-field"
                                [(ngModel)]="itemDetail.orderNo" formControlName="orderNo">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="(itemDetail.idCourseItemType-0) != 1">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="endTime">Thời gian bắt đầu</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-calendar dateFormat="dd/mm/yy" appendTo="body" class="sv-form-item__input sv-datepicker"
                                formControlName="startTime" [minDate]="minDateValue" [(ngModel)]="itemDetail.startTime"
                                showTime="true" hourFormat="24">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="(itemDetail.idCourseItemType-0) >= 20">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="endTime">Thời gian kết thúc</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-calendar dateFormat="dd/mm/yy" appendTo="body" class="sv-form-item__input sv-datepicker"
                                formControlName="endTime" [minDate]="minDateValue" [(ngModel)]="itemDetail.endTime"
                                showTime="true" hourFormat="24">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12"
                    *ngIf="itemDetail.idCourseItemType != 1 && itemDetail.idCourseItemType < 20">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="contents">Tài liệu đính kèm</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-table [value]="dsAttactment" class="sv-table" [lazy]="true">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th colspan="3">
                                            <button type="button" pTooltip="Thêm tài liệu đính kèm"
                                                class="sv-btn icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--primary"
                                                (click)="showFileExplorer(2)">
                                                <span class="__icon ci ci-attachment"></span>
                                            </button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="width: 80px;">
                                            STT
                                        </th>
                                        <th>
                                            <div class="overflow-break-word">Tài liệu
                                            </div>
                                        </th>
                                        <th style="width: 80px;">
                                            <div class="overflow-break-word">Xóa</div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                    <tr>
                                        <td style="width: 80px;">{{rowIndex + 1}}</td>
                                        <td>
                                            <div class="overflow-break-word" innerHTML="{{rowData | fileName}}"></div>
                                        </td>
                                        <td style="width: 80px;">
                                            <button type="button" (click)="deleteAttactment(rowData)"
                                                class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--red-60 focus-style-1">
                                                <span class="__icon ci ci-delete"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="itemDetail.idCourseItemType != 1">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="contents">Mô tả</div>
                        <div class="sv-form-item__input-wrapper">
                            <ck-editor name="editor1" style="width: 100%" [(ngModel)]="itemDetail.contents"
                                formControlName="contents">
                            </ck-editor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12" *ngIf="(itemDetail.idCourseItemType-0) >= 20">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="haveExtraTime">Cho phép nộp quá hạn</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-checkbox [(ngModel)]="value" [binary]="true" [(ngModel)]="itemDetail.haveExtraTime"
                                formControlName="haveExtraTime">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="(itemDetail.idCourseItemType-0) >= 20">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="endTime">Thời gian nộp quá hạn</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-calendar dateFormat="dd/mm/yy" appendTo="body" class="sv-form-item__input sv-datepicker"
                                formControlName="extraTime" [(ngModel)]="itemDetail.extraTime" showTime="true"
                                hourFormat="24">
                            </p-calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12"
                    *ngIf="(itemDetail.idCourseItemType-0) != 1 && (itemDetail.idCourseItemType-0) != 22">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="totalTime">Thời gian học / làm bài (phút)</div>
                        <div class="sv-form-item__input-wrapper">
                            <input type="number" class="sv-form-item__input sv-text-field"
                                [(ngModel)]="itemDetail.totalTime" formControlName="totalTime">
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="status">Trạng thái</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input sv-dropdown" formControlName="status"
                                [options]="arrStatus" [autoDisplayFirst]="false" dropdownIcon="bx bx-chevron-down"
                                appendTo="body" [(ngModel)]="itemDetail.status" [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="deleteItem()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1 ">
                    <span class="__icon ci ci-delete sv-color--red-60"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.DELETE' | translate}}</span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="__icon ci ci-save"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<app-fssystem-modal #pFileEpl (closePopup)="onFileExplorerClosed($event)">
</app-fssystem-modal>
