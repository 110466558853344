import { Component, OnInit, Injector } from '@angular/core';
import { SecondPageEditBase } from 'sv-shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CourseItemLiveService } from '../../../../services/course-item-live.service';

@Component({
    selector: 'app-course-item-live',
    templateUrl: './course-item-live.component.html',
    styleUrls: ['./course-item-live.component.scss']
})
export class CourseItemLiveComponent extends SecondPageEditBase implements OnInit {
    dsRecurrenceBy = [
        { label: 'Hàng ngày', value: 1 },
        { label: 'Hàng tuần', value: 2 }
    ];
    title = 'Thêm mới buổi học trực tuyến';
    recurrenceValue = [];
    minDateValue = new Date();
    constructor(
        protected _courseitemliveService: CourseItemLiveService,
        protected _injector: Injector,
    ) {
        super(_courseitemliveService, _injector);

        this.formGroup = new FormGroup({
            title: new FormControl('', Validators.required),
            startTime: new FormControl('', Validators.required),
            endTime: new FormControl(''),
            duration: new FormControl(''),
            recurrenceType: new FormControl(''),
            recurrenceBy: new FormControl(''),
            group12: new FormControl(''),
            group13: new FormControl(''),
            group14: new FormControl(''),
            group15: new FormControl(''),
            group16: new FormControl(''),
            group17: new FormControl(''),
            group18: new FormControl(''),
        });
    }

    async ngOnInit() {
    }

    async onShowPopup(data: { id: 0, idCourse: 0 }) {
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (data.id > 0) {
            this.title = "Cập nhật thông tin buổi học trực tuyến";
            this._courseitemliveService.getDetail(data.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.startTime) {
                        this.itemDetail.startTime = new Date(this.itemDetail.startTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idCourse: data.idCourse };
            this.submitting = false;
        }
    }

    onBeforeSave() {
        this.itemDetail.recurrenceValue = this.recurrenceValue.join(",");
        return true;
    }
    delete() {
        this._notifierService.showDeleteConfirm().then(() => {
            this._courseitemliveService.deleteItem(this.itemDetail.idCourse, this.itemDetail.id)
                .then(() => {
                    this._notifierService.showDeleteDataSuccess();
                    this.closePopupMethod(true);
                }, () => {
                    this._notifierService.showDeleteDataError();
                });
        });
    }
}

