import { BaseModel } from './base';

export class CourseItemModel extends BaseModel {
    idCourse: number;
    idCourseItemType: number;
    idCourseItemParent: number;
    contents: string;
    fileViewUrl: string;
    idItemData: number;
    orderNo: number;
    totalTime: number;
    isDemo: boolean;
    startTime: Date;
    endTime: Date;
    dsAttactment: [];
    haveExtraTime: boolean;
    extraTime: Date;
    approvalMark: boolean;
    countQuestion: number;
    totalMark: number;
    idSvSubject: number;
}
