import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseItemLiveService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitemlive`);
    }

    getsByIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    deleteItem(idCourse: number, id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteItem/${idCourse}/${id}`;
        return this._http.delete<ResponseResult>(url).toPromise();
    }

    deleteManyItem(lstId: string): Promise<ResponseResult> {
        const url = `${this.serviceUri}/DeleteManyItem/${lstId}`;
        return this._http.delete<ResponseResult>(url).toPromise();
    }

    joinLiveClass(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/JoinLiveClass/${idCourse}`;
        return this.defaultGet(apiUrl);
    }
}
