import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { CanonicalService, UserService } from 'sv-shared';
import { authConfig } from './config/oidc-auth.config';
declare let $: any;
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { SvSchoolYearService } from './services/sv-school-year.service';
import { Spinkit } from 'ng-http-loader';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    public spinkit = Spinkit;
    constructor(
        private router: Router,
        private translate: TranslateService,
        private _oauthService: OAuthService,
        private _userService: UserService,
        private _svSchoolYearService: SvSchoolYearService,
        private metaTagService: Meta,
        private titleTagService: Title,
        private canonicalService: CanonicalService,
        private _activatedRoute: ActivatedRoute,
    ) {
        this.translate.setDefaultLang('vi');
        this.translate.use('vi');
    }

    ngOnInit() {

        this.metaTagService.addTags([
            { charset: 'utf-8' },
            { name: 'og:title', content: environment.appMetadata.meta.title },
            { name: 'og:description', content: environment.appMetadata.meta.description },
            { name: 'og:url', content: environment.appMetadata.meta.url },
            { name: 'og:site_name', content: environment.appMetadata.meta.title },
        ]);
        this.canonicalService.setCanonicalURL();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
            const rt = this.getChild(this._activatedRoute);
            rt.data.subscribe(data => {
                console.log(data);
                this.titleTagService.setTitle(`${environment.appMetadata.meta.title} | ${data.title ?? ''}`)
            });
        });

        this.recallJsFuntions();
        this.configureWithNewConfigApi();

        this._svSchoolYearService.GetSchoolYear().then(rs => {
            var currYear = rs;
        })
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    private configureWithNewConfigApi() {
        this._oauthService.setStorage(localStorage);
        this._oauthService.configure(authConfig);
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
        if (this._oauthService.hasValidAccessToken()) {
            this._oauthService.tryLogin().then(rs => {
                this._oauthService.events.subscribe((event: OAuthInfoEvent) => {
                    if (event.type === 'token_expires') {
                        this._oauthService.silentRefresh();
                    }
                });
            });
            this._userService.returnPromises();
        }
    }
}
