import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FeedbackService } from '../../../services/feedback.service';

@Component({
    selector: 'app-mlearning-testimonials',
    templateUrl: './mlearning-testimonials.component.html',
    styleUrls: ['./mlearning-testimonials.component.scss']
})
export class MlearningTestimonialsComponent implements OnInit {

    testimonialsSlidesTwo: OwlOptions = {
        items: 1,
        nav: true,
        loop: true,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ]
    }

    dataSource = [];

    constructor(
        private _feedbackService: FeedbackService,
    ) { }

    ngOnInit() {
        this.getData();
    }
    getData() {
        this._feedbackService.getsAll().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }
}
