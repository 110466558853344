<div class="row" *ngIf="itemLiveIscomming.id > 0">
    <div class="col-md-12">
        <h2 class="invoice_title">Sắp diễn ra</h2>
    </div>
    <div class="col-md-9">
        <div class="vhls140">
            <h4>{{itemLiveIscomming.title}}</h4>
            <ul>
                <li>
                    <div class="vdt-list">
                        <span>Bắt đầu lúc:</span>{{itemLiveIscomming.startTime | date: 'dd/MM/yyyy hh:MM' }}
                    </div>
                </li>
                <li>
                    <div class="vdt-list">
                        <span>Kéo dài trong:</span>{{itemLiveIscomming.duration }}- {{'phút' | translate}})
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-3">
        <div class="vhls140">
            <ul>
                <li>
                    <div class="vdt-list">
                        <button type="button" class="default-btn"
                            *ngIf="itemLiveIscomming.statusLearning == 2 && isTeacher != true && itemLiveIscomming.attendeePW != null"
                            (click)="goLiveClass()">
                            <i class="ci ci-chevron-right"></i>
                            {{'Vào lớp' | translate}}
                        </button>

                        <button class="default-btn " disabled
                            *ngIf="itemLiveIscomming.statusLearning == 2 && isTeacher != true && itemLiveIscomming.attendeePW == null">
                            Giáo viên chưa vào lớp
                        </button>

                        <button type="button" class="default-btn " *ngIf="isTeacher == true" (click)="goLiveClass()">
                            {{'Vào lớp' | translate}}
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="invoice_table">
        <div class="table-responsive-md">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col" style="width: 5%;">STT</th>
                        <th scope="col" style="width:50%">Tiêu đề</th>
                        <th scope="col">Thời gian Bắt đầu</th>
                        <th scope="col">Kéo dài (phút)</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of dataSource; index as i">
                        <tr [class.finished]="item.statusLearning == 1">
                            <td>
                                <div class="user_dt_trans">
                                    {{ (pageIndex - 1) * pageSize + (i + 1)}}
                                </div>
                            </td>
                            <td>
                                <div class="user_dt_trans">
                                    <p>{{item.title}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="user_dt_trans">
                                    <p>{{item.startTime | date: 'dd/MM/yyyy hh:MM'}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="user_dt_trans">
                                    <p>
                                        {{item.duration}}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>