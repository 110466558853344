<aside class="widget-area">
    <div class="widget widget_search">
        <h3 class="widget-title">{{'Tìm kiếm' | translate}}</h3>
        <form class="search-form">
            <label>
                <span class="screen-reader-text">{{'Tìm kiếm' | translate}}</span>
                <input type="search" class="search-field" placeholder="Từ khóa..." [(ngModel)]="query" name="query"
                    (keyup.enter)="onSearch()">
            </label>
            <button type="submit" (click)="onSearch()"><i class="bx bx-search-alt"></i></button>
        </form>
    </div>
    <div class="widget widget_odemy_posts_thumb">
        <h3 class="widget-title">{{'Thông tin phổ biến' | translate}}</h3>
        <ng-container *ngFor="let item of dataTop">
            <article class="item">
                <a routerLink="/news-detail/{{item.id}}" class="thumb">
                    <span class="fullimage cover " role="img" style="background-image: url({{item.imageUrl}});"></span>
                </a>
                <div class="info">
                    <span>{{item.publishedDate | date : 'dd/MM/yyyy'}}</span>
                    <h4 class="title usmall"><a routerLink="/news-detail/{{item.id}}">
                            {{item.title}}
                        </a></h4>
                </div>
                <div class="clear"></div>
            </article>
        </ng-container>
    </div>
    <div class="widget widget_categories">
        <h3 class="widget-title">{{'Chuyên mục' | translate}}</h3>
        <ul>
            <li *ngFor="let item of dsCategory"><a (click)="goCategory(item.value)">{{item.label}}</a></li>

        </ul>
    </div>
    <!-- <div class="widget widget_tag_cloud">
        <h3 class="widget-title">Popular Tags</h3>
        <div class="tagcloud">
            <a routerLink="/blog-4">Business <span class="tag-link-count"> (3)</span></a>
            <a routerLink="/blog-4">Design <span class="tag-link-count"> (3)</span></a>
            <a routerLink="/blog-4">Braike <span class="tag-link-count"> (2)</span></a>
            <a routerLink="/blog-4">Fashion <span class="tag-link-count"> (2)</span></a>
            <a routerLink="/blog-4">Travel <span class="tag-link-count"> (1)</span></a>
            <a routerLink="/blog-4">Smart <span class="tag-link-count"> (1)</span></a>
            <a routerLink="/blog-4">Marketing <span class="tag-link-count"> (1)</span></a>
            <a routerLink="/blog-4">Tips <span class="tag-link-count"> (2)</span></a>
        </div>
    </div> -->
</aside>
