import { SecondPageEditBase } from '../../../../classes/base/second-page-edit-base';
import { FsFolderUserService } from '../../services/fsFolderUser.service';
import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-fssystem-private-folder-form',
    templateUrl: './fssystem-private-folder-form.component.html',
    styleUrls: ['./fssystem-private-folder-form.component.scss']
})
export class FssystemPrivateFolderFormComponent extends SecondPageEditBase implements OnInit {

    constructor(
        protected _fsFolderUserService: FsFolderUserService,
        protected _injector: Injector
    ) {
        super(_fsFolderUserService, _injector);

        this.formGroup = new FormGroup({
            title: new FormControl('', Validators.compose([Validators.required]))
        });
    }

    ngOnInit() {

    }

    async onShowPopup(data: any) {
        this.validationSummary.resetErrorMessages();
        if (data.title != '') {
            this.itemDetail = data;
            this.itemDetail.id = 1;
        } else {
            this.itemDetail.id = 0;
            this.itemDetail.physicalPathParent = data.physicalPathParent;
        }
    }

    onInsert() {
        this._fsFolderUserService.createMyFolder(this.itemDetail)
            .then(response => {
                if (this.continueAdding) {
                    this.resetForm();
                } else {
                    this.closePopupMethod(true);
                }

                this._notifierService.showInsertDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, error => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._fsFolderUserService.updateMyFolder(this.itemDetail)
            .then(response => {
                this.closePopupMethod(true);
                this._notifierService.showUpdateDataSuccess();
                this.submitting = false;
            }, error => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    resetForm() {
        this.itemDetail.title = '';
    }
}

