import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { SvSchoolYear } from '../models/sv-school-year';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SvSchoolYearService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/svschoolyear`);
    }

    setActive(id: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/SetActive/${id}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetActive(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetActive`;
        return this.defaultGet(apiUrl);
    }

    async GetSchoolYear(): Promise<SvSchoolYear> {
        const storageItem = localStorage.getItem("SvSchoolYear.Active");
        if (storageItem) {
            const result = JSON.parse(storageItem) as SvSchoolYear;
            return result
        } else {
            await this.GetActive().then(rs => {
                if (rs.success) {
                    localStorage.setItem("SvSchoolYear.Active", JSON.stringify(rs.data));
                    return rs.data;
                } else {
                    return null;
                }
            });
        }
    }
}
