import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseUserLog_TestDetailService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseuserlog_testdetail`);
    }

    getsForExam(idCourseUserLog: number, idCourseItem: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsForExam/${idCourseUserLog}/${idCourseItem}`;
        return this.defaultGet(apiUrl);
    }

    updateLog(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateLog`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsForTeacher(idCourse: number, idCourseUserLog: number, idCourseItem: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsForTeacher/${idCourse}/${idCourseUserLog}/${idCourseItem}`;
        return this.defaultGet(apiUrl);
    }

    updateTotalMark(idCourse: number, idCourseUserLog: number, dsQuestions: any[]): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateTotalMark/${idCourse}/${idCourseUserLog}`;
        return this._http
            .post<ResponseResult>(apiUrl, dsQuestions)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
