import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageIndexBase } from 'sv-shared';
import { TestQuestionType } from '../../../../config/enums';
import { InteractiveVideoTestQuestionService } from '../../../../services/interactive-video-test-question.service';
import { InteractiveVideoTestQuestionImportComponent } from './interactive-video-test-question-import/interactive-video-test-question-import.component';
import { InteractiveVideoTestQuestionMaComponent } from './interactive-video-test-question-ma/interactive-video-test-question-ma.component';
import { InteractiveVideoTestQuestionMcComponent } from './interactive-video-test-question-mc/interactive-video-test-question-mc.component';
import { InteractiveVideoTestQuestionMwComponent } from './interactive-video-test-question-mw/interactive-video-test-question-mw.component';
import { InteractiveVideoTestQuestionScComponent } from './interactive-video-test-question-sc/interactive-video-test-question-sc.component';
import { InteractiveVideoTestQuestionSqComponent } from './interactive-video-test-question-sq/interactive-video-test-question-sq.component';

@Component({
    selector: 'app-interactive-video-test-question',
    templateUrl: './interactive-video-test-question.component.html',
    styleUrls: ['./interactive-video-test-question.component.scss']
})
export class InteractiveVideoTestQuestionComponent extends SecondPageIndexBase implements OnInit {
    idInteractiveVideo = 0;
    idInteractiveVideoTest = 0;
    testQuestionType = TestQuestionType;
    @ViewChild('pEditSc') pEditSc: InteractiveVideoTestQuestionScComponent;
    @ViewChild('pEditMc') pEditMc: InteractiveVideoTestQuestionMcComponent;
    @ViewChild('pEditMw') pEditMw: InteractiveVideoTestQuestionMwComponent;
    @ViewChild('pEditSQ') pEditSQ: InteractiveVideoTestQuestionSqComponent;
    @ViewChild('pEditMA') pEditMA: InteractiveVideoTestQuestionMaComponent
    @ViewChild('pImport') pImport: InteractiveVideoTestQuestionImportComponent;
    constructor(
        protected _interactivevideotestquestionService: InteractiveVideoTestQuestionService,
        protected _injector: Injector,
    ) {
        super(_interactivevideotestquestionService, _injector);
    }

    async ngOnInit() {

        this.cols = [
            { field: 'questionText', header: this._translateService.instant('TestQuestion_QuestionText'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA_MODIFIED'), visible: false, width: '120px', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA_MODIFIED_BY'), visible: false, width: '120px' },
        ];
        await this._activatedRoute.params
            .pipe(map(params => params['idInteractiveVideo']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idInteractiveVideo = pid;
            });
        await this._activatedRoute.params
            .pipe(map(params => params['idInteractiveVideoTest']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idInteractiveVideoTest = pid;
            });
        await this.getData();
    }

    async getData() {
        this._interactivevideotestquestionService.getsByIdInteractiveVideoTest(this.idInteractiveVideoTest).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    deleteItem(item: any) {
        this.isLoading = false;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._interactivevideotestquestionService.deleteById(item.id)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }
    deleteAll() {
        this.isLoading = false;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._interactivevideotestquestionService.deleteAllByIdInteractiveVideoTest(this.idInteractiveVideoTest)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    goBack() {
        this._router.navigate([`/teacher/interactive-video`]);
    }

    addQuestion(type: number) {
        switch (type) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup({ id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: null });
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup({ id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: null });
                break;
            case TestQuestionType.MW:
                this.pEditMw.showPopup({ id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: null });
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup({ id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: null });
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup({ id: 0, idInteractiveVideoTest: this.idInteractiveVideoTest, idQuestionParent: null });
                break;
            // case TestQuestionType.ES:
            //     this.pEditES.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
            //     break;
            // case TestQuestionType.SB:
            //     this.pEditSB.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
            //     break;
            // case TestQuestionType.UP:
            //     this.pEditUp.showPopup({ id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: null });
            //     break;


        }
    }

    editItem(item: any) {
        switch (item.idTestQuestionType) {
            case TestQuestionType.SC:
                this.pEditSc.showPopup(item);
                break;
            case TestQuestionType.MC:
                this.pEditMc.showPopup(item);
                break;
            case TestQuestionType.MW:
                this.pEditMw.showPopup(item);
                break;
            case TestQuestionType.SQ:
                this.pEditSQ.showPopup(item);
                break;
            case TestQuestionType.MA:
                this.pEditMA.showPopup(item);
                break;
            // case TestQuestionType.ES:
            //     this.pEditES.showPopup(item);
            //     break;
            // case TestQuestionType.SB:
            //     this.pEditSB.showPopup(item);
            //     break;
            // case TestQuestionType.UP:
            //     this.pEditUp.showPopup(item);
            //     break;


        }
    }

    onImport() {
        this.pImport.showPopup({ idInteractiveVideoTest: this.idInteractiveVideoTest });
    }

}
