<div class="page">
    <!-- sidebar-->
    <section class="sidebar" [class.is-collapse]="isCollapse" [class.is-show]="isShow">
        <a class="sidebar__toggle js-sidebar-collapse" [class.active]="isCollapse"
            (click)="isCollapse = !isCollapse"></a>
        <div class="sidebar__header">
            <div class="row g-3 align-items-center">
                <div class="col-auto d-flex d-xl-none">
                    <button class="btn-toggle text-white js-sidebar-toggle" [class.active]="isShow" type="button"
                        (click)="isShow = !isShow"></button>
                </div>
                <div class="col d-flex">
                    <a class="sidebar__logo">
                        <img class="sidebar__logo-img" src="assets/images/logo-white.png" alt="">
                        <img class="sidebar__logo-collapse" src="assets/images/logo-coolapse.png" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="sidebar__body custom-scrollbar">
            <div class="sb-info">
                <div class="sb-info__avatar ratio ratio-1x1"><img src="assets/images/avatar-default.png" alt=""></div>
                <div class="sb-info__name">{{currentUser.displayName}}</div>
            </div>
            <ul class="menu">
                <li class="menu-item">
                    <a class="menu-link" (click)="goTab(6)">
                        <i class="fal fa-fw fa-th-large"></i>
                        <span>
                            Thông tin khóa học
                        </span>
                    </a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" (click)="goTab(1)">
                        <i class="fal fa-fw fa-desktop-alt"></i>
                        <span>
                            Nội dung khóa học
                        </span>
                    </a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" (click)="goTab(5)">
                        <i class="fal fa-fw fa-video"></i>
                        <span>
                            Học trực tuyến
                        </span>
                    </a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" (click)="goTab(2)">
                        <i class="fal fa-fw fa-history"></i>
                        <span>
                            Kết quả học tập
                        </span>
                    </a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" (click)="goTab(3)">
                        <i class="fal fa-fw fa-comments-alt"></i>
                        <span>
                            Thảo luận
                        </span>
                    </a>
                </li>

            </ul>
        </div>
        <div class="sidebar__footer">
            <a class="sidebar__link">
                <i class="fal fa-fw fa-user-headset"></i>
                <span>Hỗ trợ</span>
            </a>
        </div>
    </section>
    <!-- body-->
    <div class="page__body">
        <nav class="header">
            <div class="row align-items-center g-3 g-xxl-30">
                <div class="col-auto d-flex d-xl-none">
                    <button class="btn-toggle js-sidebar-toggle" type="button" (click)="isShow = !isShow"></button>
                </div>
                <div class="col-auto d-flex d-xl-none me-auto">
                    <a class="header__logo">
                        <img src="assets/images/logo.png" alt="">
                    </a>
                </div>
                <div class="col header__breadcrumb">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item active">{{courseDetail.code}}. {{courseDetail.title}}</li>
                    </ol>
                </div>
                <div class="col-auto">
                    <div class="header__user dropdown">
                        <a [routerLink]="['/']" class="header__btn dropdown-toggle" type="button">
                            <i class="fal fa-times-circle"></i>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <ng-container *ngIf="tabId == 6">
            <div class="aside">
                <div class="aside__header">
                    <div class="aside__header-inner">
                        <div class="aside__title">{{'Danh sách học viên' | translate}}</div>
                    </div>
                </div>
                <div class="aside__body">
                    <div class="aside__inner custom-scrollbar">
                        <app-learning-space-users></app-learning-space-users>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="content custom-scrollbar" [class.content--full]="tabId != 6">
            <!-- Dashboard -->
            <div class="section-1" *ngIf="tabId == 6">
                <h2 class="section-1__title">{{'Nội dung mới' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <app-learning-space-forum-latest-post [idCourse]="idCourse" (goItem)="onGoItem($event)">
                    </app-learning-space-forum-latest-post>
                </div>
            </div>
            <!-- Nội dung khóa học -->
            <div class="section-1" *ngIf="tabId == 1">
                <h2 class="section-1__title">{{'Nội dung khóa học' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <div class="ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom">
                        <p-tree class="sv-tree course-item-tree" [value]="courseItems" [style]="{'width': '100%'}"
                            [emptyMessage]="'LIST.EMPTY' | translate">
                            <ng-template let-node pTemplate="default">
                                <div class="lecture-container" *ngIf="node.idCourseItemType == 1">
                                    <div class="left-content">
                                        <div class="top">
                                            <div class="title">{{node.title}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture-container cp" [class.isLearned]="node.isLearned"
                                    *ngIf="node.idCourseItemType != 1" (click)="viewCourseItem(node)">
                                    <div class="left-content">
                                        <div class="top">
                                            <div class="title">{{node.title}}</div>
                                        </div>
                                    </div>
                                    <div class="details">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <div class="hstack"><span
                                                            class="me-auto"></span><span>{{node.isLearned ? '100%' :
                                                            '0%'}}</span>
                                                    </div>
                                                    <div class="progress my-1">
                                                        <div class="progress-bar"
                                                            [style.width.%]="node.isLearned ? 100 : 0"></div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <span class="content-summary" *ngIf="node.totalTime > 0">
                                                        {{node.totalTime}} ({{'phút' | translate}})
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-tree>
                    </div>
                </div>
            </div>
            <!-- End Nội dung khóa học -->
            <!-- Lịch sử học tập -->
            <div class="section-1" *ngIf="tabId == 2">
                <h2 class="section-1__title">{{'Thông tin học tập' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <div class="col-md-6">
                        <div class="vhls140">
                            <ul>
                                <li>
                                    <div class="vdt-list"><span>Tài khoản:</span> {{courseUserInfoExt.userName}}
                                    </div>
                                </li>
                                <li>
                                    <div class="vdt-list"><span>Tổng số bài đã học:</span>
                                        {{courseUserInfoExt.totalCourseItemView}}</div>
                                </li>
                                <li>
                                    <div class="vdt-list"><span>Lần học gần nhất:</span>
                                        {{courseUserInfoExt.lastActiveTime | date : 'dd/MM/yyyy hh:MM'}}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="vhls140">
                            <ul>
                                <li>
                                    <div class="vdt-list"><span>Ngày tham gia:</span>
                                        {{courseUserInfoExt.joinTime | date : 'dd/MM/yyyy hh:MM' }}
                                    </div>
                                </li>
                                <li>
                                    <div class="vdt-list"><span>Tổng số lượt xem bài giảng:</span>
                                        {{courseUserInfoExt.totalTimeViewLesson}}</div>
                                </li>
                                <li>
                                    <div class="vdt-list"><span>Trạng thái học:</span>
                                        <b>{{courseUserInfoExt.titleLearningStatus}}</b>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <h2 class="section-1__title mt-5">{{'Kết quả học tập' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <app-learning-space-table-history [dsResult]="dsResult">
                    </app-learning-space-table-history>
                </div>
            </div>
            <!-- #End Lịch sử học tập -->
            <!-- Thảo luận -->
            <div class="section-1" *ngIf="tabId == 3">
                <h2 class="section-1__title">{{'Diễn đàn / trao đổi / thảo luận' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <app-learning-space-forum [idCourse]="idCourse" [isTeacher]="isTeacher">
                    </app-learning-space-forum>
                </div>
            </div>
            <!-- End Thảo luận -->
            <!-- Học trực tuyến -->
            <div class="section-1" *ngIf="tabId == 5">
                <h2 class="section-1__title">{{'Học trực tuyến' | translate}}</h2>
                <div class="row g-1 g-xxl-3">
                    <app-learning-space-liveclass [idCourse]="idCourse" [isTeacher]="isTeacher">
                    </app-learning-space-liveclass>
                </div>
            </div>
            <!-- End Học trực tuyến -->
            <!-- ViewCourseItem Học liệu -->
            <div class="section-1" *ngIf="tabId > 10 && tabId < 20">
                <h2 class="section-1__title">{{ courseItemDetail.title }}</h2>
                <div class="row g-1 g-xxl-3">
                    <!-- VIDEO -->
                    <div class="col-lg-12 mb-30" *ngIf="tabId == 11">

                        <div class="row justify-content-md-center">
                            <div class="video embed-responsive embed-responsive-16by9 col-md-10">
                                <video id='sxmvideo' class="video-js vjs-default-skin vjs-16-9" controls>
                                </video>
                            </div>
                        </div>


                    </div>
                    <!-- SCORM -->
                    <div class="col-lg-12 mb-30" style="min-height: 600px;" *ngIf="tabId == 14 && isViewScorm">
                        <ng-scorm-player [urlLaunchPage]="scormUrl">
                        </ng-scorm-player>
                    </div>
                    <!-- DOCUMENT -->
                    <div class="col-lg-12 mb-30" *ngIf="tabId == 12" style="width: 100%; min-height: 800px;">
                        <div id="file-viewer"></div>
                    </div>
                    <ng-container *ngIf="tabId <= 20">
                        <div class="col-lg-12 mb-30" *ngIf="courseItemDetail.contents">
                            <h4>{{'Giới thiệu' | translate}}</h4>
                            <p [innerHTML]="courseItemDetail.contents"></p>
                        </div>
                        <div class="col-lg-12" *ngIf="dsCourseItemAttactment.length > 0">
                            <h4>{{'Tài liệu tham khảo' | translate}}</h4>
                            <ul class="list-disc">
                                <li *ngFor="let itemAt of dsCourseItemAttactment;  index as i">
                                    <a href="{{itemAt}}" target="_blank">
                                        {{i + 1}}.&nbsp; {{itemAt | fileName}}</a>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- End ViewCourseItem -->
            <!-- ViewCourseItem Nhiệm vụ học tập -->
            <div class="section-1" *ngIf="tabId > 20">
                <h2 class="section-1__title">{{ courseItemDetail.title }}</h2>
                <div class="row g-1 g-xxl-3">
                    <ng-container *ngIf="tabId == 22">
                        <div class="col-lg-12 mb-30">
                            <ul class="list-disc">
                                <li>Hạn nộp: {{courseItemDetail.startTime | date : 'dd/MM/yyyy hh:MM'}}
                                    -
                                    {{courseItemDetail.endTime | date : 'dd/MM/yyyy hh:MM'}}
                                </li>
                                <li *ngIf="courseItemDetail.haveExtraTime">Gia hạn nộp đến :
                                    {{courseItemDetail.extraTime | date : 'dd/MM/yyyy hh:MM'}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-12 mb-30">
                            <p [innerHTML]="courseItemDetail.contents"></p>
                        </div>
                        <div class="col-lg-12 mb-30" *ngIf="courseUserLogItem.id > 0">
                            <h3 class="section-1__subtitle">
                                {{ 'Bài đã nộp' | translate }}
                            </h3>
                            <div class="col-lg-12">
                                <div class="sv-form-item">
                                    <div class="sv-form-item__label">{{'Nội dung' |
                                        translate}}
                                    </div>
                                    <div class="sv-form-item__input-wrapper">
                                        <p [innerHTML]="courseUserLogItemView.answerText"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="sv-form-item">
                                    <div class="sv-form-item__label">{{'File đính kèm' |
                                        translate}}
                                    </div>
                                    <div class="sv-form-item__input-wrapper">
                                        <ul>
                                            <li *ngFor="let item of courseUserLogItemView.courseUserLog_Attactment">
                                                <a href="{{item.fileViewUrl}}" target="_blank">
                                                    <i class="fa fa-file-archive-o"></i> &nbsp;
                                                    {{item.title}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-30"
                            *ngIf="showCanDoTask && !isTeacher && courseDetail.idCategoryLearningStatus== categoryLearningStatus.IsLearing">
                            <h3 class="section-1__subtitle">
                                {{ 'Nộp bài' | translate }}
                            </h3>
                            <div class="ui-g -large-gutter -row sv-form-row">
                                <div class="col-lg-12">
                                    <div class="sv-form-item">
                                        <div class="sv-form-item__label">
                                            {{'Nội dung' | translate}}
                                        </div>
                                        <div class="sv-form-item__input-wrapper -type-text-field">
                                            <p-editor [(ngModel)]="courseUserLogItem.answerText"
                                                [style]="{'height':'150px', 'width': '100%'}">
                                            </p-editor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g -large-gutter -row sv-form-row">
                                <div class="col-lg-12">
                                    <div class="sv-form-item">
                                        <div class="sv-form-item__label">
                                            File đính kèm đã tải lên
                                        </div>
                                        <div class="sv-form-item__input-wrapper -type-text-field">
                                            <ul>
                                                <li *ngFor="let item of uploadedFiles">
                                                    <a href="{{item.fileViewUrl}}" target="_blank">
                                                        <i class="fa fa-file-archive-o"></i> &nbsp;
                                                        {{item.title}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g -large-gutter -row sv-form-row">
                                <div class="col-lg-12">
                                    <div class="sv-form-item">
                                        <div class="sv-form-item__label">
                                            Chọn file
                                        </div>
                                        <div class="sv-form-item__input-wrapper -type-text-field">
                                            <p-fileUpload mode="advanced" url="{{apiUploadUrl}}"
                                                [chooseLabel]="'Chọn nhiều file'" [uploadLabel]="'Tải file'"
                                                [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="true"
                                                (onUpload)="onUpload($event)"
                                                accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip"
                                                maxFileSize="1000000000">
                                            </p-fileUpload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g -large-gutter -row sv-form-row">
                                <div class="rpt100">
                                    <button class="btn_adcart"
                                        *ngIf="courseDetail.idCategoryLearningStatus== categoryLearningStatus.IsLearing && !isTeacher && courseUserLogItem.id == 0"
                                        (click)="onSendAssignment()">
                                        Nộp bài
                                    </button>
                                    <button class="btn_adcart"
                                        *ngIf="courseDetail.idCategoryLearningStatus== categoryLearningStatus.IsLearing && !isTeacher && courseUserLogItem.id > 0"
                                        (click)="onSendAssignment()">
                                        Nộp lại bài
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tabId == 21">
                        <div class="row justify-content-lg-center justify-content-md-center" *ngIf="!isDoingExam">
                            <div class="certi_form" *ngIf="courseUserLogItem.id == 0">
                                <div class="sign_form">
                                    <h3>{{ courseItemDetail.title }}</h3>
                                    <dv class="panel panel-default">
                                        <div class="panel-title10">
                                            <a>Thời gian bắt đầu:
                                                {{courseItemDetail.startTime | date:'dd/MM/yyyy HH:mm'}}</a>
                                            <a>Thời gian kết thúc:
                                                {{courseItemDetail.endTime | date:'dd/MM/yyyy HH:mm'}}</a>
                                        </div>
                                        <div class="panel-title10">
                                            <a>Thời gian làm bài: {{courseItemDetail.totalTime}} phút</a>
                                        </div>
                                    </dv>
                                    <button class="default-btn" type="submit"
                                        *ngIf="showCanDoTask && !isTeacher && courseUserLogItem.id == 0"
                                        (click)="doExam()">
                                        <i class="ci ci-arrow-right"></i>
                                        Bắt đầu làm bài
                                    </button>
                                    <button class="default-btn" type="submit" *ngIf="!showCanDoTask">
                                        <i class="ci ci-close"></i>
                                        Chưa đến hoặc đã hết thời gian làm bài
                                    </button>
                                </div>
                            </div>
                            <div class="certi_form rght1528" *ngIf="courseUserLogItem.id > 0">
                                <div class="test_result_bg">
                                    <ul class="test_result_left">
                                        <li>
                                            <div class="result_dt">
                                                <i class="uil uil-check right_ans"></i>
                                                <p>Đúng<span>({{courseUserLogItem.numQTrue}})</span></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="result_dt">
                                                <i class="uil uil-times wrong_ans"></i>
                                                <p>Sai<span>({{courseUserLogItem.numQFalse}})</span></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="result_dt">
                                                <h4>{{courseUserLogItem.numQTrue + courseUserLogItem.numQFalse +
                                                    courseUserLogItem.numQNoAns}}</h4>
                                                <p>Không trả lời<span>({{courseUserLogItem.numQNoAns}})</span></p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="result_content">
                                        <p>
                                            Điểm: {{courseUserLogItem.finalMark}} / {{courseItemDetail.totalMark}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isDoingExam">
                            <div class="col-lg-9 col-md-9">
                                <div class="question-content">
                                    <div class="col-12 question-text"
                                        *ngIf="currentQuestion.idTestQuestionType != testQuestionType.GF">
                                        <strong> Câu {{currentQuestionIndex + 1}}. &nbsp; &nbsp;</strong>
                                        <span [innerHTML]="currentQuestion.questionText"></span>
                                    </div>
                                    <div class="answer" style="padding-bottom: 30px;">
                                        <div class="row" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MC
                                        || currentQuestion.idTestQuestionType == testQuestionType.TF
                                        || currentQuestion.idTestQuestionType == testQuestionType.TFNG
                                        || currentQuestion.idTestQuestionType == testQuestionType.YN">
                                            <div class="col-12 pd-tb0">
                                                <div class="row pd-tb0" *ngFor="let ans of currentQuestion.dsAnswers">
                                                    <div class="col-1 pd-tb0">
                                                        <p-radioButton name="{{currentQuestion.id}}" value="{{ans.id}}"
                                                            (onClick)="radioBtnClick(currentQuestion)"
                                                            [(ngModel)]="currentQuestion.answerText">
                                                        </p-radioButton>
                                                    </div>
                                                    <div class="col-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.CL">
                                            <div class="col-12 pd-tb0">
                                                <div class="row pd-tb0" *ngFor="let ans of currentQuestion.dsAnswers">
                                                    <div class="col-1 pd-tb0">
                                                        <p-checkbox name="{{ans.id}}" binary="true"
                                                            (onChange)="checkBoxChange(currentQuestion)"
                                                            [(ngModel)]="ans.isUserAnswer">
                                                        </p-checkbox>
                                                    </div>
                                                    <div class="col-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.GF">
                                            <strong> Câu {{currentQuestionIndex + 1}}. &nbsp; &nbsp;</strong>
                                            <ng-container *ngFor="let item of currentQuestion.dsAnswers; index as i">
                                                <normal [innerHTML]="currentQuestion.dsQuestionText[i]"></normal>
                                                <input type="text" class="mwText" [(ngModel)]="item.answerText"
                                                    (change)="onTextChange(currentQuestion)" />
                                            </ng-container>
                                            <normal
                                                [innerHTML]="currentQuestion.dsQuestionText[currentQuestion.dsAnswers.length]">
                                            </normal>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SA">
                                            <div class="col-12 pd-2">
                                                <p-editor [(ngModel)]="currentQuestion.answerText"
                                                    [style]="{'height':'150px'}"
                                                    (onTextChange)="onTextEditorChange(currentQuestion)">
                                                </p-editor>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SQ">
                                            <div class="col-md-6 pd-2">
                                                <div class="col-12" *ngFor="let item of currentQuestion.dsAnswers">
                                                    <div class="col-12 pd-tb0 dragdrop1" pDraggable="items"
                                                        (onDragStart)="dragStart(currentQuestion.dsAnswers, item)"
                                                        pDroppable="items"
                                                        (onDrop)="drop(currentQuestion, currentQuestion.dsAnswers, item)"
                                                        [innerHTML]="item.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MA">
                                            <div class="col-md-6 pd-2">
                                                <div class="col-12 pd-tb0 dragdrop"
                                                    *ngFor="let item of currentQuestion.dsAnswers"
                                                    [innerHTML]="item.answerText">
                                                </div>
                                            </div>
                                            <div class="col-md-6 pd-2">
                                                <div class="col-12 pd-tb0 dragdrop1"
                                                    *ngFor="let item of currentQuestion.dsMatching">
                                                    <div class="col-12" pDraggable="items"
                                                        (onDragStart)="dragStart(currentQuestion.dsMatching, item)"
                                                        pDroppable="items"
                                                        (onDrop)="drop(currentQuestion, currentQuestion.dsMatching, item)"
                                                        [innerHTML]="item.matching">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.UP">
                                            <label class="col-sm-2 col-form-label">Chọn file</label>
                                            <div class="col-sm-10">
                                                <a href="{{currentQuestion.answerFileUrl}}"
                                                    target="_blank">{{currentQuestion.answerFileUrl
                                                    | fileName}}</a>
                                                <br />
                                                <p-fileUpload mode="advanced" url="{{apiUploadUrl}}"
                                                    [chooseLabel]="'Chọn file'" [uploadLabel]="'Tải file'"
                                                    [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="false"
                                                    (onUpload)="onUploadInTesting(currentQuestion, $event)"
                                                    accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip"
                                                    maxFileSize="1000000000">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SB">
                                            <div class="row question-subs"
                                                *ngFor="let subItem of currentQuestion.dsSubQuestion; index as j">
                                                <div class="row question-text"
                                                    *ngIf="subItem.idTestQuestionType != testQuestionType.GF">
                                                    <div class="col-2" style="font-weight: 600;">
                                                        Câu {{currentQuestionIndex + 1}}.{{j+1}}.
                                                    </div>
                                                    <div class="col-10" [innerHTML]="subItem.questionText"></div>
                                                </div>
                                                <div class="question-answer">
                                                    <div class="row" *ngIf="subItem.idTestQuestionType == testQuestionType.MC
                                                        || subItem.idTestQuestionType == testQuestionType.TF
                                                        || subItem.idTestQuestionType == testQuestionType.TFNG
                                                        || subItem.idTestQuestionType == testQuestionType.YN">
                                                        <div class="row pd-tb0" *ngFor="let ans of subItem.dsAnswers">
                                                            <div class="col-1 pd-tb0">
                                                                <p-radioButton name="{{subItem.id}}" value="{{ans.id}}"
                                                                    (onClick)="radioBtnClick(subItem)"
                                                                    [(ngModel)]="+subItem.answerText">
                                                                </p-radioButton>
                                                            </div>
                                                            <div class="col-11 pd-tb0" [innerHTML]="ans.answerText">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.CL">
                                                        <div class="col-12 pd-tb0">
                                                            <div class="col-12 pd-tb0"
                                                                *ngFor="let ans of subItem.dsAnswers">
                                                                <div class="col-1 pd-tb0">
                                                                    <p-checkbox name="{{ans.id}}" binary="true"
                                                                        (onChange)="checkBoxChange(subItem)"
                                                                        [(ngModel)]="ans.isUserAnswer">
                                                                    </p-checkbox>
                                                                </div>
                                                                <div class="col-11 pd-tb0" [innerHTML]="ans.answerText">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.GF">
                                                        <div class="col-2" style="font-weight: 600;">
                                                            Câu {{currentQuestionIndex + 1}}.{{j+1}}.
                                                        </div>
                                                        <div class="col-10">
                                                            <ng-container
                                                                *ngFor="let item of subItem.dsAnswers; index as i">
                                                                <normal [innerHTML]="subItem.dsQuestionText[i]">
                                                                </normal>
                                                                <input type="text" class="mwText"
                                                                    [(ngModel)]="item.answerText"
                                                                    (change)="onTextChange(subItem)" />
                                                            </ng-container>
                                                            <normal
                                                                [innerHTML]="subItem.dsQuestionText[subItem.dsAnswers.length]">
                                                            </normal>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.SA">
                                                        <div class="col-12 pd-2">
                                                            <p-editor [(ngModel)]="subItem.answerText"
                                                                [style]="{'height':'150px'}"
                                                                (onTextChange)="onTextEditorChange(subItem)">
                                                            </p-editor>
                                                            <!-- <textarea pInputTextarea [(ngModel)]="subItem.answerText"
                                                                style="width:100%" [rows]="15" (change)="radioBtnClick(subItem)"
                                                                autoResize="true"></textarea> -->
                                                        </div>
                                                    </div>

                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.SQ">
                                                        <div class="col-md-6 pd-2">
                                                            <div class="col-12 pd-2"
                                                                *ngFor="let item of subItem.dsAnswers">
                                                                <div class="col-12 dragdrop" pDraggable="items"
                                                                    (onDragStart)="dragStart(subItem.dsAnswers, item)"
                                                                    pDroppable="items"
                                                                    (onDrop)="drop(subItem, subItem.dsAnswers, item)"
                                                                    [innerHTML]="item.answerText">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.MA">
                                                        <div class="col-md-6 pd-2">
                                                            <div class="col-12 dragdrop pd-tb0"
                                                                *ngFor="let item of subItem.dsAnswers"
                                                                [innerHTML]="item.answerText"></div>
                                                        </div>
                                                        <div class="col-md-6 pd-2">
                                                            <div class="col-12" *ngFor="let item of subItem.dsMatching">
                                                                <div class="col-12 dragdrop1 pd-tb0" pDraggable="items"
                                                                    (onDragStart)="dragStart(subItem.dsMatching, item)"
                                                                    pDroppable="items"
                                                                    (onDrop)="drop(subItem, subItem.dsMatching, item)"
                                                                    [innerHTML]="item.matching">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        *ngIf="subItem.idTestQuestionType == testQuestionType.UP">
                                                        <label class="col-sm-2 col-form-label">Chọn file</label>
                                                        <div class="col-sm-10">
                                                            <a href="{{subItem.answerFileUrl}}"
                                                                target="_blank">{{subItem.answerFileUrl
                                                                | fileName}}</a>
                                                            <br />
                                                            <p-fileUpload mode="advanced" url="{{apiUploadUrl}}"
                                                                [chooseLabel]="'Chọn file'" [uploadLabel]="'Tải file'"
                                                                [cancelLabel]="'Hủy tải lên'" name="file"
                                                                [multiple]="false"
                                                                (onUpload)="onUploadInTesting(subItem, $event)"
                                                                accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip"
                                                                maxFileSize="1000000000">
                                                            </p-fileUpload>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="control-btn-quiz">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <button class="default-btn" *ngIf="currentQuestionIndex > 0"
                                                (click)="goPrevQuestion()">
                                                <i class="ci ci-arrow-left"></i> Câu trước
                                            </button>
                                        </div>
                                        <div class="col-lg-6">
                                            <button class="default-btn" style="float: right;"
                                                *ngIf="currentQuestionIndex < totalQuestion" (click)="goNextQuestion()">
                                                <i class="ci ci-arrow-right"></i>
                                                Câu sau
                                            </button>

                                            <button class="default-btn" style="float: right;"
                                                *ngIf="currentQuestionIndex >= totalQuestion" (click)="doFinishExam()">
                                                <i class="ci ci-save"></i>
                                                Nộp bài
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3">
                                <div class="certi_form rght1528">
                                    <div class="test_timer_bg">
                                        <ul>
                                            <li>
                                                <div class="timer_time">
                                                    <h4 id="timer">
                                                        <countdown #cd [config]="config"
                                                            (event)="countdownEvent($event)">
                                                        </countdown>
                                                    </h4>
                                                    <p>Thời gian</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="membership_chk_bg rght1528">
                                            <div class="checkout_title">
                                                <h4>Danh sách câu hỏi</h4>
                                                <img src="asses/images/line.svg" alt="">
                                            </div>
                                            <div class="list-wrap">
                                                <ul style="padding-left: 0px;">
                                                    <ng-container *ngFor="let item of dataTest; let i = index">
                                                        <li class="menu--item">
                                                            <a (click)="goQuestion(i)" class="menu--link" title="Home"
                                                                [class]="(currentQuestionIndex == i) ? 'active': (item.answerText !== null ? 'done': 'val')">
                                                                <i class="uil uil-check-circle menu--icon"
                                                                    *ngIf="item.answerText !== null"></i>
                                                                <i class="uil uil-focus menu--icon"
                                                                    *ngIf="item.answerText == null"></i>
                                                                <span class="menu--label">Câu {{i + 1}}</span>
                                                            </a>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- End ViewCourseItem Nhiệm vụ học tập -->
        </div>
    </div>
</div>