import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageEditBase } from 'sv-shared';
import { CourseRegisterType } from '../../../config/enums';
import { CourseModel } from '../../../models/course';
import { SvSchoolYear } from '../../../models/sv-school-year';
import { CourseItemService } from '../../../services/course-item.service';
import { CourseUserService } from '../../../services/course-user.service';
import { CourseService } from '../../../services/course.service';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';
import { SvSubjectService } from '../../../services/sv-subject.service';


@Component({
    selector: 'app-course-detail',
    templateUrl: './course-detail.component.html',
    styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent extends SecondPageEditBase implements OnInit {
    id: number;
    itemDetail: CourseModel = new CourseModel();
    isLogin = false;
    isRegister = false;
    itemCourseUser: any = { id: 0, trangThai: 2, }
    dsCourseItem = [];
    display = true;
    currYear: SvSchoolYear = new SvSchoolYear();
    itemSubject: any = { id: 0, code: '', title: '' };
    dataSource = [];
    courseRegisterType: CourseRegisterType;
    isTeacher = false;
    constructor(
        protected _svSchoolYearService: SvSchoolYearService,
        private _courseService: CourseService,
        protected _activatedRoute: ActivatedRoute,
        protected _courseUserService: CourseUserService,
        protected _courseItemService: CourseItemService,
        protected _router: Router,
        protected _injector: Injector,
        private _svSubjectService: SvSubjectService
    ) {
        super(_courseUserService, _injector);
    }

    ngOnInit() {
        this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        })
        this.isLogin = this._authenticationService.isLoggedIn();
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.id = pid;
                this.getDetail();
                this.getData();
            });
    }
    getDetail() {
        this._courseService.getDetail(this.id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
                this.getSubject(rs.data.idSvSubject);
                this.getRelationCourse(rs.data.idCategory, rs.data.idSvSubject, this.id);
            }
        });

        if (this._authenticationService.isLoggedIn() && this.currentUser.typeId > 1) {
            // Kiểm tra xem đã đăng ký chưa
            this._courseUserService.checkRegistered(this.id).then(rs => {
                if (rs.success) {
                    if (rs.data != null) {
                        this.isRegister = true;
                        this.itemCourseUser = rs.data;
                    }
                }
            });
        }

        if (this._authenticationService.isLoggedIn() && this.currentUser.typeId == 1) {
            // Kiểm tra xem đã đăng ký chưa
            this._courseService.checkIsTeacherOfCourse(this.id).then(rs => {
                if (rs.success) {
                    if (rs.data != null) {
                        this.isTeacher = rs.data;
                    }
                }
            });
        }
    }
    getSubject(id: number) {
        this._svSubjectService.getDetail(id).then(rs => {
            if (rs.success) {
                this.itemSubject = rs.data;
            }
        })
    }

    getData() {
        this._courseItemService.searchTreeForStudent(this.id).then(rs => {
            if (rs.success) {
                this.dsCourseItem = rs.data;
            }
        })
    }

    getRelationCourse(idCategory: number, idSvSubject: number, idCourse: number) {
        this._courseService.getsRelationCourse(idCategory, idSvSubject, this.currYear.id, idCourse, 3).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    doLogin() {
        this._notifierService.showWarning(this._translateService.instant('Bạn chưa đăng nhập, không thể đăng ký học!'))
    }

    doRegister() {
        this._courseUserService.registerCourse(this.id).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess(this._translateService.instant('Đăng ký thành công !'));
                this.getDetail();
            } else {
                this._notifierService.showWarning(this._translateService.instant('Lớp học phần đã kết thúc, hoặc không cho đăng ký !'))
            }
        })
    }
    goLearning() {
        this._router.navigate([`/learning-space/${this.id}`]);
    }

}
