import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecondPageIndexBase, SvUser, UserService } from 'sv-shared';
import { SvSchoolYear } from '../../../models/sv-school-year';
import { TeacherModel } from '../../../models/teacher';
import { CourseService } from '../../../services/course.service';
import { SvSchoolYearService } from '../../../services/sv-school-year.service';

@Component({
    selector: 'app-teacher-index',
    templateUrl: './teacher-index.component.html',
    styleUrls: ['./teacher-index.component.scss']
})
export class TeacherIndexComponent extends SecondPageIndexBase implements OnInit {
    currUser: SvUser;
    itemDetail: TeacherModel = new TeacherModel();
    currYear: SvSchoolYear = new SvSchoolYear();
    dataSourceOld = [];
    dsSvSchoolYear = [];
    idSchoolYearOld = 0;
    dataTrash = [];
    constructor(
        protected _courseService: CourseService,
        protected _userService: UserService,
        protected _activatedRoute: ActivatedRoute,
        protected _injector: Injector,
        protected _svSchoolYearService: SvSchoolYearService,
    ) {
        super(_courseService, _injector);

    }

    async ngOnInit() {
        await this._svSchoolYearService.GetSchoolYear().then(rs => {
            this.currYear = rs;
            this.idSchoolYearOld = rs.id - 1;
        })
        await this._userService.getCurrentUser().then(rs => {
            this.currUser = rs;
        })
        this.getDetail();
        this.getData();
        this.getsSvSchoolYear();
        this.getOldData();
        this.getTrashData();
    }

    getDetail() {
        this._userService.getPublicInfo(this.currUser.userId).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        });
    }

    getData() {
        var model = {
            idSvSchoolYear: this.currYear.id,
            status: 1,
            query: ''
        }
        this._courseService.GetsMyCourse(model).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    getsSvSchoolYear() {
        this.dsSvSchoolYear = [];
        this._svSchoolYearService.get('', 1, 1, 99999, 'Id', true)
            .then(rs => {
                if (rs.success) {
                    rs.data.forEach(element => {
                        if (parseInt(element.id) < this.currYear.id) {
                            this.dsSvSchoolYear.push({ label: element.title, value: element.id });
                        }
                    });
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            });
    }
    getOldData() {
        var model = {
            idSvSchoolYear: this.idSchoolYearOld,
            status: 1,
            query: ''
        }
        this._courseService.GetsMyCourse(model).then(rs => {
            if (rs.success) {
                this.dataSourceOld = rs.data;
            }
        })
    }

    getTrashData() {
        var model = {
            idSvSchoolYear: this.currYear.id,
            status: 2,
            query: ''
        }
        this._courseService.GetsMyCourse(model).then(rs => {
            if (rs.success) {
                this.dataTrash = rs.data;
            }
        })
    }

}
