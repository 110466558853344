<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Tài liệu học tập' | translate}}</li>
            </ul>
            <h2>{{'Tài liệu học tập' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-5 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="tabs-container">
                <div class="pane">
                    <app-fssystem-file-viewer></app-fssystem-file-viewer>
                </div>
            </div>
        </div>
    </div>
</div>
