<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Kho học liệu cá nhân' | translate}}</li>
            </ul>
            <h2>{{'Kho học liệu cá nhân' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="profile-area pt-5 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <ul class="nav-tabset">
                <li class="nav-tab" (click)="goTab(1)" [class.active]="tabId == 1">
                    <span>{{'Kho học liệu cá nhân' | translate}}</span>
                </li>
                <li class="nav-tab" (click)="goTab(2)" [class.active]="tabId == 2">
                    <span>{{'Chia sẻ với tôi' | translate}}</span>
                </li>
                <li class="nav-tab" (click)="goTab(3)" [class.active]="tabId == 3">
                    <span>{{'Thùng rác' | translate}}</span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" *ngIf="tabId == 1">
                    <app-fssystem-private></app-fssystem-private>
                </div>
                <div class="pane" *ngIf="tabId == 2">
                    <app-fssystem-private-shared-with-me></app-fssystem-private-shared-with-me>
                </div>
                <div class="pane" *ngIf="tabId == 3">
                    <app-fssystem-private-trash></app-fssystem-private-trash>
                </div>
            </div>
        </div>
    </div>
</div>
