<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Tin tức / thông báo / blog' | translate}}</li>
            </ul>
            <h2>{{itemCategory.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <ng-container *ngFor="let item of dataSource">
                        <div class="col-lg-12 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <a routerLink="/news-detail/{{item.id}}" class="d-block">
                                        <img src="{{item.imageUrl}}" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <a routerLink="/blog-4" class="category">{{item.titleCategory}}</a>
                                    <h3><a routerLink="/news-detail/{{item.id}}">
                                            {{item.title}}
                                        </a></h3>
                                    <ul class="post-content-footer d-flex align-items-center">
                                        <li>
                                        </li>
                                        <li><i class='flaticon-calendar'></i> {{item.publishedDate | date :
                                            'dd/MM/yyyy'}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <sv-paging [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize"
                                [pagesToShow]="5" *ngIf="totalRecord > 0" [loading]="isLoading"
                                (onGoPage)="onGoPage($event)" (onPrePage)="onPrePage()" (onNextPage)="onNextPage()">
                            </sv-paging>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <app-new-widget-area></app-new-widget-area>
            </div>
        </div>
    </div>
</div>
