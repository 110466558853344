import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {
    tabId = 1;
    constructor() { }

    ngOnInit() {
    }

    goTab(id: number) {
        this.tabId = id;
    }
}
