<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li><a routerLink="/">{{'Nhiệm vụ học tập' | translate}}</a> </li>
            </ul>
            <h2>{{'Nhiệm vụ học tập' | translate}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <div class="tabs-container">
                <div class="col-md-12" *ngIf="dataSource.length == 0">
                    <div class="alert alert-success" role="alert">
                        Bạn đã hoàn thành tất cả các nhiệm vụ học tập / hoặc chưa có nhiệm vụ học tập nào
                    </div>
                </div>
                <div class="col-md-12" *ngIf="dataSource.length > 0">
                    <div class="card mb-3" *ngFor="let item of dataSource">
                        <div class="card-header bg-success text-white">
                            <div class="row">
                                <div class="col">
                                    <h3 class="panel-title">{{item.title}}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="course-item text-dark" *ngFor="let taskItem of item.dsTask">
                            <div class="subtitle1" [ngClass]="{'subtitle2' : taskItem.isOutOfDate == true}">
                                <div class="row">
                                    <div class="col">
                                        <h4>{{taskItem.title}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row mb-20">
                                    <label class="col-md-2 col-form-label">Nội dung</label>
                                    <div class="col-md-10" [innerHTML]="taskItem.contents">
                                    </div>
                                </div>
                                <div class="row mb-20">
                                    <label class="col-md-2 col-form-label">Thời gian bắt đầu</label>
                                    <div class="col-md-10">
                                        {{taskItem.startTime | date : 'dd/MM/yyyy HH:mm'}}
                                    </div>
                                </div>
                                <div class="row mb-20">
                                    <label class="col-md-2 col-form-label">Thời gian kết thúc</label>
                                    <div class="col-md-10">
                                        {{taskItem.endTime | date : 'dd/MM/yyyy HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a (click)="goTask(taskItem)" class="default-btn">
                                    <i class="bx bx-task"></i>
                                    Thực hiện nhiệm vụ
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>