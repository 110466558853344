import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { TestQuestionType } from '../../../../config/enums';
import { CourseTestQuestionService } from '../../../../services/course-test-question.service';

@Component({
    selector: 'app-course-test-question-sc',
    templateUrl: './course-test-question-sc.component.html',
    styleUrls: ['./course-test-question-sc.component.scss']
})
export class CourseTestQuestionScComponent extends SecondPageEditBase implements OnInit {
    dsNumber = [];
    showEditor = false;
    itemAnswer = { id: 0, idCourseTestQuestion: 0, answerText: '', status: 1, isCorrect: false }
    indexAnswer = -1;
    correctAnswer = -1;
    idQuestionParent = null;
    idCourseItem = 0;
    idCourse = 0;
    constructor(
        protected _testquestionService: CourseTestQuestionService,
        protected _injector: Injector
    ) {
        super(_testquestionService, _injector);

        this.formGroup = new FormGroup({
            questionText: new FormControl('', Validators.required),
            mark: new FormControl(''),
            orderNo: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    getDs() {
        if (this.dsNumber.length == 0) {
            for (let index = 1; index < 20; index++) {
                this.dsNumber.push({ label: index, value: index })
            }
        }

    }

    async onShowPopup(item: { id: 0, idCourse: 0, idCourseItem: 0, idQuestionParent: null }) {
        // this.resetForm();
        this.getDs();
        this.idQuestionParent = item.idQuestionParent;
        this.idCourseItem = item.idCourseItem;
        this.idCourse = item.idCourse;
        this.submitting = true;
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (item.id > 0) {
            this._testquestionService.getDetail(item.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    for (let index = 0; index < this.itemDetail.dsAnswers.length; index++) {
                        if (this.itemDetail.dsAnswers[index].isCorrect == true) {
                            this.correctAnswer = index;
                            break;
                        }
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = {
                id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: item.idQuestionParent, idTestQuestionType: TestQuestionType.SC, levelOfDifficult: 1, mark: 1, status: 1
                , dsAnswers: [
                    { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                    { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                    { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                    { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false }
                ]
            };
            this.submitting = false;
        }
    }

    onBeforeSave(): boolean {
        if (this.itemDetail.dsAnswers.length == 0) {
            this._notifierService.showWarning(this._translateService.instant('TestQuestion_MESSAGE_INPUTERROR'));
            return false;
        } else {
            var correct = this.itemDetail.dsAnswers.filter(x => x.isCorrect).length;
            if (correct !== 1) {
                this._notifierService.showWarning(this._translateService.instant('TestQuestion_MESSAGE_INPUTERROR'));
                return false;
            }
        }

        return true;
    }

    addAnswer() {
        this.itemDetail.dsAnswers.push({ id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false });
    }


    deleteAnswer(index: number) {
        this._notifierService.showDeleteConfirm().then(rs => {
            this.itemDetail.dsAnswers.splice(index, 1);
        });
    }
    onChangeCorrectAnswer() {
        for (let index = 0; index < this.itemDetail.dsAnswers.length; index++) {
            if (index == this.correctAnswer) {
                this.itemDetail.dsAnswers[index].isCorrect = true;
            } else {
                this.itemDetail.dsAnswers[index].isCorrect = false;
            }
        }
    }

    resetForm() {
        this.validationSummary.resetErrorMessages();
        this.formGroup.reset();
        this.itemDetail = {
            id: 0, idCourse: this.idCourse, idCourseItem: this.idCourseItem, idQuestionParent: this.idQuestionParent, idTestQuestionType: TestQuestionType.SC, levelOfDifficult: 1, mark: 1, status: 1
            , dsAnswers: [
                { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false },
                { id: 0, idCourseTestQuestion: this.itemDetail.id, answerText: '', status: 1, isCorrect: false }
            ]
        };
        this.submitting = false;
    }

    delete() {
        this.submitting = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._testquestionService.deleteById(this.itemDetail.idCourse, this.itemDetail.id)
                .then(response => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                    this.submitting = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.submitting = false;
                });
        });
    }

    changeEditorView() {
        this.showEditor = !this.showEditor;
    }
}

