<div class="about-area ptb-100 bg-f9fbff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img12.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">HỌC TỪ XA</span>
                    <h2>Số hóa & tổ chức giảng dạy các lớp học phần theo đúng chương trình đào tạo của trường Và các
                        khóa học bổ trợ kiến thức, kỹ năng cho học viên / sinh viên</h2>
                    <p>Phát triển kiến thức - kỹ năng của bạn, học điều gì đó mới và phát triển kỹ năng của bạn từ mọi
                        nơi trên thế giới!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Giảng viên hướng dẫn học</span></li>
                        <li><span><i class="flaticon-time-left"></i> Truy cập mọi lúc</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Học từ xa</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Bổ sung, củng cố kiến thức</span></li>
                    </ul>
                    <a routerLink="/courses" class="default-btn"><i class="flaticon-user"></i>
                        Các lớp học phần<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>
</div>
