<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{'Trang chủ' | translate}}</a></li>
                <li>{{'Khóa học' | translate}}</li>
            </ul>
            <h2>{{itemCategory.title}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>Có tất cả <span class="count">{{totalRecord}}</span> Khóa học</p>
            </div>
            <div class="col-lg-3 col-md-3 ordering">
                <div class="select-box">
                    <label>{{'Phân loại' | translate}}</label>
                    <p-dropdown [options]="dsTypeId" [(ngModel)]="typeId" (onChange)="onSearch()"></p-dropdown>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 ordering">
                <div class="select-box">
                    <label>{{'Sắp xếp theo' | translate}}</label>
                    <p-dropdown [options]="dsOrderBy" [(ngModel)]="idOrderBy" (onChange)="changeOrder()"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of dataSource">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/course-detail/{{item.id}}" class="d-block image">
                            <img src="{{item.imageUrl | fsFileUrl}}" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="{{item.avatarTeacher | fsFileUrl}}" class="rounded-circle" alt="image">
                            <span>{{item.displayNameTeacher}}</span>
                        </div>
                        <h3><a routerLink="/course-detail/{{item.id}}">{{item.title}}</a></h3>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> {{item.countCourseItem}} {{'Nội dung' |
                                translate}}</li>
                            <li><i class='flaticon-people'></i> {{item.countCourseUser}} {{'Học viên' |
                                translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <sv-paging [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize"
                        [pagesToShow]="5" *ngIf="totalRecord > 0" [loading]="isLoading" (onGoPage)="onGoPage($event)"
                        (onPrePage)="onPrePage()" (onNextPage)="onNextPage()">
                    </sv-paging>
                </div>
            </div>
        </div>
    </div>
</div>