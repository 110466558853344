import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SecondPageEditBase } from 'sv-shared';
import { InteractiveVideoTestQuestionService } from '../../../../services/interactive-video-test-question.service';
import { InteractiveVideoTestService } from '../../../../services/interactive-video-test.service';
import { InteractiveVideoService } from '../../../../services/interactive-video.service';
import { InteractiveVideoPlayerComponent } from '../../../player/interactive-video-player/interactive-video-player.component';

@Component({
    selector: 'app-interactive-video-view',
    templateUrl: './interactive-video-view.component.html',
    styleUrls: ['./interactive-video-view.component.scss']
})
export class InteractiveVideoViewComponent extends SecondPageEditBase implements OnInit {

    @ViewChild('player') player: InteractiveVideoPlayerComponent;
    constructor(
        protected _interactivevideoService: InteractiveVideoService,
        protected _injector: Injector,
        protected _interactiveVideoTestService: InteractiveVideoTestService,
        protected _interactiveVideoTestQuestionService: InteractiveVideoTestQuestionService
    ) {
        super(_interactivevideoService, _injector);
    }

    ngOnInit() {
    }

    async onShowPopup(id) {
        this.player.playIntractiveVideo(id);
    }
}
