import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { resolve } from 'path';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FsItemPublicService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svFsSystemEndpoint}/FsItemPublic`);
    }

    uploadAvatar(file: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UploadAvatar`;
        return this._http.post<ResponseResult>(url, file)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    uploadCourseImage(file: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UploadCourseImage`;
        return this._http.post<ResponseResult>(url, file)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    uploadCourseItemLog(file: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UploadCourseItemLog`;
        return this._http.post<ResponseResult>(url, file)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getFsItemUrl(storageUrl: string): string {
        return `${environment.apiDomain.svFsSystemEndpoint}/${storageUrl}`;
    }

}
