import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'sv-shared';
import { CourseTopicService } from '../../../../services/course-topic.service';

@Component({
    selector: 'app-learning-space-forum-add-topic',
    templateUrl: './learning-space-forum-add-topic.component.html',
    styleUrls: ['./learning-space-forum-add-topic.component.scss']
})
export class LearningSpaceForumAddTopicComponent extends SecondPageEditBase implements OnInit {

    idCourse = 0;
    constructor(
        protected _courseTopicService: CourseTopicService,
        protected _injector: Injector,
    ) {
        super(_courseTopicService, _injector);

        this.formGroup = new FormGroup({
            title: new FormControl('', [Validators.required]),
            bodyHtml: new FormControl(''),
        });
    }

    async ngOnInit() {
    }

    async onShowPopup(data: { id: number, idCourse: number; }) {
        this.idCourse = data.idCourse;
        this.submitting = true;
        this.validationSummary.resetErrorMessages();
        if (data.id > 0) {
            this._courseTopicService.getDetail(data.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idCourse: this.idCourse, userId: this.currentUser.userId, viewCount: 0, replyCount: 0 };
            this.submitting = false;
        }
    }
    onBeforeSave() {

    }
}
