import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { ExportService, SecondPageEditBase, UserService } from 'sv-shared';
import { CourseUserService } from '../../../../services/course-user.service';

@Component({
    selector: 'app-course-item-user',
    templateUrl: './course-item-user.component.html',
    styleUrls: ['./course-item-user.component.scss']
})
export class CourseItemUserComponent extends SecondPageEditBase implements OnInit {

    @ViewChild('fileControl') fileControl: FileUpload;
    rawFileName = '';
    isUploading = false;
    dataSource = [];
    cols = [];
    dataError = [];
    dsCourseUser = [];
    dsUsers = [];
    courseInfo: any;
    constructor(
        protected _courseUserService: CourseUserService,
        protected _injector: Injector,
        protected _exportService: ExportService,
        protected _userService: UserService,
    ) {
        super(_courseUserService, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'userName', header: this._translateService.instant('Users.userName'), visible: true, sort: true },
            { field: 'displayName', header: this._translateService.instant('Users.displayName'), visible: true, sort: true },
        ];
    }

    myUploader(event) {
        this.submitting = true;
        const files = event.files;
        if (files.length > 0) {
            this.isUploading = true;
            this.rawFileName = files[0].name;
            const formData: FormData = new FormData();
            formData.append(this.rawFileName, files[0]);
            this._courseUserService.importUsers(this.courseInfo.id, formData).then(rs => {
                if (rs.success) {
                    this.dataSource = rs.data.data;
                    this.dataError = rs.data.dataError;
                } else {
                    this._notifierService.showHttpUnknowError();
                }
                this.submitting = false;
                this.isUploading = false;
            }).catch(err => {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
                this.submitting = false;
                this.isUploading = false;
            });
        }
        this.submitting = false;
    }
    onShowPopup(data: any) {
        this.courseInfo = data;
        this.dataError = [];
        this.dataSource = [];

        this.dsUsers = [];
        var model = {
            keyword: '',
            trangThai: 1,
            idPhongBan: 0,
            typeId: 2,
            idChucVu: 0,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: 'displayName',
            isDesc: false
        }
        this._userService.find(model)
            .then(rs => {
                if (rs.success) {
                    this.dsUsers = rs.data;
                }
            });

        this._courseUserService.getsByIdCourse(this.courseInfo.id)
            .then(response => {
                this.dsCourseUser = response.data;
            });
    }
    onUpload() {
        this.fileControl.upload();
    }
    async onImport() {
        this.submitting = true;

        var userIds = [];

        await this.dataSource.forEach(item => {
            if ((this.dsCourseUser.findIndex(x => x.userName == item.userName) < 0)
                && this.dsUsers.findIndex(x => x.userName == item.userName) >= 0) {
                var uInfo = this.dsUsers.find(x => x.userName == item.userName);
                if (userIds.findIndex(x => x == uInfo.id) < 0) {
                    userIds.push(uInfo.id);
                }


            }
        });

        if (userIds.length > 0) {

            var modelAdd = [];
            userIds.forEach(item => {
                modelAdd.push(
                    {
                        idCourse: this.courseInfo.id,
                        userId: item,
                        trangThai: 1,
                    })
            });

            this._courseUserService.addsCourseUser(this.courseInfo.id, modelAdd).then(rs => {
                if (rs.success) {
                    this._notifierService.showInsertDataSuccess();
                    this.closePopupMethod(true);
                } else {
                    this._notifierService.showHttpUnknowError();
                }
                this.submitting = false;
            }).catch(err => {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
                this.submitting = false;
            });
        } else {
            this._notifierService.showWarning('Không có dữ liệu cần import');
        }

    }
    downloadForm() {
        this._courseUserService.getTemplateImportUsers();
    }
}
