<p-dialog #dialog header="{{ 'Cập nhật thông tin bài kiểm tra' | translate }}" class="sv-modal" [(visible)]="isShow"
    [modal]="true" [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '1300px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="col-lg-12 col-md-12">
                        <div class="sv-form-control type-text-field required">
                            <div class="sv-form-item__label" for="title">{{'FORM.TITLE' | translate}}</div>
                            <div class="sv-form-item__input-wrapper">
                                <input type="text" class="sv-form-item__input sv-text-field"
                                    [(ngModel)]="itemDetail.title" formControlName="title">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="sv-form-control type-text-field ">
                            <div class="sv-form-item__label" for="startTime">
                                {{'Thời gian bắt đầu (giây - theo video)' | translate}}</div>
                            <div class="sv-form-item__input-wrapper">
                                <input type="text" class="sv-form-item__input sv-text-field"
                                    [(ngModel)]="itemDetail.startTime" formControlName="startTime">
                                &nbsp;
                                <button type="button" pTooltip="Chọn thời gian bắt đầu"
                                    class="sv-btn icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--primary"
                                    (click)="getStartTime()">
                                    <span class="bx bx-time"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="sv-form-control type-text-field required">
                            <div class="sv-form-item__label" for="testTime">{{'Thời gian làm bài (phút)' | translate}}</div>
                            <div class="sv-form-item__input-wrapper">
                                <input type="text" class="sv-form-item__input" [(ngModel)]="itemDetail.testTime"
                                    formControlName="testTime">
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="col-lg-12 col-md-12" *ngIf="videoInfo.videoUrl !== null && videoInfo.videoUrl !== ''">
                        <div class="video embed-responsive embed-responsive-16by9">
                            <video #video width="100%" height="100%" controls
                                src="{{videoInfo.videoUrl | fsFileUrl}}"></video>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1 sv-icon-color--red-60">
                    <span class="__icon ci ci-delete sv-color--red-60"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.DELETE' | translate}}</span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
                    <span class="__icon ci ci-save"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
