<p-dialog #dialog header="{{ 'Video tương tác' | translate }}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '850px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="ui-g large-gutter row sv-form-row">
            <app-interactive-video-player #player></app-interactive-video-player>
        </div>
    </div>
</p-dialog>
