<p-dialog #dialog header="{{ title }}" class="sv-modal" [(visible)]="isShow" [modal]="true" [responsive]="true"
    [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '1300px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-success" role="alert">
                    Tổng điểm: <span class="label label-danger font-20"><b>{{totalMark}}</b> điểm</span>
                </div>
            </div>
        </div>
        <div class="sv-table-wrapper">
            <p-table [columns]="cols" [value]="dataTest" class="sv-table" [scrollable]="true" scrollHeight="auto"
                [lazy]="true">
                <ng-template pTemplate="colgroup">
                    <colgroup>

                        <ng-container *ngFor="let col of cols">
                            <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                        </ng-container>
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <th *ngIf="col.sort" [pSortableColumn]="col.field" [hidden]="!col.visible">
                                <div class="flex-el space-between">
                                    <div class="overflow-break-word">{{col.header}}</div>
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>
                            <th *ngIf="!col.sort" [hidden]="!col.visible">
                                <div class="overflow-break-word">{{col.header}}</div>
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>

                        <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                            <div *ngSwitchCase="'questionText'" class="ui-g-12">
                                <div class="ui-g-12">
                                    Câu {{rowIndex + 1}} - {{'TestQuestion_Marks' | translate }}: {{rowData.mark}}
                                </div>
                                <div class="ui-g-12 fw-600" [innerHTML]="rowData.questionText">
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 1">
                                    <div class="ui-g-12 pd-tb0">
                                        <div class="ui-g-12 pd-tb0" *ngFor="let ans of rowData.dsAnswers">
                                            <div class="ui-g-1 pd-tb0">
                                                <p-radioButton name="{{rowData.id}}" value="{{ans.id}}" disabled
                                                    [(ngModel)]="+rowData.answerText">
                                                </p-radioButton>
                                            </div>
                                            <div class="ui-g-11 pd-tb0" [innerHTML]="ans.answerText">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-2 pd-tb0">
                                            Đáp án đúng:
                                        </div>
                                        <div class="ui-g-10 pd-tb0">
                                            <span class="ui-g-12 " *ngFor="let item of rowData.dsCorrectAnswer"
                                                [innerHTML]="item.answerText"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 2">
                                    <div class="ui-g-12 pd-tb0">
                                        <div class="ui-g-12 pd-tb0" *ngFor="let ans of rowData.dsAnswers">
                                            <div class="ui-g-1 pd-tb0">
                                                <p-checkbox disabled name="{{ans.id}}" binary="true"
                                                    [(ngModel)]="ans.isUserAnswer"></p-checkbox>
                                            </div>
                                            <div class="ui-g-11 pd-tb0" [innerHTML]="ans.answerText"></div>
                                        </div>
                                    </div>
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-2 pd-tb0">
                                            Đáp án đúng:
                                        </div>
                                        <div class="ui-g-10 pd-tb0">
                                            <span class="ui-g-12 " *ngFor="let item of rowData.dsCorrectAnswer"
                                                [innerHTML]="item.answerText"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 3">

                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Đáp án đúng:
                                        </div>
                                        <div class="ui-g-9 pd-tb0" [innerHTML]="rowData.correctAnswer"></div>
                                    </div>
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Học sinh trả lời:
                                        </div>
                                        <div class="ui-g-9 pd-tb0" [innerHTML]="rowData.answerText"></div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 7">
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Đáp án đúng:
                                        </div>
                                        <div class="ui-g-9 pd-tb0">
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsAnswers"
                                                    [innerHTML]="item.answerText"></div>
                                            </div>
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsAnswers"
                                                    [innerHTML]="item.matching">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Học sinh trả lời:
                                        </div>
                                        <div class="ui-g-9 pd-tb0">
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsAnswers"
                                                    [innerHTML]="item.answerText"></div>
                                            </div>
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsMatching"
                                                    [innerHTML]="item.matching">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 4">
                                    <div class="ui-g-12">
                                        <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                                            Học sinh trả lời:
                                        </div>
                                        <div class="ui-g-9 pd-tb0" [innerHTML]="rowData.answerText"></div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 6">
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Đáp án đúng:
                                        </div>
                                        <div class="ui-g-9 pd-tb0">
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsAnswers"
                                                    [innerHTML]="item.answerText"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                        <div class="ui-g-3 pd-tb0">
                                            Học sinh trả lời:
                                        </div>
                                        <div class="ui-g-9 pd-tb0">
                                            <div class="ui-g-6 pd-2">
                                                <div class="col-md-12" *ngFor="let item of rowData.dsAnswers"
                                                    [innerHTML]="item.answerText"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 8">
                                    <div class="ui-g-12">
                                        <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                                            Học sinh trả lời:
                                        </div>
                                        <div class="ui-g-9 pd-tb0">
                                            <a href="{{rowData.answerFileUrl}}">{{rowData.answerFileUrl | fileName}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rowData.idTestQuestionType == 5">
                                    <div class="ui-g-12 pd-tb0"
                                        *ngFor="let subItem of rowData.dsSubQuestions; index as i">
                                        <div class="row" *ngIf="subItem.idTestQuestionType == 1">
                                            <div class="ui-g-11">
                                                Câu {{rowIndex + 1}}.{{i + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{subItem.mark}}
                                            </div>
                                            <div class="ui-g-1">
                                                <input type="text" class="__control-input" style="width:90px"
                                                    [(ngModel)]="subItem.userMark">
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12 pd-tb0">
                                                <div class="ui-g-12 pd-tb0" *ngFor="let subans of subItem.dsAnswers">
                                                    <div class="ui-g-1 pd-tb0">
                                                        <p-radioButton name="{{rowData.id}}_{{subItem.id}}"
                                                            value="{{subans.id}}" disabled
                                                            [(ngModel)]="+subItem.answerText">
                                                        </p-radioButton>
                                                    </div>
                                                    <div class="ui-g-11 pd-tb0" [innerHTML]="subans.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-2 pd-tb0">
                                                    Đáp án đúng:
                                                </div>
                                                <div class="ui-g-10 pd-tb0">
                                                    <span class="ui-g-12 " *ngFor="let item of subItem.dsCorrectAnswer"
                                                        [innerHTML]="item.answerText"></span>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 2">
                                            <div class="ui-g-11">
                                                Câu {{rowIndex + 1}}.{{i + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{subItem.mark}}
                                            </div>
                                            <div class="ui-g-1">
                                                <input type="text" class="__control-input" style="width:90px"
                                                    [(ngModel)]="subItem.userMark">
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12 pd-tb0">
                                                <div class="ui-g-12 pd-tb0" *ngFor="let subans of subItem.dsAnswers">
                                                    <div class="ui-g-1 pd-tb0">
                                                        <p-checkbox disabled name="{{rowData.id}}_{{subItem.id}}"
                                                            binary="true" [(ngModel)]="subans.isUserAnswer">
                                                        </p-checkbox>
                                                    </div>
                                                    <div class="ui-g-11 pd-tb0" [innerHTML]="subans.answerText">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-2 pd-tb0">
                                                    Đáp án đúng:
                                                </div>
                                                <div class="ui-g-10 pd-tb0">
                                                    <span class="ui-g-12 " *ngFor="let item of subItem.dsCorrectAnswer"
                                                        [innerHTML]="item.answerText"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 3">
                                            <div class="ui-g-11">
                                                Câu {{rowIndex + 1}}.{{i + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{subItem.mark}}
                                            </div>
                                            <div class="ui-g-1">
                                                <input type="text" class="__control-input" style="width:90px"
                                                    [(ngModel)]="subItem.userMark">
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-2 pd-tb0">
                                                    Đáp án đúng:
                                                </div>
                                                <div class="ui-g-10 pd-tb0" [innerHTML]="subItem.correctAnswer"></div>
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-3 pd-tb0">
                                                    Học sinh trả lời:
                                                </div>
                                                <div class="ui-g-9 pd-tb0" [innerHTML]="subItem.answerText"></div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 4">
                                            <div class="ui-g-12">
                                                Câu {{rowIndex + 1}}.{{i + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{subItem.mark}}
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12">
                                                <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                                                    Học sinh trả lời:
                                                </div>
                                                <div class="ui-g-9 pd-tb0" [innerHTML]="subItem.answerText"></div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 6">
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-3 pd-tb0">
                                                    Đáp án đúng:
                                                </div>
                                                <div class="ui-g-9 pd-tb0">
                                                    <div class="ui-g-6 pd-2">
                                                        <div class="col-md-12" *ngFor="let subans of subItem.dsAnswers"
                                                            [innerHTML]="subans.answerText"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-3 pd-tb0">
                                                    Học sinh trả lời:
                                                </div>
                                                <div class="ui-g-9 pd-tb0">
                                                    <div class="ui-g-6 pd-2">
                                                        <div class="col-md-12" *ngFor="let subans of subItem.dsAnswers"
                                                            [innerHTML]="subans.answerText"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 7">
                                            <div class="ui-g-12">
                                                Câu {{rowIndex + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{rowData.mark}}
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-3 pd-tb0">
                                                    Đáp án đúng:
                                                </div>
                                                <div class="ui-g-9 pd-tb0">
                                                    <div class="ui-g-6 pd-tb0">
                                                        <div class="col-md-12 pd-tb0"
                                                            *ngFor="let item of subItem.dsAnswers"
                                                            [innerHTML]="item.answerText"></div>
                                                    </div>
                                                    <div class="ui-g-6 pd-tb0">
                                                        <div class="col-md-12" *ngFor="let item of subItem.dsAnswers"
                                                            [innerHTML]="item.answerText">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ui-g-12 fw-600" style="font-weight: bold;">
                                                <div class="ui-g-3 pd-tb0">
                                                    Học sinh trả lời:
                                                </div>
                                                <div class="ui-g-9 pd-tb0">
                                                    <div class="ui-g-6 pd-tb0">
                                                        <div class="col-md-12 pd-tb0"
                                                            *ngFor="let item of subItem.dsAnswers"
                                                            [innerHTML]="item.answerText"></div>
                                                    </div>
                                                    <div class="ui-g-6 pd-tb0">
                                                        <div class="col-md-12 pd-tb0"
                                                            *ngFor="let item of subItem.dsMatching"
                                                            [innerHTML]="item.matching">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="subItem.idTestQuestionType == 8">
                                            <div class="ui-g-12">
                                                Câu {{rowIndex + 1}} - {{'TestQuestion_Marks' | translate }}:
                                                {{subItem.mark}}
                                            </div>
                                            <div class="ui-g-12 fw-600" [innerHTML]="subItem.questionText">
                                            </div>
                                            <div class="ui-g-12">
                                                <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                                                    Học sinh trả lời:
                                                </div>
                                                <div class="ui-g-9 pd-tb0">
                                                    <a href="{{subItem.answerFileUrl}}">{{subItem.answerFileUrl |
                                                        fileName}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span *ngSwitchCase="'userMark'">
                                <div class="__control-input-wrapper" *ngIf="rowData.idTestQuestionType != 5">
                                    <input type="text" class="__control-input" style="width:90px"
                                        [(ngModel)]="rowData.userMark">
                                </div>
                            </span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-footer>
        <div class="ui-g large-gutter row sv-modal-footer-content">
            <div class="ui-g-8 __left-content">

            </div>

            <div class="ui-g-4 __right-content">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
                    <span class="__label">Lưu</span>
                    <span class="__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>
