import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from 'sv-shared';;
import { map, takeUntil } from 'rxjs/operators';
import { CourseItemModel } from '../../../../models/courseitem';
import { CourseUserService } from '../../../../services/course-user.service';
import { CourseItemService } from '../../../../services/course-item.service';
import { CourseService } from '../../../../services/course.service';

@Component({
    selector: 'app-course-item-user-learning-log',
    templateUrl: './course-item-user-learning-log.component.html',
    styleUrls: ['./course-item-user-learning-log.component.scss']
})
export class CourseItemUserLearningLogComponent extends SecondPageIndexBase implements OnInit {

    idCourse = 0;
    idCourseItem = 0;
    itemDetail: CourseItemModel = new CourseItemModel();

    constructor(
        protected _courseUserService: CourseUserService,
        protected _courseItemService: CourseItemService,
        protected _injector: Injector,
    ) {
        super(_courseUserService, _injector);
    }

    async ngOnInit() {
        await this._activatedRoute.params
            .pipe(map(params => params['idCourse']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourse = pid;
            });
        await this._activatedRoute.params
            .pipe(map(params => params['idCourseItem']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.idCourseItem = pid;
            });
        this.getDetail(this.idCourseItem);
        this.getData();
    }

    getDetail(id: number) {
        this._courseItemService.getDetail(id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        })
    }

    getData() {
        this.isLoading = true;
        this._courseUserService.GetsLogByIdCourseItem(this.idCourse, this.idCourseItem).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
            this.isLoading = false;
        })
    }
    goBack() {
        this._router.navigate([`teacher/course/item/${this.itemDetail.idCourse}`]);
    }
}
