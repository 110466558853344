import { BaseModel } from './base';

export class InteractiveVideoTestQuestion extends BaseModel {
    id: number;
    idInteractiveVideoTest: number;
    idQuestionParent: number;
    idTestQuestionType: number;
    questionText: string;
    mark: number;
    answerText: string;
    dsSubQuestions = [];
    dsAnswers = [];
}