
export class SvUser {
    userId: number;
    userName: string;
    displayName: string;
    fullName: string;
    email: string;
    issuperuser: string;
    permissions: any;
    scope: any;
    avatar: string;
    position: string;
    role: number;
    gender: number;
    typeId: number;
    idOrganization: number;
    constructor() {
    }

    isSuperUser(): boolean {
        return this.issuperuser === 'True';
    }

    isAdmin(): boolean {
        return this.role === 0;
    }
}
