<div class="sv-modal-content-panel sv-form-container">
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__main-block">
                <div class="sv-data-view-main-action-area">
                    <div (window:resize)="onLeftTableActionsWidthChange()"
                        class="sv-data-view-main-action-area__left-block" id="left-table-actions"
                        style="flex: 1; min-width: 110px">
                        <button type="button"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            (click)="toggleLeftSidebar()">
                            <span class="sv-btn__icon ci ci-page-last" *ngIf="!openLeftSidebar"></span>
                            <span class="sv-btn__icon ci ci-page-first" *ngIf="openLeftSidebar"></span>
                        </button>
                        <button type="button" (click)="goHome()" id="act-refresh"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            pTooltip="{{'FsSystem.BtnHome' | translate }}">
                            <span class="sv-btn__icon ci ci-home"></span>
                        </button>
                        <button type="button" pTooltip="{{'BUTTON.RELOAD' | translate }}"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            (click)="onPopupClosedReloadDataTable(true)">
                            <span class="sv-btn__icon ci ci-restart"></span>
                        </button>
                        <button type="button" (click)="addFolder()" id="act-add"
                            class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                            <span class="sv-btn__label">{{'FsSystem.BtnAddFolder' | translate}}</span>
                            <span class="sv-btn__icon ci ci-add"></span>
                        </button>
                        <button type="button" (click)="uploadFiles()" *ngIf="root > 0"
                            class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash sv-icon-color--green-50 -focus-style-1">
                            <span class="sv-btn__label">{{'FsSystem.BtnFileUpload' | translate}}</span>
                            <span class="sv-btn__icon ci ci-upload"></span>
                        </button>
                    </div>
                    <div class="sv-data-view-main-action-area__right-block">
                        <!-- <button type="button" (click)="changeViewTableMode(true)" pTooltip="Chế độ xem danh sách"
                            *ngIf="!tableViewMode"
                            class="sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash sv-icon-color--primary">
                            <span class="sv-btn__icon ci ci-table"></span>
                        </button>
                        <button type="button" (click)="changeViewTableMode(false)" pTooltip="Chế độ xem lưới"
                            *ngIf="tableViewMode"
                            class="sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash sv-color--primary">
                            <span class="sv-btn__icon ci ci-grid"></span>
                        </button> -->
                    </div>
                </div>
                <div class="sv-table-wrapper" *ngIf="!tableViewMode">
                    <div class="file-grid-wrapper">
                        <div class="file-list grid">
                            <div class="file-wrapper file-item" *ngFor="let item of dataSource">
                                <div class="file-item" (contextmenu)="showContectMenu($event, item)"
                                    (click)="onClickFsItem(item)" [class.is-clicked]="item.isClick"
                                    (dblclick)="onDoubleItemClick(item)">
                                    <div class="icon-item"
                                        [innerHTML]="item.title | icon : item.idFsItemType == 1 : item.webPath">
                                    </div>
                                    <div class="item-name">
                                        <b class="name">
                                            {{item.title}}
                                        </b>
                                        <div class="item-info">
                                            <span class="item-length" *ngIf="item.countShared > 0">
                                                {{item.countShared}}
                                            </span>
                                            <div class="item-shared" *ngIf="item.countShared > 0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="shared-icon feather feather-link">
                                                    <path
                                                        d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71">
                                                    </path>
                                                    <path
                                                        d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71">
                                                    </path>
                                                </svg>
                                            </div>
                                            <span class="item-length" *ngIf="item.idFsItemType > 1">
                                                {{item.totalSize | fileSize}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sv-table-wrapper" *ngIf="tableViewMode">
                    <p-table [columns]="cols" [value]="dataSource" class="sv-table" [scrollable]="true" [lazy]="true">
                        <ng-template pTemplate="colgroup">
                            <colgroup>
                                <!-- <col style="width: 50px"> -->
                                <ng-container *ngFor="let col of cols">
                                    <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                                </ng-container>
                            </colgroup>
                        </ng-template>

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <!-- <th>
                                    <p-triStateCheckbox class="sv-checkbox primary-theme" [(ngModel)]="isCheckAll"
                                        (onChange)="onChangeBulkSelection()"></p-triStateCheckbox>
                                </th> -->
                                <ng-container *ngFor="let col of columns">
                                    <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                                        <div class="h-flex-el -h-space-between">
                                            <div class="h-overflow-break-word">{{col.header}}</div>
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th *ngIf="!col.sort" [hidden]="!col.visible">
                                        <div class="h-overflow-break-word">{{col.header}}</div>
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                            <tr (contextmenu)="showContectMenu($event, rowData)" (click)="onClickFsItem(rowData)"
                                [class.is-clicked]="rowData.isClick" (dblclick)="onDoubleItemClick(rowData)">
                                <!-- <td>
                                    <p-checkbox class="sv-checkbox primary-theme" name="group" [value]="rowData"
                                        (onChange)="checkSelectionState()" [(ngModel)]="selectedItems"></p-checkbox>
                                </td> -->

                                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field"
                                    class="pointer">
                                    <span class="icon-item-tbl" *ngSwitchCase="'idFsItemType'"
                                        [innerHTML]="rowData.title | icon : rowData.idFsItemType == 1 : rowData.webPath"></span>
                                    <span *ngSwitchCase="'totalSize'">
                                        <span *ngIf="rowData.idFsItemType > 1">
                                            {{rowData.totalSize | fileSize}}
                                        </span>
                                    </span>
                                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-overlayPanel #pContext [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                    appendTo="body" styleClass="contextmenu">
                    <div class="menu-options">
                        <ul class="menu-option-group">
                            <li class="menu-option" (click)="viewFile()">
                                <div class="icon ci ci-view"></div>
                                <div class="text-label">
                                    {{'FsSystem.contextMenu.view' | translate}}
                                </div>
                            </li>
                            <li class="menu-option" (click)="copyUrl()" [hidden]="selectedFsItem?.idFsItemType <= 1">
                                <div class="icon ci ci-copy"></div>
                                <div class="text-label">
                                    {{'FsSystem.contextMenu.copyLink' | translate}}
                                </div>
                            </li>
                        </ul>
                        <ul class="menu-option-group">
                            <li class="menu-option" (click)="unzipItem()" [hidden]="selectedFsItem?.idFsItemType != 17">
                                <div class="icon ci ci-document-export"></div>
                                <div class="text-label">
                                    {{'FsSystem.contextMenu.unZip' | translate}}
                                </div>
                            </li>
                            <li class="menu-option" (click)="shareItem()">
                                <div class="icon ci ci-share"></div>
                                <div class="text-label">
                                    {{'FsSystem.contextMenu.share' | translate}}
                                </div>
                            </li>
                            <li class="menu-option" (click)="editFsItem()" *ngIf="selectedFsItem?.idFsItemType == 1">
                                <div class="icon ci ci-edit">
                                </div>
                                <div class="text-label ">
                                    {{'FsSystem.contextMenu.edit' | translate}}
                                </div>
                            </li>
                            <li class="menu-option" (click)="deleteFsItem()">
                                <div class="icon ci ci-delete sv-color--red-60">
                                </div>
                                <div class="text-label sv-color--red-60">
                                    {{'FsSystem.contextMenu.delete' | translate}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
    </div>
</div>

<app-fssystem-private-folder-form #pEditFolder (closePopup)="onPopupClosedReloadTree($event)">
</app-fssystem-private-folder-form>
<app-fssystem-private-upload #pUpload (closePopup)="onPopupClosedReloadDataTable($event)">
</app-fssystem-private-upload>
<app-fssystem-private-shared #pShared></app-fssystem-private-shared>
<app-fssystem-file-viewer #pFileViewer></app-fssystem-file-viewer>
