import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SecondPageIndexBase, SvUser, UserService } from 'sv-shared';
import { InteractiveVideoTestService } from '../../../services/interactive-video-test.service';
import { InteractiveVideoService } from '../../../services/interactive-video.service';
import { SvSubjectService } from '../../../services/sv-subject.service';
import { InteractiveVideoFormTestComponent } from './interactive-video-form-test/interactive-video-form-test.component';
import { InteractiveVideoFormComponent } from './interactive-video-form/interactive-video-form.component';
import { InteractiveVideoViewComponent } from './interactive-video-view/interactive-video-view.component';

@Component({
    selector: 'app-interactive-video',
    templateUrl: './interactive-video.component.html',
    styleUrls: ['./interactive-video.component.scss']
})
export class InteractiveVideoComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild('pEdit') pEdit: InteractiveVideoFormComponent;
    @ViewChild('pEditTest') pEditTest: InteractiveVideoFormTestComponent;
    @ViewChild('pView') pView: InteractiveVideoViewComponent;
    // @ViewChild('pShared') pShared: InteractiveVideoSharedComponent;
    dsSvSubject = [];
    idSvSubject = 0;
    tabIndex = 1;
    dataShared = [];
    totalRecordShared = 0;
    currentUser = new SvUser();
    constructor(
        protected _interactiveVideoService: InteractiveVideoService,
        protected _injector: Injector,
        protected _userService: UserService,
        protected _svSubjectService: SvSubjectService,
        private _interactiveVideoTestService: InteractiveVideoTestService
    ) {
        super(_interactiveVideoService, _injector);
    }

    ngOnInit() {
        this.getsSvSubject();
        this.getData();
    }

    getsSvSubject() {
        this.dsSvSubject = [{ label: ' -- Chọn học phần', value: 0 }]

        var model = {
            idOrganization: 0,
            keyword: '',
            status: 1,
            pageIndex: 1,
            pageSize: 99999,
            orderCol: 'Id',
            isDesc: true
        }
        this._svSubjectService.find(model)
            .then(rs => {
                rs.data.map(item =>
                    this.dsSvSubject.push({ label: `${item.code} - ${item.title}`, value: item.id })
                );
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });

    }
    getData() {
        this.isLoading = true;
        var model = {
            idSvSubject: this.idSvSubject,
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            getListTest: true,
        }
        this._interactiveVideoService.getsMyVideo(model)
            .then(response => {
                this.dataSource = response.data;
                this.dataExport = response.data;
                this.totalRecord = response.totalRecord;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });

        this.resetBulkSelect();
    }

    onEdit(id: number) {
        this.pEdit.showPopup(id);
    }
    onView(id: number) {
        this.pView.showPopup(id);
    }

    onDelete(item: any) {
        this.isLoading = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._baseService.delete(item.id)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    onAdd() {
        this.pEdit.showPopup();
    }
    onGoPage($event) {
        this.pageIndex = $event;
        this.getData();
    }
    onShare(id: number) {
        //this.pShared.showPopup(id);
    }

    onAddTest(itemVideo: any) {
        this.pEditTest.showPopup({ idInteractiveVideo: itemVideo.id, id: 0 });
    }
    onEditTest(itemTest: any) {
        this.pEditTest.showPopup({ idInteractiveVideo: itemTest.idInteractiveVideo, id: itemTest.id });
    }
    onDeleteTest(itemTest: any) {
        this.isLoading = true;
        this._notifierService.showDeleteConfirm().then(rs => {
            this._interactiveVideoTestService.delete(itemTest.id)
                .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
        });
    }

    goQuestionManager(itemTest: any) {
        this._router.navigate([`teacher/interactive-video/exam/${itemTest.idInteractiveVideo}/${itemTest.id}`]);
    }
}

