import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ComponentBase } from 'sv-shared';
import { CourseUserLogService } from '../../../services/course-user-log.service';
import { CourseUserService } from '../../../services/course-user.service';
import { CourseService } from '../../../services/course.service';

@Component({
    selector: 'app-student-course-result',
    templateUrl: './student-course-result.component.html',
    styleUrls: ['./student-course-result.component.scss']
})
export class StudentCourseResultComponent extends ComponentBase implements OnInit {
    itemDetail = { title: '' };
    dsResult = [];
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _courseService: CourseService,
        private _courseUserLogService: CourseUserLogService,
        protected _courseUserService: CourseUserService,
    ) {
        super();
    }

    ngOnInit() {
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this._courseService.getDetail(pid).then(rs => {
                    if (rs.success) {
                        this.itemDetail = rs.data;
                    }
                })
                this.getMyResult(pid);
            });
    }

    getMyResult(idCourse: number) {
        this._courseUserLogService.getsMyResult(idCourse).then(rs => {
            if (rs.success) {
                this.dsResult = rs.data;
            }
        })
    }

}
