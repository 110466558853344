<p-dialog #dialog header="{{'FsSystem.Title.FileViewer' | translate}}" class="sv-modal" [(visible)]="isShow"
    [modal]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">

        <div *ngIf="fileType == 1" class="text-center">
            <img src="{{fileUrl}}" style="max-width: 1000px;" />
        </div>
        <div id="fileViewContent" style="display:none;" *ngIf="fileType == 2">
        </div>
        <div *ngIf="fileType == 2" style="width: 100%; height: 100%; min-height: 800px;">
            <div id="file-viewer"></div>
        </div>
        <div class="ui-g-12 ui-lg-12">
            <video width="100%" height="100%" #pVideo id='sxmvideoRm'
                class="video-js vjs-default-skin embed-responsive embed-responsive-16by9" controls>
            </video>
        </div>
    </div>
    <p-footer>
    </p-footer>
</p-dialog>
