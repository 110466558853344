<p-dialog #dialog header="{{ 'Cập nhật thông tin bài giảng tương tác' | translate }}" class="sv-modal"
    [(visible)]="isShow" [modal]="true" [styleClass]="'sv-modal force-full-width'"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="sv-form-control type-text-field required">
                            <div class="sv-form-item__label" for="idSvSubject">{{'Học phần' | translate}}</div>
                            <div class="sv-form-item__input-wrapper">
                                <p-dropdown class="sv-form-item__input sv-dropdown" formControlName="idSvSubject"
                                    [options]="dsSubject" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                                    appendTo="body" [(ngModel)]="itemDetail.idSvSubject" [filter]="true"
                                    filterBy="label,value">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sv-form-control type-text-field required">
                            <div class="sv-form-item__label" for="title">{{'FORM.TITLE' | translate}}</div>
                            <div class="sv-form-item__input-wrapper">
                                <input type="text" class="sv-form-item__input sv-text-field"
                                    [(ngModel)]="itemDetail.title" formControlName="title">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sv-form-control type-text-field">
                            <div class="sv-form-item__label" for="videoUrl">{{'Đường dẫn video (mp4)' | translate}}
                            </div>
                            <div class="sv-form-item__input-wrapper">
                                <input type="text" class="sv-form-item__input sv-text-field"
                                    [(ngModel)]="itemDetail.videoUrl" (change)="onChangeVideoUrl()"
                                    formControlName="videoUrl">
                                &nbsp;
                                <button type="button" pTooltip="Chọn học liệu"
                                    class="sv-btn icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--primary"
                                    (click)="showFileExplorer()">
                                    <span class="__icon ci ci-attachment"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="sv-form-control type-text-field">
                                <div class="sv-form-item__label" for="videoTime">{{'Thời lượng (giây)' | translate}}
                                </div>
                                <div class="sv-form-item__input-wrapper">
                                    <input type="text" class="sv-form-item__input sv-text-field"
                                        [(ngModel)]="itemDetail.videoTime" formControlName="videoTime">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="sv-form-control type-text-field">
                                <div class="sv-form-item__label" for="status">{{'FORM.STATUS' | translate}}</div>
                                <div class="sv-form-item__input-wrapper">
                                    <p-dropdown class="sv-form-item__input sv-dropdown" formControlName="status"
                                        [options]="arrStatus" [autoDisplayFirst]="false"
                                        dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                        [(ngModel)]="itemDetail.status" [filter]="true" filterBy="label,value">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ui-g-12 ui-lg-12" *ngIf="itemDetail.videoUrl !== null && itemDetail.videoUrl !== ''">
                        <div class="video embed-responsive embed-responsive-16by9">
                            <video #video width="100%" height="100%" controls
                                src="{{itemDetail.videoUrl | fsFileUrl}}"></video>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1 sv-icon-color--red-60">
                    <span class="__icon ci ci-delete sv-color--red-60"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.DELETE' | translate}}</span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
                    <span class="__icon ci ci-save"></span> &nbsp;
                    <span class="sv-btn__label">{{'BUTTON.SAVE' | translate}}</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<app-fssystem-modal #pFileEpl (closePopup)="onFileExplorerClosed($event)">
</app-fssystem-modal>
