import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    title = environment.appMetadata.webinfo.footer.title;
    address = environment.appMetadata.webinfo.footer.address;
    tel = environment.appMetadata.webinfo.footer.tel;
    mobile = environment.appMetadata.webinfo.footer.mobile;
    email = environment.appMetadata.webinfo.footer.email;
    web = environment.appMetadata.webinfo.footer.web;
    copyright_title1 = environment.appMetadata.webinfo.footer.copyright.title1;
    copyright_title2 = environment.appMetadata.webinfo.footer.copyright.title2;
    copyright_url: any = environment.appMetadata.webinfo.footer.copyright.url;

    constructor() { }

    ngOnInit(): void {
    }

}
